import React, { Component } from "react";
import { connect } from "react-redux";
import Checkbox from "rc-checkbox";

class ReportCheckbox extends Component {
  constructor(props) {
    super(props);

    let checked = false;
    if (
      props.report.exportDims !== undefined &&
      props.report.exportDims !== null
    ) {
      checked = props.report.exportDims[props.checkedValue];
    }
    this.state = {
      checked
    };
  }
  componentDidUpdate() {
    let checked = false;
    if (
      this.props.report.exportDims !== undefined &&
      this.props.report.exportDims !== null
    ) {
      checked = this.props.report.exportDims[this.props.checkedValue];
    }
    if (checked !== this.state.checked)
      this.setState({
        checked
      });
  }
  render() {
    return (
      <div key={this.props.index} style={{ marginRight: "3px" }}>
        <Checkbox
          checked={this.state.checked}
          onChange={event => {
            this.setState({ checked: !!event.target.checked });
            this.props.checkboxChangeHandler(event, this.props.report);
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReportCheckbox);
