import React, { Component } from "react";
import { connect } from "react-redux";
import { removeGroup, updateGroupName } from "../../../reducers/groupReducer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Pen } from "../../../lib/icons";
import { trash } from "react-icons-kit/iconic/trash";
import { Icon } from "react-icons-kit";
import { toast } from "react-toastify";

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGroupsModal: false,
      showDeleteGroupConfirm: false,
      selectedGroup: "",
      editGroupName: false,
      newGroupName: "",
      useState: false
    };
    this.deleteGroup = this.deleteGroup.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.textInput = React.createRef();
    this.handleCancel = this.handleCancel.bind(this);
  }
  keyDownHandler(event) {
    if (event.key === "Enter") {
      this.props.updateGroupName(
        this.props.selectedClient.value,
        this.props.group.id,
        this.textInput.current.value
      );
      this.setState({
        editGroupName: false,
        groupName: this.textInput.current.value,
        useState: true
      });
    } else if (event.key === "Escape") {
      this.setState({ groupName: this.props.group.name });
      this.setState({ editGroupName: false });
    }
  }

  renderGroupName() {
    const { editGroupName } = this.state;
    const { group } = this.props;
    let groupName = group.name;
    if (this.state.useState) {
      groupName = this.state.groupName;
    }
    if (editGroupName) {
      return (
        <input
          defaultValue={groupName}
          ref={this.textInput}
          onChange={evt => {
            if (evt.keyCode === 13) {
              this.setState({ editGroupName: false });
            }
          }}
          onKeyDown={event => {
            groupName = this.textInput;
            this.keyDownHandler(event);
          }}
        />
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          {group.unsavedChanges ? "*" : ""} {groupName}
        </div>
      );
    }
  }

  editGroupName(event, group) {
    event.stopPropagation();
    this.setState({ editGroupName: true });
  }

  handleCancel() {
    this.setState({ showDeleteGroupConfirm: false });
  }
  deleteGroup() {
    this.props.removeGroup(
      this.props.selectedClient.value,
      this.state.selectedGroup
    );
    this.setState({ showDeleteGroupConfirm: false });
    // eslint-disable-next-line quotes
    toast.success('Group "' + this.props.group.name + '" deleted.');
  }
  deleteGroupConfirmation(event, clicked) {
    event.stopPropagation();
    this.setState({ showDeleteGroupConfirm: true, selectedGroup: clicked.id });
  }

  render() {
    const { showDeleteGroupConfirm } = this.state;
    return (
      <div style={{}}>
        <Modal isOpen={showDeleteGroupConfirm}>
          <ModalHeader>Delete Group</ModalHeader>

          <ModalBody>Are you sure you want to delete this group?</ModalBody>

          <ModalFooter>
            <Button onClick={this.deleteGroup} bsstyle="primary">
              Delete
            </Button>
            <Button onClick={this.handleCancel}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <div style={{ display: "inline-flex", width: "100%" }}>
          {this.renderGroupName()}
          <div
            className="ta-action-div"
            style={{ display: "inline-flex", width: "10%" }}
          >
            <div
              className="ta-edit-group"
              style={{ display: "inline-flex" }}
              onClick={event => {
                this.editGroupName(event, this.props.group);
              }}
            >
              <Pen
                className="pen"
                height={"13px"}
                style={{ marginTop: "5px" }}
                width={"13px"}
                color={"rgba(0, 126, 167, 1)"}
              />
            </div>
            <Icon
              style={{
                paddingLeft: "7px",
                color: "rgba(0, 126, 167, 1)"
              }}
              size={13}
              onClick={event => {
                this.deleteGroupConfirmation(event, this.props.group);
              }}
              icon={trash}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedClient: state.clientReducer.selectedClient,
  unsavedChanges: state.groupReducer.unsavedChanges
});

const mapDispatchToProps = dispatch => ({
  updateGroupName(client, groupId, newGroupName) {
    dispatch(updateGroupName(client, groupId, newGroupName));
  },
  removeGroup(client, groupId) {
    dispatch(removeGroup(client, groupId));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Group);
