import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import {
  getCustomApps,
  deleteCustomApp,
  filterApps,
  setSelectedApp,
  setSelectedAppObjects
} from "../../reducers/customApplicationReducer";
import { getGroups } from "../../reducers/groupReducer";
import {
  getClients,
  setClient,
  getAvailableApps
} from "../../reducers/clientReducer";
import EditCustomApplication from "./editCustomApplication";
import { getActiveWorkspaceForClient } from "../../reducers/workspaceReducer";
import EditBaseApplication from "./editBaseApplication";
import "./style.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class CustomApplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateCustomAppModal: false,
      showEditBaseAppModal: false,
      showDeleteConfirm: false,
      customAppToEdit: "",
      allowEdit: false,
      init: true,
      isNew: true,
      defaultApps: [],
      allowBaseEdit: false,
      baseAppToEdit: "",
      baseAppTitle: "",
      appsOrder: [],
      searchAppsOrder: [],
      listLoaded: false,
      workspaceType: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.customAppClickHandler = this.customAppClickHandler.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.appSearch = React.createRef();
    this.baseAppSearch = React.createRef();
  }

  componentDidMount() {
    this.props.getClients();

    if (this.props.selectedClient !== null) {
      this.setState({
        selectedClient: {
          label: this.props.selectedClient.label,
          value: this.props.selectedClient.value
        }
      });
      this.props.getGroups(this.props.selectedClient.value);
      this.props.getCustomApps(this.props.selectedClient.value);
      this.props.getAvailableApps(this.props.selectedClient.value);
      this.props.getActiveWorkspaceForClient(this.props.selectedClient.value);
    }
  }
  componentDidUpdate() {
    if (
      this.props.availableApps === null &&
      this.props.selectedClient !== null
    ) {
      this.props.getAvailableApps(this.props.selectedClient.value);
      this.props.getActiveWorkspaceForClient(this.props.selectedClient.value);
    }

    if (
      this.props.loadedApps &&
      !this.props.loadingApps &&
      !this.state.listLoaded &&
      !this.props.activeLoading &&
      !this.props.loadingCustomApps
    ) {
      this.props.setSelectedApp({});
      let selectedAvApps = cloneDeep(this.props.availableApps);
      let showActiveWorkspaces = false;
      selectedAvApps = selectedAvApps.filter(item => {
        if (
          item.checked &&
          (item.type === "powerBI" || item.type === "tableau")
        ) {
          showActiveWorkspaces = true;
        }
        return item.checked;
      });

      let appsOrder = [...selectedAvApps];
      if (showActiveWorkspaces) {
        appsOrder = [...selectedAvApps, ...this.props.activeWorkspaces];
      }
      var c = [];
      var addArr = [];
      var defaultApps = [];

      let clientAppsOrder = this.props.selectedClient.appsOrder;

      appsOrder.forEach(item => {
        if (!clientAppsOrder.includes(item._id)) {
          clientAppsOrder.push(item._id);
        }
      });
      if (this.props.selectedClient.appsOrder !== undefined) {
        this.props.selectedClient.appsOrder.forEach(el => {
          let found = appsOrder.find(e => {
            if (e.title === "TABS Insight" || e.title === "Tableau") {
              return false;
            }
            return e._id === el;
          });

          if (found !== undefined) {
            c.push(found);

            if (found.title !== undefined) {
              found.label = found.title;
              found.name = found.title;
              found.value = found.title;
            } else if (found.name !== undefined) {
              found.label = found.name;
              found.value = found.name;

              if (found.type === undefined) {
                found.link = "/reports/landing/" + found._id;
              } else if (found.type === "tableau") {
                found.link = "/tableau/report/" + found.workspaceId;
              } else if (found.type === "powerBI") {
                //found.link = "/powerBI/report/"+
              }
            }
            defaultApps.push(found);
          } else {
            addArr.push(el);
          }
        });
        c = c.filter(item => {
          return item !== undefined;
        });
        // appsOrder = sortBy(appsOrder, [
        //   function (o) {
        //     return o.appOrder;
        //   }
        // ]);
        appsOrder = c;
      } else {
        appsOrder = [];
      }

      this.setState({
        searchAppsOrder: appsOrder,
        appsOrder,
        listLoaded: true
      });
    }
  }

  handleChange = (selectedOption, type = "client") => {
    if (type === "client") {
      this.props.setClient(selectedOption);
      this.props.getGroups(selectedOption.value);
      this.props.getCustomApps(selectedOption.value);
      this.props.getAvailableApps(selectedOption.value);
      this.props.getActiveWorkspaceForClient(selectedOption.value);
      this.setState({
        selectedClient: selectedOption,
        listLoaded: false,
        appsOrder: [],
        searchAppsOrder: []
      });
    }
  };
  handleInputChange(type) {
    if (type === "app") {
      this.props.filterApps(this.appSearch.value, this.props.customApps);
    } else {
      if (this.baseAppSearch.value === "") {
        this.setState({ searchAppsOrder: this.state.appsOrder });
      } else {
        let newApps = cloneDeep(this.state.appsOrder);
        newApps = newApps.filter(app => {
          return (
            app.name
              .toLowerCase()
              .indexOf(this.baseAppSearch.value.toLowerCase()) !== -1
          );
        });
        this.setState({ searchAppsOrder: newApps });
      }
    }
  }

  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }

  customAppClickHandler(id) {
    this.setState({ customAppToEdit: id, allowEdit: true });
  }
  baseAppClickHandler(id, title, app) {
    var workspace = false;
    if (app.workspaceId !== undefined) {
      workspace = true;
    }
    let nTitle = title;
    if (title === undefined) {
      nTitle = app.name;
    }
    this.setState({
      baseAppToEdit: id,
      baseAppTitle: nTitle,
      workspaceType: workspace,
      allowBaseEdit: true
    });
  }
  handleClose() {
    this.setState({
      showDeleteConfirm: false
    });
  }
  renderCustomApps() {
    const { searchCustomApps } = this.props;
    const { customAppToEdit } = this.state;

    if (searchCustomApps.length > 0) {
      return searchCustomApps.map((app, idx) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            key={idx}
            className={customAppToEdit === app._id ? "selected" : ""}
            onClick={() => {
              this.customAppClickHandler(app._id);
            }}
          >
            {app.name}
          </div>
        );
      });
    } else {
      return <div />;
    }
  }
  renderBaseApps() {
    // const { availableApps } = this.props;
    const { baseAppToEdit, searchAppsOrder } = this.state;

    if (searchAppsOrder !== null && searchAppsOrder.length > 0) {
      return searchAppsOrder.map((app, idx) => {
        let title = app.title;
        if (title === undefined) {
          title = app.name;
        }

        if (
          app.alias !== undefined &&
          app.alias !== "" &&
          app.alias !== app.title
        ) {
          return (
            <div
              style={{ cursor: "pointer" }}
              key={idx}
              className={baseAppToEdit === app._id ? "selected" : ""}
              onClick={() => {
                this.baseAppClickHandler(app._id, app.title, app);
              }}
            >
              {title} (Alias: {app.alias})
            </div>
          );
        } else {
          return (
            <div
              style={{ cursor: "pointer" }}
              key={idx}
              className={baseAppToEdit === app._id ? "selected" : ""}
              onClick={() => {
                this.baseAppClickHandler(app._id, app.title, app);
              }}
            >
              {title}
            </div>
          );
        }
      });
    } else {
      return <div />;
    }
  }
  editCustomApp(isNew) {
    // if (isNew) {
    //   this.props.setGroupSelection([]);
    //   this.props.getCustomAppObjects(this.state.selectedClient.value, "");
    // } else {
    //   this.props.getCustomAppObjects(
    //     this.state.selectedClient.value,
    //     this.state.customAppToEdit
    //   );
    // }
    // this.props.getCustomApp(
    //   this.state.selectedClient.value,
    //   this.state.customAppToEdit
    // );
    this.setState({
      showCreateCustomAppModal: true,
      isNew: isNew,
      init: true
    });
  }
  editBaseApp() {
    this.setState({
      showEditBaseAppModal: true
    });
  }

  deleteHandler() {
    const { selectedClient, customAppToEdit } = this.state;
    if (customAppToEdit !== "") {
      this.props.deleteCustomApp(selectedClient, customAppToEdit);
      this.handleClose();
    }
  }
  render() {
    const { clients } = this.props;
    const {
      selectedClient,
      showCreateCustomAppModal,
      showDeleteConfirm,
      showEditBaseAppModal
    } = this.state;
    if (showCreateCustomAppModal) {
      return (
        <EditCustomApplication
          newApp={this.state.isNew}
          customAppToEdit={this.state.customAppToEdit}
          cancelHandler={() => {
            this.setState({ showCreateCustomAppModal: false });
          }}
        />
      );
    } else if (showEditBaseAppModal) {
      return (
        <EditBaseApplication
          appToEdit={this.state.baseAppToEdit}
          title={this.state.baseAppTitle}
          workspaceType={this.state.workspaceType}
          cancelHandler={() => {
            this.setState({ showEditBaseAppModal: false });
          }}
          saveHandler={() => {
            this.setState({ listLoaded: false });
          }}
        />
      );
    }

    return (
      <div style={{ display: "flex", width: "77%" }}>
        <Modal isOpen={showDeleteConfirm}>
          <ModalHeader>Confirm Deletion</ModalHeader>

          <ModalBody>
            <div>
              Are you sure you want to do remove this Custom Application?
            </div>
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.deleteHandler}>
              Ok
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div style={{ width: "100%", height: "80%" }}>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div
              className="client"
              style={{ marginTop: "10px", display: "flex" }}
            >
              <div className="client-text">Client:</div>
              <Select
                key={JSON.stringify(clients)} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
                //filterOption={() => true}
                className="dropdown"
                value={
                  selectedClient //isClearable
                }
                onChange={item => {
                  this.handleChange(item, "client");
                }}
                onInputChange={this.onInputChange.bind(this)}
                options={clients}
              />
            </div>
            <hr className="spacer" style={{ width: "100%" }} />
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div style={{ width: "50%" }}>
                <div>Custom Applications</div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "35%" }}>
                    <div>
                      <input
                        className="input-box"
                        placeholder="search"
                        ref={input => (this.appSearch = input)}
                        onChange={() => {
                          this.handleInputChange("app");
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="action-buttons">
                    <div
                      className={"ta-button ta-button-primary"}
                      onClick={() => {
                        if (this.state.selectedClient !== null) {
                          this.editCustomApp(true);
                        }
                      }}
                    >
                      New
                    </div>

                    <div
                      className={
                        this.state.allowEdit
                          ? "ta-button ta-button-secondary"
                          : "ta-button ta-button-disabled"
                      }
                      onClick={() =>
                        this.state.allowEdit ? this.editCustomApp(false) : null
                      }
                    >
                      Edit
                    </div>
                    <div
                      className={"ta-button ta-button-secondary"}
                      onClick={() => {
                        if (this.state.customAppToEdit !== "")
                          this.setState({ showDeleteConfirm: true });
                      }}
                    >
                      Delete
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    height: "500px",
                    border: "1px solid #ccc",
                    overflowY: "auto",
                    padding: "4px"
                  }}
                >
                  {this.renderCustomApps()}
                </div>
              </div>
              <div style={{ marginLeft: "10px", width: "50%" }}>
                <div>Customize Base Applications and Workspaces</div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "35%" }}>
                    <div>
                      <input
                        className="input-box"
                        placeholder="search"
                        ref={input => (this.baseAppSearch = input)}
                        onChange={() => {
                          this.handleInputChange("baseApp");
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="action-buttons">
                    <div
                      className={
                        this.state.allowBaseEdit
                          ? "ta-button ta-button-secondary"
                          : "ta-button ta-button-disabled"
                      }
                      onClick={() =>
                        this.state.allowBaseEdit ? this.editBaseApp() : null
                      }
                    >
                      Edit
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    height: "500px",
                    border: "1px solid #ccc",
                    overflowY: "auto",
                    padding: "4px"
                  }}
                >
                  {this.renderBaseApps()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loadingApps: state.clientReducer.loadingApps,
  loadedApps: state.clientReducer.loadedApps,
  availableApps: state.clientReducer.availableApps,
  customApps: state.customAppReducer.customApps,
  searchCustomApps: state.customAppReducer.searchCustomApps,
  availableCustomAppObjects: state.customAppReducer.availableCustomAppObjects,
  loadingCustomApps: state.customAppReducer.loadingCustomApps,
  loadedAppObjects: state.customAppReducer.loadedAppObjects,
  order: state.customAppReducer.order,
  clients: state.clientReducer.clients,
  selectedClient: state.clientReducer.selectedClient,
  files: state.filesReducer.files,
  userGroups: state.groupReducer.userGroups,
  searchGroups: state.groupReducer.searchGroups,
  activeWorkspaces: state.workspaceReducer.activeWorkspaces,
  activeLoading: state.workspaceReducer.activeLoading
});
const mapDispatchToProps = dispatch => ({
  getCustomApps(client) {
    dispatch(getCustomApps(client));
  },
  getAvailableApps(client) {
    dispatch(getAvailableApps(client));
  },
  getClients() {
    dispatch(getClients());
  },
  setClient(client) {
    dispatch(setClient(client));
  },

  getGroups(client) {
    dispatch(getGroups(client));
  },
  deleteCustomApp(client, app) {
    dispatch(deleteCustomApp(client, app));
  },
  filterApps(filter, apps) {
    dispatch(filterApps(filter, apps));
  },
  getActiveWorkspaceForClient(client) {
    dispatch(getActiveWorkspaceForClient(client));
  },
  setSelectedApp(App) {
    dispatch(setSelectedApp(App));
  },
  setSelectedAppObjects(Objs) {
    dispatch(setSelectedAppObjects(Objs));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomApplications);
