import React, { Component } from "react";
//import PowerBIItem from "./powerBIItem";
import { FolderIcon, RightChevron } from "../../../lib/icons";
import "../style.css";
import { connect } from "react-redux";
import { selectFiles } from "../../../reducers/filesReducer";

class FileFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      allSelected: false
    };
    this.selectAll = this.selectAll.bind(this);
    this.textInput = React.createRef();
    this.editFolderName = this.editFolderName.bind(this);
    this.closeTextInput = this.closeTextInput.bind(this);
  }
  componentDidMount() {
    if (
      this.textInput.current !== undefined &&
      this.textInput.current !== null
    ) {
      this.textInput.current.focus();
      this.textInput.current.select();

      //window.addEventListener("blur", this.closeTextInput, false);
    }
  }
  componentDidUpdate() {
    if (
      this.textInput.current !== undefined &&
      this.textInput.current !== null
    ) {
      this.textInput.current.focus();
      this.textInput.current.select();

      window.addEventListener("blur", this.closeTextInput, false);
    }
  }
  collapseHandler() {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }
  componentWillUnmount() {
    window.removeEventListener("blur", this.closeTextInput, false);
  }
  selectAll() {
    this.props.item.children.forEach(file => {
      if (file.type === "file")
        this.props.selectFiles(file, !this.state.allSelected);
      else {
        file.children.forEach(cFile => {
          this.props.selectFiles(cFile, !this.state.allSelected);
        });
      }
    });
    this.setState({ allSelected: !this.state.allSelected });
  }
  editFolderName(event, item) {
    if (item.status !== undefined && item.status === "new") {
      item.editing = true;
      this.setState({ editing: true, name: item.name });
    }
  }
  closeTextInput(event) {
    if (event.type === "blur") {
      window.removeEventListener("blur", this.closeTextInput, false);
      this.props.item.name = {};
      this.props.item.name.current = "";

      this.props.editName(
        this.props.item,
        this.textInput.current.value,
        this.state.name
      );
    }
  }
  keyDownHandler(event, item) {
    if (event.key === "Enter") {
      this.props.editName(
        this.props.item,
        this.textInput.current.value,
        this.state.name
      );
    } else if (event.key === "Escape") {
      this.props.item.editing = false;
      this.props.editName(this.props.item, this.state.name, this.state.name);
      this.setState({ editing: false });
    }
  }
  clickHandler(event, item) {
    let path = item.path.split("/");
    let finder = path[path.length - 1];
    path = item.path.split(finder)[0];
    //this.setState({ path });
    if (this.props.clickHandler !== undefined) {
      this.props.clickHandler(event, item);
    }
  }
  render() {
    const { item, index, reportFolder } = this.props;

    const { collapsed } = this.state;

    let path = item.path;

    if (reportFolder === true) {
      if (collapsed) {
        return (
          <div key={index}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                key={index}
                style={{ display: "flex" }}
                onClick={() => {
                  this.collapseHandler(index);
                }}
              >
                <RightChevron
                  //transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
                  height={"24px"}
                  width={"24px"}
                  color={"rgba(88, 89, 91, 1)"}
                  viewBox={"0 -4 24 24"}
                />
              </div>
              {this.props.item.editing ? (
                <div
                  onClick={event => {
                    this.clickHandler(event, item);
                  }}
                  onDoubleClick={event => {
                    this.editFolderName(event, item);
                  }}
                  className={
                    "ta-file-title " +
                    (this.props.selected === path ? "selected" : "")
                  }
                >
                  <input
                    style={{ width: "100%" }}
                    className="folder-edit-text"
                    defaultValue={item.name}
                    ref={this.textInput}
                    onChange={evt => {
                      if (evt.keyCode === 13) {
                        this.props.item.editing = false;
                        this.setState({ editing: false });
                      }
                    }}
                    onBlur={this.closeTextInput}
                    onKeyDown={event => {
                      item.name = this.textInput;
                      this.keyDownHandler(event, item);
                    }}
                  />
                </div>
              ) : (
                <div
                  onClick={event => {
                    this.clickHandler(event, item);
                  }}
                  onDoubleClick={event => {
                    this.editFolderName(event, item);
                  }}
                  className={
                    "ta-file-title " +
                    (this.props.selected === path ? "selected" : "")
                  }
                >
                  {item.name}
                </div>
              )}
            </div>
          </div>
        );
      }

      return (
        <div>
          <div
            className="folder-div"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              className="collapse"
              style={{ display: "flex" }}
              onClick={() => {
                this.collapseHandler(index);
              }}
            >
              <RightChevron
                transform={this.state.collapsed ? "" : "rotate(90,12,12)"}
                height={"24px"}
                width={"24px"}
                color={"rgba(88, 89, 91, 1)"}
                viewBox={"0 -4 24 24"}
              />
            </div>
            {this.props.item.editing ? (
              <div
                onClick={event => {
                  this.clickHandler(event, item);
                }}
                onDoubleClick={event => {
                  this.editFolderName(event, item);
                }}
                className={
                  "ta-file-title " +
                  (this.props.selected === path ? "selected" : "")
                }
              >
                <input
                  style={{ width: "100%" }}
                  className="folder-edit-text"
                  defaultValue={item.name}
                  ref={this.textInput}
                  onChange={evt => {
                    if (evt.keyCode === 13) {
                      this.props.item.editing = false;
                      this.setState({ editing: false });
                    }
                  }}
                  onBlur={this.closeTextInput}
                  onKeyDown={event => {
                    item.name = this.textInput;
                    this.keyDownHandler(event, item);
                  }}
                />
              </div>
            ) : (
              <div
                onClick={event => {
                  this.clickHandler(event, item);
                }}
                onDoubleClick={event => {
                  this.editFolderName(event, item);
                }}
                className={
                  "ta-file-title " +
                  (this.props.selected === path ? "selected" : "")
                }
              >
                {item.name}
              </div>
            )}
          </div>
          <div
            style={{
              paddingLeft: "40px"
            }}
          >
            {this.props.children}
          </div>
        </div>
      );
    } else {
      return (
        <div key={index} className="file-folder-parent">
          <div style={{ display: "flex" }}>
            <FolderIcon
              height={"16"}
              // color={"rgba(88, 89, 91, 1)"}
              color={"rgba(88, 89, 91, 1)"}
              className="folder-icon"
            />
            <div className="ta-file-title">{item.name}</div>
            <div className="select-all" onClick={this.selectAll}>
              Select All
            </div>
          </div>
          <div
            style={{
              paddingLeft: "40px"
            }}
          >
            {this.props.children}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  selectedFiles: state.filesReducer.selected
});
const mapDispatchToProps = dispatch => ({
  selectFiles(files, select) {
    dispatch(selectFiles(files, select));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FileFolder);
