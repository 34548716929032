import axios from "axios";
import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loading: true,
  error: null,
  folders: [],
  searchFolders: [],
  sasToken: "",
  selected: []
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_FOLDER_LIST_BEGIN = "FETCH_FOLDER_LIST_BEGIN";
const FETCH_FOLDER_LIST_SUCCESS = "FETCH_FOLDER_LIST_SUCCESS";
const FETCH_FOLDER_LIST_FAILURE = "FETCH_FOLDER_LIST_FAILURE";
const SET_SEARCH_FOLDERS = "SET_SEARCH_FOLDERS";

const SET_SELECTED_FOLDERS = "SET_SELECTED_FOLDERS";

/* -------------------- ACTION CREATORS -------------------- */

const fetchFolderListBegin = () => ({
  type: FETCH_FOLDER_LIST_BEGIN
});
const fetchFolderListSuccess = folders => {
  return { type: FETCH_FOLDER_LIST_SUCCESS, payload: folders };
};
const setSearchFolders = folders => {
  return { type: SET_SEARCH_FOLDERS, payload: folders };
};

const fetchFolderListError = error => ({
  type: FETCH_FOLDER_LIST_FAILURE,
  payload: { error }
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getFolderList = client => {
  return dispatch => {
    dispatch(fetchFolderListBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/powerBI/getReportFolders`,
        {
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // let reports = response.data.workspaces.map(item => {
        //   return item.reports;
        // });
        // debugger;

        dispatch(fetchFolderListSuccess(response.data.workspaces));
      })
      .catch(error => {
        dispatch(fetchFolderListError(error));
      });
  };
};
export const moveReport = (rpt, target) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/powerBI/moveReport`,
        {
          rpt,
          target
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);
        dispatch(getFolderList());
      });
  };
};
export const filterFolders = (filter, folders) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchFolders(folders));
    } else {
      let newFolders = cloneDeep(folders);
      let nFolders = [];

      newFolders.forEach((newFolder, newFolderIdx) => {
        if (newFolder.children === undefined) {
          if (newFolder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
            return nFolders.push(newFolder);
        } else {
          newFolder.children = newFolder.children.filter((folder, index) => {
            if (folder.children === undefined) {
              return (
                folder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
              );
            } else {
              return true;
            }
          });
          newFolder.children.forEach((folder, index) => {
            if (folder.children !== undefined) {
              let tempFolders = cloneDeep(folder);
              folder.children = [];
              tempFolders.children.forEach((cFolder, cIndex) => {
                if (
                  cFolder.name.toLowerCase().indexOf(filter.toLowerCase()) !==
                  -1
                ) {
                  newFolder.children[index].children.push(cFolder);
                }
              });
            }
          });
          nFolders.push(newFolder);
        }
      });
      dispatch(setSearchFolders(nFolders));
    }
  };
};
export const selectFolders = (folders, select) => {
  return { type: SET_SELECTED_FOLDERS, folders, select };
};
export const setFolderList = folders => {
  return dispatch => {
    //dispatch(fetchFolderListBegin());
    dispatch(setSearchFolders(folders.treeData));
  };
};
export const createFolder = (client, folder, ws) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/powerBI/createFolder`,
        {
          folder,
          ws
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        //console.log(response);
        dispatch(getFolderList(client.value));
      });
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FOLDER_LIST_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        folders: action.payload,
        searchFolders: action.payload,
        loading: false
      };
    case FETCH_FOLDER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        reportConfig: []
      };
    case SET_SEARCH_FOLDERS:
      return {
        ...state,
        searchFolders: action.payload
      };
    case SET_SELECTED_FOLDERS:
      let newSelected = cloneDeep(state.selected);
      let newFolders = cloneDeep(state.folders);
      newFolders = newFolders.map(folder => {
        if (folder.name === action.folders.name) {
          folder.selected = action.select;
        }
        return folder;
      });
      action.folders.selected = action.select;
      if (action.select) {
        newSelected.push(action.folders);
      } else {
        if (Array.isArray(action.folders)) {
          let aFolders = action.folders.map(folder => {
            return folder.path;
          });
          newSelected = newSelected.filter(folder => {
            return aFolders.indexOf(folder.path) === -1;
          });
        } else {
          newSelected = newSelected.filter(folder => {
            return folder.path !== action.folders.path;
          });
        }
      }
      return {
        ...state,
        selected: newSelected,
        folders: newFolders
      };
    default:
      return state;
  }
};
