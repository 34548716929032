import React from "react";
import { connect } from "react-redux";
//import axios from "axios";

//import { userActions } from "../_actions";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username } = this.state;

    this.props.auth.login(username);

    //if (username && password) {
    //  dispatch(userActions.login(username, password));
    //}
  }
  render() {
    const { username, submitted } = this.state;
    return (
      <div>
        <form name="form" onSubmit={this.handleSubmit}>
          <div
            className={
              "form-group" + (submitted && !username ? " has-error" : "")
            }
          >
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="form-control"
              name="username"
              value={username}
              onChange={this.handleChange}
            />
            {submitted && !username && (
              // eslint-disable-next-line indent
              <div className="help-block">Username is required</div>
            )}
          </div>
          <div className="form-group">
            <button
              style={{ marginTop: "1rem", background: "rgba(0, 126, 167, 1)" }}
              className="btn btn-primary"
            >
              Log In
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
