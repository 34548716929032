import history from "./history";
import { runWithAdal } from "react-adal";
import { authContext } from "./config";
import { parseToken } from "./helpers";
import { setAuthLoaded } from "../reducers/authReducer";
import axios from "axios";
import moment from "moment-timezone";

class Auth {
  constructor(store) {
    this.store = store;
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    window.AuthenticationContext = authContext;
    window.authContext = authContext;
  }

  login(username) {
    //This can safely be called whenever, as it doesn't crash if it isn't a callback.
    //I am not sure why I need this here, but without it, nothing works.
    window.authContext.handleWindowCallback();
    let access = localStorage.getItem("access_token");

    if (!window.authContext.isCallback(window.location.hash)) {
      if (access === null) {
      }
      //Having both of these checks is to prevent having a token in localstorage, but no user. Relates to issue #471
      // else if (
      //   !window.authContext.getCachedToken(authContext.config.clientId) ||
      //   !window.authContext.getCachedUser()
      // ) {
      //   window.authContext.login();
      // }
      else {
        // window.authContext._renewIdToken((err, idToken) => {
        //   if (err) {
        //     console.log("Renewal failed: " + err);
        //     // Failed to get the token silently; show the login button
        //     //showLoginButton();
        //     window.authContext.login();
        //     // You could attempt to launch the login popup here, but in browsers this could be blocked by
        //     // a popup blocker, in which case the login attempt will fail with the reason FailedToOpenWindow.
        //   } else {
        //     console.log("ID TOKEN RENEWAL " + JSON.stringify(idToken));
        //   }
        // });
        window.authContext.acquireToken(
          authContext.config.clientId,
          (error, token) => {
            if (token !== null) {
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${token}`;
              localStorage.setItem("access_token", token);
            } else {
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${localStorage.getItem("access_token")}`;
            }
          }
        );
      }
    }
    authContext.config.extraQueryParameter = `login_hint=${username}`;
    runWithAdal(authContext, () => {
      console.log(authContext);
      //TODO : continue your process
    });
  }

  handleAuthentication() {
    var requestInfo = authContext.getRequestInfo(window.location.hash);
    if (requestInfo.valid) {
      authContext.saveTokenFromHash(requestInfo);
      if (requestInfo.parameters.id_token) {
        this.setSession(requestInfo.parameters.id_token);
        history.replace("/");
      }
    }
    //         var groups = [];
    //         if (
    //           authResult.idTokenPayload[
    //             "http://portal.tabsanalytics.com/user_metadata"
    //           ] === undefined
    //         ) {
    //           //saml login/ ad login
    //           groups =
    //             authResult.idTokenPayload[
    //               "http://portal.tabsanalytics.com/group"
    //             ];
    //         } else {
    //           //Username/Password login
    //           groups =
    //             authResult.idTokenPayload[
    //               "http://portal.tabsanalytics.com/user_metadata"
    //             ].groups;
    //         }
    //         let accessOvis = groups.filter(group => {
    //           return group === "ovis-admin";
    //         });
    //         if (accessOvis.length > 0) {
    //           this.setSession(authResult);
    //         } else {
    //           history.replace("/home");
    //           console.log(
    //             "You do not have permissions to access this application"
    //           );
    //           alert(`Error: You Do not have permissions to access this app.`);
    //         }
    //       }

    //       history.replace("/");
    //     } else if (err) {
    //       history.replace("/home");
    //       console.log(err);
    //       alert(`Error: ${err.error}. Check the console for further details.`);
    //     }
    //   }
    // );
  }

  setSession(hash) {
    // Set the time that the access token will expire at
    let parsedToken = parseToken(hash);
    let expiresAt = parsedToken.exp * 1000;

    localStorage.setItem("access_token", hash);
    if (parsedToken.unique_name !== undefined) {
      localStorage.setItem("user", parsedToken.unique_name);
    } else if (parsedToken.email !== undefined) {
      localStorage.setItem("user", parsedToken.email);
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${hash}`;

    localStorage.setItem("expires_at", expiresAt);
    this.store.dispatch(setAuthLoaded(true));
    // localStorage.setItem("access_token", authResult.accessToken);
    // localStorage.setItem("id_token", authResult.idToken);
    // localStorage.setItem("expires_at", expiresAt);
    // if (
    //   authResult.idTokenPayload[
    //     "http://accucast.tabsanalytics.com/user_metadata"
    //   ] !== undefined
    // ) {
    //   localStorage.setItem(
    //     "groupId",
    //     authResult.idTokenPayload[
    //       "http://accucast.tabsanalytics.com/user_metadata"
    //     ].groups[0]
    //   );
    // } else if (
    //   authResult.idTokenPayload["http://accucast.tabsanalytics.com/group"] !==
    //   undefined
    // ) {
    //   localStorage.setItem(
    //     "groupId",
    //     authResult.idTokenPayload["http://accucast.tabsanalytics.com/group"]
    //   );
    // }
    //this.store.dispatch(setAuthLoaded(true));
  }

  logout() {
    // // Clear access token and ID token from local storage
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("id_token");
    // localStorage.removeItem("expires_at");
    // localStorage.removeItem("groupId");
    // // navigate to the home route
    // history.replace("/home");
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("groupId");
    localStorage.removeItem("client");
    localStorage.removeItem("clientToken");
    localStorage.removeItem("sso");
    //this.store.dispatch(logout(true));
    var i = sessionStorage.length;
    while (i--) {
      var key = sessionStorage.key(i);
      if (/adal/.test(key)) {
        sessionStorage.removeItem(key);
      }
    }
    sessionStorage.removeItem(
      "adal.access.token.keyhttps://graph.microsoft.com"
    );
    //authContext.clearCache();
    // navigate to the home route
    history.replace("/home");
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    expiresAt = moment(expiresAt, "x");
    const newDate = moment(expiresAt, "x").subtract(25, "minutes");
    const nowTime = moment();
    if (nowTime > newDate && nowTime < expiresAt) {
      window.authContext._renewIdToken((err, idToken) => {
        //   if (err) {
        //     console.log("Renewal failed: " + err);
        //     // Failed to get the token silently; show the login button
        //     //showLoginButton();
        //     window.authContext.login();
        //     // You could attempt to launch the login popup here, but in browsers this could be blocked by
        //     // a popup blocker, in which case the login attempt will fail with the reason FailedToOpenWindow.
        //   } else {
        console.log("ID TOKEN RENEWAL " + JSON.stringify(idToken));
        axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
        //   }
      });

      return true;
    } else {
      return nowTime < expiresAt;
    }
    //return new Date().getTime() < expiresAt;
  }
}

export default Auth;
