import React from "react";
import "./stepper.css"; // Make sure to import the CSS

function Stepper({ value, onChange }) {
  const increment = () => {
    if (value < 100) {
      onChange(value + 1);
    }
  };

  const decrement = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };
  const handleChange = event => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      onChange(newValue);
    } else if (event.target.value === "") {
      onChange(""); // Allow temporary empty field for user corrections
    }
  };

  const handleBlur = () => {
    if (value === "") {
      onChange(6); // Reset to 0 if field is left empty
    }
  };

  return (
    <div className="stepper">
      <div className="input-group">
        <input
          className="number-input"
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <button
            className="control-button up"
            onClick={increment}
            disabled={value === 100}
          >
            ▲
          </button>
          <button
            className="control-button down"
            onClick={decrement}
            disabled={value === 0}
          >
            ▼
          </button>
        </div>
      </div>
    </div>
  );
}

export default Stepper;
