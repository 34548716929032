import React, { Component } from "react";
//import Home from "./components/home";
import { Router, Route } from "react-router-dom";
import history from "./lib/history";
import App from "./App";
import Auth from "./lib/auth";
import Callback from "./components/callback";
import { ToastContainer } from "react-toastify";

export default class OvisRouter extends Component {
  constructor(props) {
    super(props);
    this.state = { auth: new Auth(this.props.store) };
  }
  handleAuthentication = ({ location }) => {
    if (/access_token|id_token|error/.test(location.hash)) {
      this.state.auth.handleAuthentication();
    }
  };
  render() {
    return (
      <Router history={history}>
        <div style={{ height: "100%", flex: 1 }}>
          <Route
            path="/"
            render={props => <App auth={this.state.auth} {...props} />}
          />
          {/* <Route
            path="/home"
            render={props => <Home auth={auth} {...props} />}
          /> */}
          <Route
            path="/callback"
            render={props => {
              this.handleAuthentication(props);
              return <Callback {...props} />;
            }}
          />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </div>
      </Router>
    );
  }
}
