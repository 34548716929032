import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
//import CreatableSelect from "react-select/lib/Creatable";
//import "./style.css";

import { Checkbox } from "@atlaskit/checkbox";
import { getClients, setClient } from "../../reducers/clientReducer";
import { getGroups } from "../../reducers/groupReducer";
import axios from "axios";

class BighornLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      groups: []
    };
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.selectAllGroups = this.selectAllGroups.bind(this);
  }
  componentDidMount() {
    if (this.props.selectedClient !== null) {
      this.setState({
        selectedClient: {
          label: this.props.selectedClient.label,
          value: this.props.selectedClient.value
        }
      });
      this.props.getGroups(this.props.selectedClient.value);
    }
  }
  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }
  handleChange = selectedOption => {
    this.props.setClient(selectedOption);
    this.setState({ selectedClient: selectedOption });
    this.props.getGroups(selectedOption.value);
  };
  checkboxChangeHandler(event, group) {
    const { searchGroups } = this.props;

    console.log(group);

    searchGroups.forEach(sGroup => {
      if (group.name === sGroup.name) {
        sGroup.checked = event.target.checked;
      }
    });
    this.setState({ groups: searchGroups });
  }

  renderUserGroups() {
    const { loading, searchGroups } = this.props;

    if (searchGroups.length !== 0 && !loading) {
      let pbiEditorGroup = searchGroups.filter(group => {
        return group.name === "pbi-editor";
      });
      let pbiCreatorGroup = searchGroups.filter(group => {
        return group.name === "pbi-creator";
      });
      if (pbiEditorGroup.length === 0) {
        searchGroups.push({ name: "pbi-editor" });
      }
      if (pbiCreatorGroup.length === 0) {
        searchGroups.push({ name: "pbi-creator" });
      }
      return searchGroups.map((group, index) => {
        let checked;
        if (group.checked === undefined || group.checked === null) {
          checked = 0;
        } else {
          checked = group.checked;
        }
        return (
          <div key={index}>
            <Checkbox
              isChecked={checked}
              onChange={event => {
                this.checkboxChangeHandler(event, group);
              }}
              style={{ marginRight: "10px" }}
              label={group.name}
              value={group.name}
              name={group.name}
            />
          </div>
        );
      });
    }
  }
  saveHandler() {
    let newGroups = [];
    this.props.searchGroups.forEach(group => {
      if (group.checked === 1 || group.checked === true) {
        if (group.name !== null && group.name !== undefined) {
          newGroups.push(group.name);
        } else {
          newGroups.push(group);
        }
      }
    });
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/app/getBighornToken`,
        {
          client: this.props.selectedClient.value,
          groups: newGroups,
          user: localStorage.getItem("user"),
          accessToken: localStorage.getItem("access_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        this.setState({ groups: [] });
        this.props.searchGroups.forEach(group => {
          group.checked = false;
        });
        this.props.getGroups(this.state.selectedClient.value);
        var element = window.document.createElement("a");
        element.setAttribute(
          "href",
          process.env.REACT_APP_BIGHORN_CLIENT + "/setClient#" + res.data.token
        );
        element.setAttribute("target", "_blank");

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
      });
  }
  selectAllGroups(status) {
    if (status === "all") {
      let newChecks = this.props.searchGroups.map(item => {
        item.checked = 1;
        return item;
      });
      this.setState({ groups: newChecks });
    } else {
      this.props.searchGroups.forEach(item => {
        item.checked = 0;
        return item;
      });
      this.setState({ groups: [] });
    }
  }
  render() {
    const { clients } = this.props;
    const { selectedClient } = this.state;

    return (
      <div>
        <div style={{ marginBottom: "20px", fontWeight: "600" }}>
          Select a client and one or more groups to apply during login
        </div>
        <div className="client" style={{ paddingBottom: "20px" }}>
          <div className="client-text">Client:</div>
          <Select
            key={JSON.stringify(clients)}
            className="dropdown"
            value={
              selectedClient //isClearable
            }
            onChange={this.handleChange}
            onInputChange={this.onInputChange.bind(this)}
            options={clients}
          />
          <div className="action-buttons">
            <div
              className={
                this.state.groups.length !== 0
                  ? "ta-button ta-button-primary"
                  : "ta-button ta-button-disabled"
              }
              onClick={this.state.groups.length !== 0 ? this.saveHandler : null}
            >
              Login
            </div>
          </div>
        </div>
        <div style={{ fontWeight: "500" }}>Available Groups:</div>
        <div style={{ fontStyle: "italic", marginBottom: "8px" }}>
          Select the client name group at minimum for most scenarios
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{ cursor: "pointer", color: "rgb(0, 126, 167)" }}
            onClick={() => {
              this.selectAllGroups("all");
            }}
          >
            Select All
          </div>
          <div
            style={{
              marginLeft: "2px",
              marginRight: "2px",
              color: "rgb(0, 126, 167)"
            }}
          >
            /
          </div>
          <div
            style={{ cursor: "pointer", color: "rgb(0, 126, 167)" }}
            onClick={() => {
              this.selectAllGroups();
            }}
          >
            Select None
          </div>
        </div>
        {this.renderUserGroups("none")}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedClient: state.clientReducer.selectedClient,
  clients: state.clientReducer.clients,
  userGroups: state.groupReducer.userGroups,
  searchGroups: state.groupReducer.searchGroups,
  loading: state.groupReducer.loading

  // reports: state.reportReducer.reports,
  // error: state.reportReducer.error,
  // loading: state.reportReducer.loading,
  // allWorkspaces: state.workspaceReducer.allWorkspaces,
  // wsLoading: state.workspaceReducer.loading
});
const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  setClient(client) {
    dispatch(setClient(client));
  },
  getGroups(client) {
    dispatch(getGroups(client));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(BighornLogin);
