import React from "react";
// import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import "./index.css";

import * as serviceWorker from "./serviceWorker";
import ReduxThunk from "redux-thunk";

import rootReducer from "./reducers/rootReducer";
import OvisRouter from "./routes";

import "bootstrap/dist/css/bootstrap.css";

function configureStore(initialState = {}) {
  //const reduxMiddleware = applyMiddleware(ReduxThunk, createLogger());
  const reduxMiddleware = applyMiddleware(ReduxThunk);
  return createStore(rootReducer, initialState, reduxMiddleware);
}
let configuredStore = configureStore();
ReactDOM.render(
  <Provider store={configureStore()}>
    <OvisRouter store={configuredStore} />
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
