import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { getClients, setClient } from "../../reducers/clientReducer";
import { Editor } from "@tinymce/tinymce-react";
import "./style.css";
import {
  saveSiteMessage,
  getSiteMessages,
  deleteSiteMessage
} from "../../reducers/reportReducer";
import renderHTML from "react-render-html";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

class SiteMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: { label: "All Clients", value: "All Clients" },
      siteMessage: {},
      messageId: "",
      content: "",
      title: "",
      activeEditor: null,
      unsavedChanges: false,
      saved: false,
      published: false,
      showIsDirtyModal: false,
      showEmailUsersModal: false,
      subject: "TABS Portal: Reporting Data Refreshed",
      message: "",
      types: [
        { label: "Info", value: "Info" },
        { label: "System", value: "System" },
        {
          label: "New Content (Data/Reports)",
          value: "NewContent"
        },
        { label: "Marketing", value: "Marketing" }
      ],
      type: { label: "Info", value: "Info" }
    };
    this.handleChange = this.handleChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.props.getSiteMessages(this.state.selectedClient.value);
    this.uploadCallback = this.uploadCallback.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }

  handleChange = selectedOption => {
    this.props.setClient(selectedOption);
    this.setState({
      selectedClient: selectedOption,
      selectedUsers: [],
      invitedCheckRan: false,
      uninvited: false,
      siteMessage: {},
      content: "",
      messageId: "",
      type: { label: "Info", value: "Info" }
    });
    this.title = "";
    this.props.getSiteMessages(selectedOption.value);
  };
  componentDidMount() {
    if (this.props.selectedClient !== null) {
      this.setState({
        selectedClient: {
          label: this.props.selectedClient.label,
          value: this.props.selectedClient.value
        }
      });
      this.props.getSiteMessages(this.props.selectedClient.value);
    }
  }
  handleTypeChange = selectedOption => {
    this.setState({
      type: selectedOption
    });
  };
  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }
  inputChangeHandler() {
    if (this.title.value.length <= 30) {
      this.setState({
        title: this.title.value
      });
    }
  }
  clickHandler() {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/emailUsers`,
        {
          message: this.state.message,
          subject: this.state.subject,
          client: this.props.selectedClient.value
        },

        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        console.log(res.statusText);
        this.setState({ showEmailUsersModal: false });
        // this.handleClose();
      });
  }
  uploadCallback(blobInfo, progress) {
    return new Promise((resolve, reject) => {
      let file = blobInfo.blob;

      //const xhr = new XMLHttpRequest();
      console.log(file);
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/uploadImage`
      );
      xhr.setRequestHeader("ClientToken", localStorage.getItem("clientToken"));
      var data = new FormData();
      data.append("image", blobInfo.blob());

      data.append("client", this.state.selectedClient.value);
      data.size = file.size;
      xhr.send(data);
      xhr.upload.onprogress = e => {
        progress((e.loaded / e.total) * 100);
      };
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response.data.link);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
        return;
      });
    });
  }
  handleEditorChange(e) {
    if (
      e !== "" &&
      e.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, "") !==
        this.state.siteMessage.html
    ) {
      this.setState({
        unsavedChanges: true,
        unsavedContent: e.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, ""),
        content: e.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, "")
      });
    } else {
      this.setState({ title: this.state.title });
    }
  }
  deleteHandler() {
    const { title } = this.state;
    let message = {};
    message = { title, id: this.state.messageId };
    this.props.deleteSiteMessage(this.state.selectedClient.value, message);
    this.setState({ title: "", content: "", messageId: "" });
    toast.success("Deleting Message...");
  }
  count(main_str, sub_str) {
    main_str += "";
    sub_str += "";

    if (sub_str.length <= 0) {
      return main_str.length + 1;
    }

    let subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    return (main_str.match(new RegExp(subStr, "gi")) || []).length;
  }
  handleSubjectChange(e) {
    this.setState({ subject: e.currentTarget.value });
  }
  handleMessageChange(e) {
    this.setState({ message: e.currentTarget.value });
  }
  saveHandler(publish) {
    const { unsavedContent, title } = this.state;

    //Remove the SAS token from the image before saving it
    let rmCount = this.count(
      unsavedContent,
      "src='https://tabsportal.blob.core.windows.net"
    );
    let rmSAS = unsavedContent;
    let x = 1;
    while (x <= rmCount) {
      let remove = rmSAS.split("src='")[x].split("'")[0].split("?")[1];
      rmSAS = unsavedContent.replace("?" + remove, "");
      x = x + 1;
    }

    let toSaveContent =
      this.renderTypeString() +
      "<div style='padding-right:4px;padding-left:4px;padding-top:4px;font-size:14px;'>" +
      rmSAS +
      "</div>";
    let message = {};
    if (this.state.messageId === undefined) {
      message = { title, id: null, type: this.state.type.value };
    } else {
      message = {
        title,
        id: this.state.messageId,
        type: this.state.type.value
      };
    }
    if (publish) {
      message.status = "published";
    } else {
      message.status = "saved";
    }

    console.log("save handler");
    if (publish) {
      toast.success("Publishing Message ...");
    } else {
      toast.success("Saving Message ...");
    }

    this.props.saveSiteMessage(
      this.state.selectedClient.value,
      message,
      toSaveContent
    );
    this.setState({
      unsavedChanges: false
    });
  }

  renderTypeString() {
    const { title } = this.state;
    const divStyle =
      "padding-left:4px;line-height:26px;font-size: 14px;height:26px;";
    const svgStyle = "margin-right:6px;margin-bottom:3px;";
    switch (this.state.type.value) {
      case "Info":
        return (
          "<div style='background: #cce5ff;border-bottom: #b8daff 1px solid;" +
          divStyle +
          "'><svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 20 20' fill='#004085' style=' " +
          svgStyle +
          " '><style type='text/css' /><path d='M10 1.3c4.8 0 8.7 3.9 8.7 8.7 0 4.8-3.9 8.7-8.7 8.7 -4.8 0-8.7-3.9-8.7-8.7C1.3 5.2 5.2 1.3 10 1.3M10 0C4.5 0 0 4.5 0 10c0 5.5 4.5 10 10 10s10-4.5 10-10C20 4.5 15.5 0 10 0zM8.5 13.9H9V9H8.5C8.3 9 8.1 8.8 8.1 8.5V8.2c0-0.3 0.2-0.5 0.5-0.5h1.9C10.8 7.7 11 8 11 8.2v5.6h0.5c0.3 0 0.5 0.2 0.5 0.5v0.3c0 0.3-0.2 0.5-0.5 0.5H8.5c-0.3 0-0.5-0.2-0.5-0.5v-0.3C8.1 14.1 8.3 13.9 8.5 13.9zM10 4.2c-0.7 0-1.3 0.6-1.3 1.3S9.3 6.8 10 6.8s1.3-0.6 1.3-1.3S10.7 4.2 10 4.2z'/></svg>" +
          title +
          "</div>"
        );
      case "System":
        return (
          "<div style='background: #fff3cd;border-bottom: #ffeeba 1px solid;" +
          divStyle +
          "'><svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 20 20' fill='#856404' style=' " +
          svgStyle +
          " '><style type='text/css'></style><path d='M19.8 4.3c-0.1-0.3-0.3-0.4-0.6-0.3C19.2 4 19.1 4 19 4.1L16.1 7l-2.7-0.4L13 3.9 15.9 1c0.2-0.2 0.2-0.5 0-0.7 -0.1-0.1-0.1-0.1-0.2-0.1 -3-0.8-6 1.1-6.8 4.1C8.7 5.3 8.7 6.4 9 7.4l-8.3 8.3c-1 1-0.9 2.6 0.1 3.5 1 0.9 2.5 0.9 3.5 0l8.3-8.3c2.9 1 6.1-0.6 7.1-3.5C20 6.4 20.1 5.3 19.8 4.3L19.8 4.3zM2.5 18.5c-0.5 0-0.9-0.4-0.9-0.9s0.4-0.9 0.9-0.9 0.9 0.4 0.9 0.9C3.4 18 3 18.5 2.5 18.5z'/></svg>" +
          title +
          "</div>"
        );
      case "NewContent":
        return (
          "<div style='background: #d4edda;border-bottom: #c3e6cb 1px solid;" +
          divStyle +
          "'><svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 20' fill='#155724' style=' " +
          svgStyle +
          " '> <style type='text/css' /><path d='M14,17.5h2.6c0.4,0,0.9-0.4,0.9-0.9V7.1c0-0.4-0.4-0.9-0.9-0.9H14c-0.4,0-0.9,0.4-0.9,0.9v9.5C13.1,17,13.5,17.5,14,17.5z          M20.5,17.5h2.6c0.4,0,0.9-0.4,0.9-0.9V0.9C24,0.4,23.6,0,23.1,0h-2.6c-0.4,0-0.9,0.4-0.9,0.9v15.7C19.6,17,20.1,17.5,20.5,17.5z          M0.9,17.5h2.6c0.4,0,0.9-0.4,0.9-0.9v-4.3c0-0.4-0.4-0.9-0.9-0.9H0.9c-0.4,0-0.9,0.4-0.9,0.9v4.3C0,17,0.4,17.5,0.9,17.5z          M7.4,17.5H10c0.4,0,0.9-0.4,0.9-0.9V4.8C10.9,4.4,10.5,4,10,4H7.4C7,4,6.5,4.4,6.5,4.8v11.8C6.5,17,7,17.5,7.4,17.5z'/></svg>" +
          title +
          "</div>"
        );
      case "Marketing":
        return (
          "<div style='background: #d1ecf1;border-bottom: #bee5eb 1px solid;" +
          divStyle +
          "'><svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 20 17.4' fill='#0c5460' style=' " +
          svgStyle +
          " '> <style type='text/css' /> <path d='M12.5 0.1l-0.4 0.3c-0.2 0.1-0.3 0.4-0.2 0.7l0.2 0.3L1.3 12.1c-0.2-0.2-0.4-0.2-0.6-0.1l-0.4 0.3C0 12.4-0.1 12.7 0.1 13l2.6 4.2c0.1 0.2 0.4 0.3 0.7 0.2l0.4-0.3C4 17 4 16.7 4 16.5l3.5-1.2c0.1 0.2 0.1 0.3 0.2 0.5 0.9 1.4 2.7 1.8 4.1 1 1.2-0.7 1.6-2.1 1.3-3.4l5.4-1.8 0.2 0.3c0.1 0.2 0.4 0.3 0.7 0.2l0.4-0.3c0.2-0.1 0.3-0.4 0.2-0.7l-6.8-11C13 0 12.7-0.1 12.5 0.1zM11 15.6c-0.7 0.4-1.6 0.2-2-0.5 0-0.1-0.1-0.1-0.1-0.2l2.8-0.9C11.8 14.5 11.5 15.2 11 15.6zM3.2 15.2l-1.1-1.8L12.9 2.7l4.7 7.7L3.2 15.2z'/></svg>" +
          title +
          "</div>"
        );
      default:
        return;
    }
  }
  renderType() {
    switch (this.state.type.value) {
      case "Info":
        return (
          <div
            style={{
              background: "#cce5ff",
              height: "100%",
              borderBottom: "#b8daff 1px solid"
            }}
            className="msg-header"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="#004085"
              style={{ marginRight: "6px", marginBottom: "3px" }}
            >
              <path d="M10 1.3c4.8 0 8.7 3.9 8.7 8.7 0 4.8-3.9 8.7-8.7 8.7 -4.8 0-8.7-3.9-8.7-8.7C1.3 5.2 5.2 1.3 10 1.3M10 0C4.5 0 0 4.5 0 10c0 5.5 4.5 10 10 10s10-4.5 10-10C20 4.5 15.5 0 10 0zM8.5 13.9H9V9H8.5C8.3 9 8.1 8.8 8.1 8.5V8.2c0-0.3 0.2-0.5 0.5-0.5h1.9C10.8 7.7 11 8 11 8.2v5.6h0.5c0.3 0 0.5 0.2 0.5 0.5v0.3c0 0.3-0.2 0.5-0.5 0.5H8.5c-0.3 0-0.5-0.2-0.5-0.5v-0.3C8.1 14.1 8.3 13.9 8.5 13.9zM10 4.2c-0.7 0-1.3 0.6-1.3 1.3S9.3 6.8 10 6.8s1.3-0.6 1.3-1.3S10.7 4.2 10 4.2z" />
            </svg>
            {this.state.title}
          </div>
        );
      case "System":
        return (
          <div
            style={{
              background: "#fff3cd",
              height: "100%",
              borderBottom: "#ffeeba 1px solid"
            }}
            className="msg-header"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="#856404"
              style={{ marginRight: "6px", marginBottom: "3px" }}
            >
              <style type="text/css" />
              <path d="M19.8 4.3c-0.1-0.3-0.3-0.4-0.6-0.3C19.2 4 19.1 4 19 4.1L16.1 7l-2.7-0.4L13 3.9 15.9 1c0.2-0.2 0.2-0.5 0-0.7 -0.1-0.1-0.1-0.1-0.2-0.1 -3-0.8-6 1.1-6.8 4.1C8.7 5.3 8.7 6.4 9 7.4l-8.3 8.3c-1 1-0.9 2.6 0.1 3.5 1 0.9 2.5 0.9 3.5 0l8.3-8.3c2.9 1 6.1-0.6 7.1-3.5C20 6.4 20.1 5.3 19.8 4.3L19.8 4.3zM2.5 18.5c-0.5 0-0.9-0.4-0.9-0.9s0.4-0.9 0.9-0.9 0.9 0.4 0.9 0.9C3.4 18 3 18.5 2.5 18.5z" />
            </svg>
            {this.state.title}
          </div>
        );
      case "NewContent":
        return (
          <div
            style={{
              background: "#d4edda",
              height: "100%",
              borderBottom: "#c3e6cb 1px solid"
            }}
            className="msg-header"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 20"
              fill="#155724"
              style={{ marginRight: "6px", marginBottom: "3px" }}
            >
              <style type="text/css" />
              <path
                d="M14,17.5h2.6c0.4,0,0.9-0.4,0.9-0.9V7.1c0-0.4-0.4-0.9-0.9-0.9H14c-0.4,0-0.9,0.4-0.9,0.9v9.5C13.1,17,13.5,17.5,14,17.5z
          M20.5,17.5h2.6c0.4,0,0.9-0.4,0.9-0.9V0.9C24,0.4,23.6,0,23.1,0h-2.6c-0.4,0-0.9,0.4-0.9,0.9v15.7C19.6,17,20.1,17.5,20.5,17.5z
          M0.9,17.5h2.6c0.4,0,0.9-0.4,0.9-0.9v-4.3c0-0.4-0.4-0.9-0.9-0.9H0.9c-0.4,0-0.9,0.4-0.9,0.9v4.3C0,17,0.4,17.5,0.9,17.5z
          M7.4,17.5H10c0.4,0,0.9-0.4,0.9-0.9V4.8C10.9,4.4,10.5,4,10,4H7.4C7,4,6.5,4.4,6.5,4.8v11.8C6.5,17,7,17.5,7.4,17.5z"
              />
            </svg>
            {this.state.title}
          </div>
        );
      case "Marketing":
        return (
          <div
            style={{
              background: "#d1ecf1",
              height: "100%",
              borderBottom: "#bee5eb 1px solid"
            }}
            className="msg-header"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 17.4"
              fill="#0c5460"
              style={{ marginRight: "6px", marginBottom: "3px" }}
            >
              <style type="text/css" />
              <path d="M12.5 0.1l-0.4 0.3c-0.2 0.1-0.3 0.4-0.2 0.7l0.2 0.3L1.3 12.1c-0.2-0.2-0.4-0.2-0.6-0.1l-0.4 0.3C0 12.4-0.1 12.7 0.1 13l2.6 4.2c0.1 0.2 0.4 0.3 0.7 0.2l0.4-0.3C4 17 4 16.7 4 16.5l3.5-1.2c0.1 0.2 0.1 0.3 0.2 0.5 0.9 1.4 2.7 1.8 4.1 1 1.2-0.7 1.6-2.1 1.3-3.4l5.4-1.8 0.2 0.3c0.1 0.2 0.4 0.3 0.7 0.2l0.4-0.3c0.2-0.1 0.3-0.4 0.2-0.7l-6.8-11C13 0 12.7-0.1 12.5 0.1zM11 15.6c-0.7 0.4-1.6 0.2-2-0.5 0-0.1-0.1-0.1-0.1-0.2l2.8-0.9C11.8 14.5 11.5 15.2 11 15.6zM3.2 15.2l-1.1-1.8L12.9 2.7l4.7 7.7L3.2 15.2z" />
            </svg>
            {this.state.title}
          </div>
        );
      default:
        return (
          <div
            style={{
              background: "#cce5ff",
              height: "100%",
              borderBottom: "#b8daff 1px solid"
            }}
            className="msg-header"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="#004085"
              style={{ marginRight: "6px", marginBottom: "3px" }}
            >
              <path d="M10 1.3c4.8 0 8.7 3.9 8.7 8.7 0 4.8-3.9 8.7-8.7 8.7 -4.8 0-8.7-3.9-8.7-8.7C1.3 5.2 5.2 1.3 10 1.3M10 0C4.5 0 0 4.5 0 10c0 5.5 4.5 10 10 10s10-4.5 10-10C20 4.5 15.5 0 10 0zM8.5 13.9H9V9H8.5C8.3 9 8.1 8.8 8.1 8.5V8.2c0-0.3 0.2-0.5 0.5-0.5h1.9C10.8 7.7 11 8 11 8.2v5.6h0.5c0.3 0 0.5 0.2 0.5 0.5v0.3c0 0.3-0.2 0.5-0.5 0.5H8.5c-0.3 0-0.5-0.2-0.5-0.5v-0.3C8.1 14.1 8.3 13.9 8.5 13.9zM10 4.2c-0.7 0-1.3 0.6-1.3 1.3S9.3 6.8 10 6.8s1.3-0.6 1.3-1.3S10.7 4.2 10 4.2z" />
            </svg>
            {this.state.title}
          </div>
        );
    }
  }
  newsFeedClickHandler(e) {
    let removeTitle = e.html.split("</div>")[0];
    let newContent = e.html.split(removeTitle)[1];
    newContent = newContent
      .replace(/<div ?[a-z='-: ;#()]*>/g, "")
      .replace(/<\/div>/g, "");
    removeTitle = removeTitle
      .replace(/<div ?[a-z='-: ;#()]*>/g, "")
      .replace(/<\/div>/g, "");
    let saved = false;
    let published = false;
    if (e.status === "saved") {
      saved = true;
    } else if (e.status === "published") {
      published = true;
      saved = true;
    }
    this.setState({
      title: e.title,
      content: newContent,
      messageId: e._id,
      saved,
      published
    });
  }
  cancelHandler(e) {
    const { content } = this.state;
    this.state.activeEditor.setContent(content);
    this.setState({ showIsDirtyModal: false, unsavedChanges: false });
  }
  handleClose() {
    this.setState({ showIsDirtyModal: false, showEmailUsersModal: false });
  }
  renderNewsFeed() {
    if (this.props.feedItems !== null) {
      if (this.props.feedItems.length > 0) {
        return this.props.feedItems.map((feed, idx) => {
          let status = "";
          if (feed.status === "published") {
            status = (
              <div>
                Published {moment(feed.actionTS).format("YYYY-MM-DD HH:mm:SS")}
              </div>
            );
          } else {
            status = (
              <div>
                Saved {moment(feed.actionTS).format("YYYY-MM-DD HH:mm:SS")}
              </div>
            );
          }
          return (
            <div
              className="news-feed-item"
              key={idx}
              onClick={() => {
                this.newsFeedClickHandler(feed);
              }}
            >
              {feed.html !== undefined ? renderHTML(feed.html) : null}
              {status}
            </div>
          );
        });
      }
    }
    return;
  }
  renderStatus() {
    if (this.state.saved && this.state.published) {
      return <div className="status-div">Saved / Published</div>;
    } else if (this.state.saved && !this.state.published) {
      return <div className="status-div">Saved / *Unpublished</div>;
    } else {
      return <div className="status-div">*Unsaved / *Unpublished</div>;
    }
  }
  render() {
    const { clients } = this.props;
    let clientsAndAll = [];
    clientsAndAll.push({ label: "All Clients", value: "All Clients" });
    clients.forEach(client => {
      clientsAndAll.push(client);
    });

    const { selectedClient, title, showIsDirtyModal, showEmailUsersModal } =
      this.state;
    console.log(selectedClient);
    return (
      <div style={{ display: "flex", width: "77%" }}>
        <div style={{ width: "100%", height: "80%" }}>
          <div style={{ height: "100%" }}>
            <div>
              <Modal isOpen={showIsDirtyModal}>
                <ModalHeader>Unsaved Changes</ModalHeader>

                <ModalBody>
                  You have unsaved changes are you sure you want to discard your
                  edits?
                </ModalBody>

                <ModalFooter>
                  <Button bsstyle="primary" onClick={this.cancelHandler}>
                    Ok
                  </Button>
                  <Button onClick={this.handleClose}>Cancel</Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={showEmailUsersModal}>
                <ModalHeader>Compose Email</ModalHeader>
                <ModalBody>
                  <div>
                    Emailing all users at {selectedClient.value.split("+")[0]}
                  </div>
                  <div>Subject:</div>
                  <input
                    style={{ width: "100%" }}
                    onChange={this.handleSubjectChange}
                    value={this.state.subject}
                  />
                  <div>Message:</div>
                  <textarea
                    style={{ width: "100%", height: "300px" }}
                    onChange={this.handleMessageChange}
                    value={this.state.message}
                  />
                </ModalBody>

                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.clickHandler();
                    }}
                  >
                    Email Users
                  </Button>
                  <Button onClick={this.handleClose}>Done</Button>
                </ModalFooter>
              </Modal>
            </div>
            <div className="ovis-tab-view">
              <div>
                <div>
                  <div style={{ fontWeight: "600", fontSize: "22px" }}>
                    Site Messages
                  </div>
                  <div>
                    Create a new message or select an existing message to edit
                  </div>
                </div>
                <div className="client" style={{ marginTop: "10px" }}>
                  <div className="client-text">Audience:</div>
                  <Select
                    key={JSON.stringify(clientsAndAll)} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
                    //filterOption={() => true}
                    className="dropdown"
                    value={
                      selectedClient //isClearable
                    }
                    onChange={this.handleChange}
                    onInputChange={this.onInputChange.bind(this)}
                    options={clientsAndAll}
                    onCreateOption={
                      this.handleCreate //autoLoad={true}
                    }
                  />
                  <span
                    style={{
                      marginLeft: "24px",
                      fontWeight: "bold",
                      marginTop: "5px",
                      fontStyle: "italic"
                    }}
                  >
                    Creating New Message
                  </span>
                  <div className="spacer" style={{ width: "10%" }} />
                  <div
                    className={
                      selectedClient.value !== "All Clients"
                        ? "ta-button ta-button-primary"
                        : "ta-button ta-button-disabled"
                    }
                    onClick={() => {
                      if (selectedClient.value !== "All Clients") {
                        axios
                          .post(
                            `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getEmailMessage`,
                            { client: this.props.selectedClient.value },
                            {
                              headers: {
                                ClientToken: localStorage.getItem("clientToken")
                              }
                            }
                          )
                          .then(res => {
                            if (
                              res.data.message === undefined ||
                              res.data.message === ""
                            ) {
                              let date;
                              if (
                                res.data.modifiedDate !== undefined &&
                                res.data.modifiedDate !== null
                              ) {
                                date = res.data.modifiedDate;
                              } else {
                                date = new Date();
                              }
                              console.log(moment.tz.guess());

                              let subjectMsg =
                                "TABS Portal: Reporting Data Refreshed";

                              let message =
                                "Hello,\n\nYour TABS Portal data was refreshed on " +
                                moment
                                  .tz(date, moment.tz.guess())
                                  .format("MM/DD/YYYY") +
                                " at " +
                                moment
                                  .tz(date, moment.tz.guess())
                                  .format("HH:mm:SS A") +
                                " " +
                                moment.tz(date, moment.tz.guess()).zoneAbbr() +
                                "." +
                                "\n\nPlease visit https://portal.tabsanalytics.com to access your updated reports.\n\nThank you,\n\nTABS Analytics";
                              this.setState({
                                loadingEmail: false,
                                message: message,
                                subject: subjectMsg,
                                showEmailUsersModal: true
                              });
                            } else {
                              this.setState({
                                loadingEmail: false,
                                message: res.data.message.replace(
                                  /<br>/g,
                                  "\n"
                                ),
                                subject: res.data.subject,
                                showEmailUsersModal: true
                              });
                            }
                            // this.handleClose();
                          });
                        this.setState({ showEmailUsersModal: true });
                        //this.saveHandler(true);
                      }
                    }}
                  >
                    Email Users
                  </div>
                  <div className="action-buttons">
                    <div
                      className={
                        this.state.unsavedChanges
                          ? "ta-button ta-button-primary"
                          : "ta-button ta-button-disabled"
                      }
                      onClick={() => {
                        if (this.state.unsavedChanges) {
                          this.saveHandler(false);
                        }
                      }}
                    >
                      Save
                    </div>
                    <div
                      className={
                        this.state.unsavedChanges || this.state.saved === true
                          ? "ta-button ta-button-primary"
                          : "ta-button ta-button-disabled"
                      }
                      onClick={() => {
                        if (
                          this.state.unsavedChanges ||
                          this.state.saved === true
                        ) {
                          this.saveHandler(true);
                        }
                      }}
                    >
                      Publish
                    </div>
                    <div
                      className="ta-button ta-button-primary"
                      onClick={this.deleteHandler}
                    >
                      Delete
                    </div>
                    <div
                      className={
                        this.state.unsavedChanges
                          ? "ta-button ta-button-secondary"
                          : "ta-button ta-button-disabled"
                      }
                      onClick={() =>
                        this.state.unsavedChanges
                          ? this.setState({ showIsDirtyModal: true })
                          : null
                      }
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ height: "100%", display: "flex", paddingTop: "20px" }}
              >
                <div className="feed-container">
                  <input
                    className="input-box"
                    placeholder="search"
                    ref={input => (this.search = input)}
                    onChange={this.handleInputChange}
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      width: "250px",
                      marginTop: "32px",
                      flexDirection: "column",
                      overflowY: "scroll",
                      border: "1px solid #b5b5b5"
                    }}
                  >
                    {this.renderNewsFeed()}
                  </div>
                </div>
                <div style={{ height: "100%" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex" }}>
                      <div>Message Type:</div>
                      <Select
                        className="dropdown"
                        options={this.state.types}
                        onChange={this.handleTypeChange}
                        value={this.state.type}
                      />
                    </div>
                    <div>Title (30 chars max):</div>
                    <input
                      onChange={this.inputChangeHandler}
                      //disabled={selectedReport === ""}
                      ref={input => (this.title = input)}
                      value={title}
                    />
                  </div>
                  {this.renderStatus()}
                  <div className="tinyMCE-wrapper">
                    <div
                      className="toolbar"
                      style={{
                        height: "10px",
                        maxHeight: "10px",
                        width: "800px"
                        // maxWidth: "0px"
                      }}
                    />
                    <div className="tinyMCE-container">
                      <div className="message-container">
                        <div style={{}}>{this.renderType()}</div>
                        <div className="tinymce-body">
                          <Editor
                            //disabled={selectedReport === ""}
                            ref={editor => {
                              this.editor = editor;
                            }}
                            selector=".tinymce-body"
                            apiKey="pvb1gt41v3ao2iqre7v6pexbfzkpwahqb07bna8kku53ydbt"
                            id={"tinyMCE"}
                            onEditorChange={this.handleEditorChange}
                            value={this.state.content}
                            // onSaveContent={this.saveHandler}
                            init={{
                              setup: editor => {
                                this.setState({ activeEditor: editor });
                              },
                              inline: true,
                              fixed_toolbar_container: ".toolbar",
                              images_upload_url: "postAcceptor.php",
                              images_upload_handler: this.uploadCallback,
                              plugins:
                                "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
                              menubar: false,
                              toolbar_mode: "floating",
                              toolbar:
                                "insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image code table",
                              powerpaste_word_import: "clean",
                              powerpaste_html_import: "clean",
                              content_css: [
                                "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i"
                              ]
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.clientReducer.clients,
  selectedClient: state.clientReducer.selectedClient,
  feedItems: state.reportReducer.feedItems
});
const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  setClient(client) {
    dispatch(setClient(client));
  },
  saveSiteMessage(client, message, content) {
    dispatch(saveSiteMessage(client, message, content));
  },
  getSiteMessages(client) {
    dispatch(getSiteMessages(client));
  },
  deleteSiteMessage(client, message) {
    dispatch(deleteSiteMessage(client, message));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(SiteMessages);
