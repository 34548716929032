import axios from "axios";
// import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  reportSubscriptions: [],
  searchReportSubscriptions: [],
  clientRptSubs: [],
  loading: true,
  error: null
};

/* -------------------- ACTION TYPES -------------------- */
const GET_REPORT_SUBSCRIPTIONS_SUCCESS = "GET_REPORT_SUBSCRIPTIONS_SUCCESS";
const GET_REPORT_SUBSCRIPTIONS_BEGIN = "GET_REPORT_SUBSCRIPTIONS_BEGIN";
const GET_REPORT_SUBSCRIPTIONS_FAILURE = "GET_REPORT_SUBSCRIPTIONS_FAILURE";
const GET_CLIENT_REPORT_SUBSCRIPTIONS_SUCCESS =
  "GET_CLIENT_REPORT_SUBSCRIPTIONS_SUCCESS";
const GET_CLIENT_REPORT_SUBSCRIPTIONS_BEGIN =
  "GET_CLIENT_REPORT_SUBSCRIPTIONS_BEGIN";
const GET_CLIENT_REPORT_SUBSCRIPTIONS_FAILURE =
  "GET_CLIENT_REPORT_SUBSCRIPTIONS_FAILURE";

/* -------------------- ACTION CREATORS -------------------- */

const fetchReportSubsBegin = () => ({
  type: GET_REPORT_SUBSCRIPTIONS_BEGIN
});
const fetchReportSubsSuccess = rptSubs => {
  return { type: GET_REPORT_SUBSCRIPTIONS_SUCCESS, payload: rptSubs };
};
const fetchReportSubsFailure = () => ({
  type: GET_REPORT_SUBSCRIPTIONS_FAILURE
});

const fetchClientReportSubsBegin = () => ({
  type: GET_CLIENT_REPORT_SUBSCRIPTIONS_BEGIN
});
const fetchClientReportSubsSuccess = rptSubs => {
  return { type: GET_CLIENT_REPORT_SUBSCRIPTIONS_SUCCESS, payload: rptSubs };
};
const fetchClientReportSubsFailure = () => ({
  type: GET_CLIENT_REPORT_SUBSCRIPTIONS_FAILURE
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getReportSubscriptions = () => {
  return dispatch => {
    dispatch(fetchReportSubsBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getReportSubscriptionByDataset`,
        {},
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchReportSubsSuccess(response.data.rptSubs));
      })
      .catch(err => {
        dispatch(fetchReportSubsFailure());
      });
  };
};
export const getReportSubscriptionsByClient = client => {
  return dispatch => {
    dispatch(fetchClientReportSubsBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getReportSubscriptionByClient`,
        { client },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchClientReportSubsSuccess(response.data.rptSubs));
      })
      .catch(err => {
        dispatch(fetchClientReportSubsFailure());
      });
  };
};

export const saveReportSubscription = (rptSub, client) => {
  return dispatch => {
    // dispatch(fetchReportSubscriptionsBegin());

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/updateReportSubscription`,
        {
          rptSub,
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log("Saved");
        dispatch(getReportSubscriptionsByClient(client));
        // dispatch(fetchReportSubscriptionsSuccess(response.data.subscriptions));
      })
      .catch(error => dispatch(fetchReportSubsFailure(error)));
  };
};
export const deleteReportSubscription = (rptSubId, client) => {
  return dispatch => {
    // dispatch(fetchReportSubscriptionsBegin());

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/deleteReportSubscription`,
        {
          rptSubId,
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log("Deleted");
        let clientSplit = client;
        if (client.includes("+")) {
          clientSplit = client.split("+")[0];
        }
        dispatch(getReportSubscriptionsByClient(clientSplit));
        // dispatch(fetchReportSubscriptionsSuccess(response.data.subscriptions));
      })
      .catch(error => dispatch(fetchReportSubsFailure(error)));
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_SUBSCRIPTIONS_BEGIN:
      return {
        ...state,
        loading: true
      };

    case GET_REPORT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        reportSubscriptions: action.payload,
        searchReportSubscriptions: action.payload,
        loading: false
      };
    case GET_REPORT_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      };
    case GET_CLIENT_REPORT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        clientRptSubs: action.payload
      };

    default:
      return state;
  }
};
