import axios from "axios";
// import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  uploadInboxes: [],
  inboxFiles: [],
  loading: true,
  error: null
};

/* -------------------- ACTION TYPES -------------------- */
const GET_UPLOAD_INBOXES_SUCCESS = "GET_UPLOAD_INBOXES_SUCCESS";
const GET_UPLOAD_INBOXES_BEGIN = "GET_UPLOAD_INBOXES_BEGIN";
const GET_UPLOAD_INBOXES_FAILURE = "GET_UPLOAD_INBOXES_FAILURE";
const GET_INBOX_FILES_SUCCESS = "GET_INBOX_FILES_SUCCESS";
const GET_INBOX_FILES_BEGIN = "GET_INBOX_FILES_BEGIN";
const GET_INBOX_FILES_FAILURE = "GET_INBOX_FILES_FAILURE";

/* -------------------- ACTION CREATORS -------------------- */

const fetchUploadInboxesBegin = () => ({
  type: GET_UPLOAD_INBOXES_BEGIN
});
const fetchUploadInboxesSuccess = inboxes => {
  return { type: GET_UPLOAD_INBOXES_SUCCESS, payload: inboxes };
};
const fetchUploadInboxesFailure = () => ({
  type: GET_UPLOAD_INBOXES_FAILURE
});

const fetchInboxFilesBegin = () => ({
  type: GET_INBOX_FILES_BEGIN
});
const fetchInboxFilesSuccess = rptSubs => {
  return { type: GET_INBOX_FILES_SUCCESS, payload: rptSubs };
};
const fetcInboxFilesFailure = () => ({
  type: GET_INBOX_FILES_FAILURE
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getUploadInboxes = () => {
  return dispatch => {
    dispatch(fetchUploadInboxesBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getUploadInboxes`,
        {},
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchUploadInboxesSuccess(response.data.uploadInboxes));
      })
      .catch(err => {
        dispatch(fetchUploadInboxesFailure());
      });
  };
};
export const getFilesByInbox = client => {
  return dispatch => {
    dispatch(fetchInboxFilesBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getUploadInboxFiles`,
        { client },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchInboxFilesSuccess(response.data.rptSubs));
      })
      .catch(err => {
        dispatch(fetcInboxFilesFailure());
      });
  };
};

export const saveUploadInbox = (name, notifications) => {
  return dispatch => {
    // dispatch(fetchReportSubscriptionsBegin());

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveUploadInbox`,
        {
          name,
          notifications
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log("Saved");
        dispatch(getUploadInboxes());
        // dispatch(fetchReportSubscriptionsSuccess(response.data.subscriptions));
      })
      .catch(error => dispatch(fetchUploadInboxesFailure(error)));
  };
};
export const deleteUploadInbox = inbox => {
  return dispatch => {
    // dispatch(fetchReportSubscriptionsBegin());

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/deleteUploadInbox`,
        {
          inbox
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getUploadInboxes());
        // dispatch(fetchReportSubscriptionsSuccess(response.data.subscriptions));
      })
      .catch(error => dispatch(fetchUploadInboxesFailure(error)));
  };
};
export const deleteUploadInboxFiles = inboxes => {
  return dispatch => {
    // dispatch(fetchReportSubscriptionsBegin());

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/deleteUploadInboxFiles`,
        {
          inboxes
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getUploadInboxes());
        // dispatch(fetchReportSubscriptionsSuccess(response.data.subscriptions));
      })
      .catch(error => dispatch(fetchUploadInboxesFailure(error)));
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_UPLOAD_INBOXES_BEGIN:
      return {
        ...state,
        loading: true
      };

    case GET_UPLOAD_INBOXES_SUCCESS:
      return {
        ...state,
        uploadInboxes: action.payload,
        loading: false
      };
    case GET_UPLOAD_INBOXES_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      };
    case GET_INBOX_FILES_SUCCESS:
      return {
        ...state,
        inboxFiles: action.payload
      };

    default:
      return state;
  }
};
