import React, { Component } from "react";
import "./App.css";
import { Gear } from "./lib/icons";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import WorkspaceMapping from "./components/workspaceMapping";
import ReportMetadata from "./components/reportMetadata";
import UserManagement from "./components/userManagement";
import BighornLogin from "./components/bighornLogin";
import StaticContent from "./components/staticContent";
import SiteMessages from "./components/siteMessages";
import DataManagement from "./components/dataManagement";
import ReportFolders from "./components/reportFolders";
import CustomApplications from "./components/customApplications";
import ExcelRefresh from "./components/excelRefresh";
import ReportSubscriptions from "./components/reportSubscriptions/reportSubscriptions";
import FileUpload from "./components/fileUpload/fileUpload";
import ShareLinks from "./components/shareLinks";
import Client from "./components/client";
import Files from "./components/files";
import LandingPage from "./components/landingPage";
import { refreshWorkspaces } from "./reducers/workspaceReducer";
import { getApps } from "./reducers/authReducer";

import Login from "./components/login";

class App extends Component {
  constructor(props) {
    super(props);
    let tSelect = "client";

    this.state = {
      selectedClient: null,
      tabSelected: tSelect,
      initialSelect: false
    };
    this.renderTabs = this.renderTabs.bind(this);
  }
  renderTabsContent() {
    if (this.state.tabSelected === "workspace") {
      return <WorkspaceMapping />;
    } else if (this.state.tabSelected === "reportMetadata") {
      return <ReportMetadata />;
    } else if (this.state.tabSelected === "userManagement") {
      return <UserManagement />;
    } else if (this.state.tabSelected === "bighornLogin") {
      return <BighornLogin />;
    } else if (this.state.tabSelected === "files") {
      return <Files />;
    } else if (this.state.tabSelected === "staticContent") {
      return <StaticContent />;
    } else if (this.state.tabSelected === "siteMessages") {
      return <SiteMessages />;
    } else if (this.state.tabSelected === "landingPage") {
      return <LandingPage />;
    } else if (this.state.tabSelected === "dataManagement") {
      return <DataManagement />;
    } else if (this.state.tabSelected === "client") {
      return <Client />;
    } else if (this.state.tabSelected === "reportFolders") {
      return <ReportFolders />;
    } else if (this.state.tabSelected === "customApplications") {
      return <CustomApplications />;
    } else if (this.state.tabSelected === "excelRefresh") {
      return <ExcelRefresh />;
    } else if (this.state.tabSelected === "reportSubscriptions") {
      return <ReportSubscriptions />;
    } else if (this.state.tabSelected === "fileUpload") {
      return <FileUpload />;
    } else if (this.state.tabSelected === "shareLinks") {
      return <ShareLinks />;
    }
  }

  renderTabs() {
    const { role } = this.props;
    if (role === "ovis-admin") {
      return (
        <div className="ovis-tabs">
          <div
            className={
              this.state.tabSelected === "bighornLogin"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "bighornLogin" })}
          >
            Bighorn Login
          </div>

          <div
            className={
              this.state.tabSelected === "client"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "client" })}
          >
            Client
          </div>
          <div
            className={
              this.state.tabSelected === "excelRefresh"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "excelRefresh" })}
          >
            Excel Refresh
          </div>
          <div
            className={
              this.state.tabSelected === "files"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "files" })}
          >
            File Management
          </div>
          <div
            className={
              this.state.tabSelected === "shareLinks"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "shareLinks" })}
          >
            File Share Links
          </div>
          <div
            className={
              this.state.tabSelected === "fileUpload"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "fileUpload" })}
          >
            File Upload Inbox
          </div>
          <div
            className={
              this.state.tabSelected === "landingPage"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "landingPage" })}
          >
            Landing Page
          </div>
          <div
            className={
              this.state.tabSelected === "customApplications"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "customApplications" })}
          >
            Manage Custom Applications
          </div>
          <div
            className={
              this.state.tabSelected === "reportFolders"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "reportFolders" })}
          >
            Report Folders
          </div>
          <div
            className={
              this.state.tabSelected === "reportMetadata"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "reportMetadata" })}
          >
            {" "}
            Report Metadata
          </div>
          <div
            className={
              this.state.tabSelected === "reportSubscriptions"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() =>
              this.setState({ tabSelected: "reportSubscriptions" })
            }
          >
            {" "}
            Report Subscriptions
          </div>
          <div
            className={
              this.state.tabSelected === "siteMessages"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "siteMessages" })}
          >
            Site Messages
          </div>
          <div
            className={
              this.state.tabSelected === "staticContent"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "staticContent" })}
          >
            Static Content
          </div>
          <div
            className={
              this.state.tabSelected === "userManagement"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "userManagement" })}
          >
            {" "}
            User Management
          </div>
          <div
            className={
              this.state.tabSelected === "workspace"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "workspace" })}
          >
            Workspace/Workbook Mapping
          </div>
        </div>
      );
    } else if (role === "ovis-content-dev") {
      return (
        <div className="ovis-tabs">
          <div
            className={
              this.state.tabSelected === "bighornLogin"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "bighornLogin" })}
          >
            Bighorn Login
          </div>
          <div
            className={
              this.state.tabSelected === "files"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "files" })}
          >
            File Management
          </div>
          <div
            className={
              this.state.tabSelected === "shareLinks"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "shareLinks" })}
          >
            File Share Links
          </div>
          <div
            className={
              this.state.tabSelected === "fileUpload"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "fileUpload" })}
          >
            File Upload Inbox
          </div>
          <div
            className={
              this.state.tabSelected === "landingPage"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "landingPage" })}
          >
            Landing Page
          </div>

          <div
            className={
              this.state.tabSelected === "reportFolders"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "reportFolders" })}
          >
            Report Folders
          </div>
          <div
            className={
              this.state.tabSelected === "reportMetadata"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "reportMetadata" })}
          >
            {" "}
            Report Metadata
          </div>
          <div
            className={
              this.state.tabSelected === "siteMessages"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "siteMessages" })}
          >
            Site Messages
          </div>
          <div
            className={
              this.state.tabSelected === "staticContent"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "staticContent" })}
          >
            Static Content
          </div>
          <div
            className={
              this.state.tabSelected === "workspace"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "workspace" })}
          >
            Workspace/Workbook Mapping
          </div>
          <div
            className={
              this.state.tabSelected === "customApplications"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "customApplications" })}
          >
            Manage Custom Applications
          </div>
        </div>
      );
    } else if (role === "ovis-file-share") {
      return (
        <div className="ovis-tabs">
          <div
            className={
              this.state.tabSelected === "shareLinks"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "shareLinks" })}
          >
            File Share Links
          </div>
          <div
            className={
              this.state.tabSelected === "fileUpload"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "fileUpload" })}
          >
            File Upload Inbox
          </div>
        </div>
      );
    } else {
      return (
        <div className="ovis-tabs">
          <div
            className={
              this.state.tabSelected === "bighornLogin"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "bighornLogin" })}
          >
            Bighorn Login
          </div>
          <div
            className={
              this.state.tabSelected === "shareLinks"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "shareLinks" })}
          >
            File Share Links
          </div>
          <div
            className={
              this.state.tabSelected === "fileUpload"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "fileUpload" })}
          >
            File Upload Inbox
          </div>
          <div
            className={
              this.state.tabSelected === "siteMessages"
                ? "ovis-tab-selected ovis-tab"
                : "ovis-tab"
            }
            onClick={() => this.setState({ tabSelected: "siteMessages" })}
          >
            Site Messages
          </div>
        </div>
      );
    }
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated()) {
      if (this.props.location.pathname === "/") {
        this.props.history.replace("/ovis");
      }
      this.props.getApps();
    }
  }
  componentDidUpdate() {
    if (this.props.apps.length > 0) {
      if (!this.state.initialSelect) {
        var tSelect = "bighornLogin";
        // if (this.props.role === "ovis-content-dev") {
        //   tSelect = "bighornLogin";
        // } else if (this.props.role === "ovis-analyst") {
        //   tSelect = "bighornLogin";
        // }
        if (this.props.role === "ovis-file-share") {
          tSelect = "shareLinks";
        }
        this.setState({ tabSelected: tSelect, initialSelect: true });
      }
      this.props.refreshWorkspaces();
    }
  }
  goTo(route) {
    this.props.history.replace(`/${route}`);
  }

  login() {
    this.props.auth.login();
    //this.props.dispath(loginUser());
  }

  logout() {
    this.props.auth.logout();
  }

  render() {
    const { loading, allLoading, apps } = this.props;
    const { isAuthenticated } = this.props.auth;
    if (loading && allLoading) {
      return <div>Loading</div>;
    } else if (!isAuthenticated()) {
      return (
        <div className="login-wrapper">
          <div className="login-msg">
            You are not logged in. Please Log In to continue.
          </div>
          {/* <div>
            <Button
              id="qsLoginBtn"
              bsStyle="primary"
              style={{ background: "rgba(0, 126, 167, 1)" }}
              className="btn-margin"
              onClick={this.login.bind(this)}
            >
              Log In
            </Button>
          </div> */}
          <Login auth={this.props.auth} />
        </div>
      );
    } else if (apps.length > 0) {
      return (
        <div className="app" style={{ height: "100%" }}>
          <div className="app-header">
            <Gear
              className="gear"
              height={"20px"}
              style={{ marginRight: "10px", marginTop: "4px" }}
              width={"20px"}
              color={"rgba(0, 126, 167, 1)"}
            />
            <div
              style={{
                display: "inline-flex",
                position: "relative",
                top: "1px"
              }}
              className="app-title"
            >
              Ovis Management System
            </div>
            <div
              className="logout ta-button ta-button-secondary"
              onClick={() => this.logout()}
            >
              Logout
            </div>
          </div>
          <div className="tab-area">
            {this.renderTabs()}
            {this.renderTabsContent()}
          </div>
        </div>
      );
    } else if (apps.length < 0 && isAuthenticated()) {
      return (
        <div className="app" style={{ height: "100%" }}>
          <div className="app-header">
            <Gear
              className="gear"
              height={"20px"}
              style={{ marginRight: "10px", marginTop: "4px" }}
              width={"20px"}
              color={"rgba(0, 126, 167, 1)"}
            />
            <div
              style={{
                display: "inline-flex",
                position: "relative",
                top: "1px"
              }}
              className="app-title"
            >
              Ovis Management System
            </div>
            <div
              className="logout ta-button ta-button-secondary"
              onClick={() => this.logout()}
            >
              Logout
            </div>
          </div>
          <div className="tab-area">
            You do not have permissions for this app.
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = state => ({
  apps: state.auth.apps,
  appsLoaded: state.auth.appsLoaded,
  role: state.auth.role,
  workspaces: state.workspaceReducer.workspaces
});

const mapDispatchToProps = dispatch => ({
  refreshWorkspaces() {
    dispatch(refreshWorkspaces());
  },
  getApps() {
    dispatch(getApps());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
