import axios from "axios";
import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  users: [],
  searchUsers: [],
  loading: true,
  error: null,
  inviteToastMessage: null,
  isInviting: false
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_USERS_BEGIN = "FETCH_USERS_BEGIN";
const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

const ADD_USER_FAILURE = "ADD_USER_FAILURE";
const SET_SEARCH_USERS = "SET_SEARCH_USERS";
const SET_TOAST_MESSAGE = "SET_TOAST_MESSAGE";
const CLEAR_TOAST_MESSAGE = "CLEAR_TOAST_MESSAGE";
const SET_INVITE_STATUS = "SET_INVITE_STATUS";

/* -------------------- ACTION CREATORS -------------------- */

const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});
const fetchUsersSuccess = users => {
  return { type: FETCH_USERS_SUCCESS, payload: users };
};
const fetchUsersError = error => ({
  type: FETCH_USERS_FAILURE,
  payload: { error }
});

const addUsersError = error => ({
  type: ADD_USER_FAILURE,
  payload: { error }
});

const setSearchUsers = users => {
  return { type: SET_SEARCH_USERS, payload: users };
};
const setToastMessage = (pass, message) => {
  return { type: SET_TOAST_MESSAGE, pass, message };
};
const doClearToasts = () => {
  return { type: CLEAR_TOAST_MESSAGE };
};
const setInviteStatus = status => {
  return { type: SET_INVITE_STATUS, payload: status };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */

export const getUsers = client => {
  return dispatch => {
    dispatch(fetchUsersBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getAllUsersForClient`,
        {
          client: client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchUsersSuccess(response.data.users));
      })
      .catch(error => dispatch(fetchUsersError(error)));
  };
};

export const addNewUser = (client, user) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/addUser`,
        {
          client: client,
          user
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
            // Authorization: "Bearer " + localStorage.getItem("access_token")
          }
        }
      )
      .then(response => {
        dispatch(getUsers(client));
      })
      .catch(error => dispatch(addUsersError(error)));
  };
};

export const addExtension = (client, users) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/msGraph/addExtensionToUsers`,
        {
          client: client,
          users
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
            //Authorization: "Bearer " + localStorage.getItem("access_token")
          }
        }
      )
      .then(response => {
        console.log(response);
        //dispatch(getUsers(client));
        //dispatch(addExtension(client, response.data.result.users));
        //dispatch(getUsers(client));
      })
      .catch(error => console.log("ERROR"));
  };
};

export const addUserToGroup = (client, users) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/msGraph/addUserToGroup`,
        {
          client: client,
          users
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);

        //dispatch(addExtension(client, response.data.result.users));
        dispatch(getUsers(client));
      })
      .catch(error => console.log("ERROR"));
  };
};

export const graphUpdateUserGroups = (client, users) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/msGraph/graphUpdateUserGroups`,
        {
          client: client,
          users
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);
        //dispatch(addExtension(client, response.data.result.users));
        dispatch(getUsers(client));
      })
      .catch(error => console.log("ERROR"));
  };
};

export const updateUserGroups = (client, user) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/updateUserGroups`,
        {
          client,
          user
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);
        //dispatch(addExtension(client, response.data.result.users));
        dispatch(getUsers(client));
      })
      .catch(error => console.log("ERROR"));
  };
};
export const deleteUsers = (client, users) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/msGraph/deleteUsers`,
        {
          client,
          users
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        let message;

        if (response.data.success === false) {
          message = response.data.message;
          dispatch(setToastMessage(false, message));
        } else {
          message = "Users Deleted";
          dispatch(setToastMessage(true, message));
        }
        dispatch(getUsers(client));
        // if (response.data.response.length > 0) {
        //   dispatch(addExtension(client, response.data.response));
        //   dispatch(addUserToGroup(client, response.data.response));
        // }
      })
      .catch(error => console.log("ERROR")); //dispatch(addUsersError(error)));
  };
};

export const inviteUsers = client => {
  return dispatch => {
    dispatch(setInviteStatus(true));
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/msGraph/inviteAllUsers`,
        {
          client: client
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);
        dispatch(setInviteStatus(false));
        if (response.data.success === false) {
          let message = response.data.user + ": " + response.data.message;
          dispatch(setToastMessage(false, message));
        } else if (response.data.response.length > 0) {
          dispatch(setToastMessage(true, "All Users invited.", ""));
          // dispatch(addExtension(client, response.data.response));
          //dispatch(addUserToGroup(client, response.data.response));
          dispatch(getUsers(client));
        }
      })
      .catch(error => console.log("ERROR")); //dispatch(addUsersError(error)));
  };
};
export const inviteSpecifiedUsers = (client, users) => {
  return dispatch => {
    dispatch(setInviteStatus(true));
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/msGraph/inviteSpecifiedUsers`,
        {
          users,
          client
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);
        dispatch(setInviteStatus(false));
        if (response.data.success === false) {
          let message = response.data.user + ": " + response.data.message;
          dispatch(setToastMessage(false, message));
        } else if (response.data.response.length > 0) {
          dispatch(setToastMessage(true, "Specified Users invited.", ""));
          // let userArr = users.map(item => {
          //   return item.id;
          // });
          // let newUsers = response.data.response.filter(fUser => {
          //   return userArr.includes(fUser.id);
          // });
          // dispatch(addExtension(client, newUsers));
          //dispatch(addUserToGroup(client, newUsers));

          dispatch(getUsers(client));
        }
      })
      .catch(error => console.log("ERROR")); //dispatch(addUsersError(error)));
  };
};
export const updateUser = user => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/msGraph/updateUser`,
        {
          user
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(result => {});
  };
};

export const filterUsers = (filter, users) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchUsers(users));
    } else {
      let newUsers = cloneDeep(users);
      newUsers = newUsers.filter(user => {
        return user.userName.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      });
      dispatch(setSearchUsers(newUsers));
    }
  };
};

export const clearToasts = () => {
  return dispatch => {
    dispatch(doClearToasts());
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USERS_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        searchUsers: action.payload,
        loading: false
      };
    case SET_SEARCH_USERS:
      return { ...state, searchUsers: action.payload };
    case FETCH_USERS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    case ADD_USER_FAILURE:
      return { ...state, error: action.payload.error };
    case SET_TOAST_MESSAGE:
      return {
        ...state,
        inviteToastMessage: {
          message: action.message,
          pass: action.pass
        }
      };
    case CLEAR_TOAST_MESSAGE:
      return {
        ...state,
        inviteToastMessage: null
      };
    case SET_INVITE_STATUS:
      return { ...state, isInviting: action.payload };
    default:
      return state;
  }
};
