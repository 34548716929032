import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import {
  getAllWorkspaces,
  setWorkspace
} from "../../reducers/workspaceReducer";
import { getClients, setClient } from "../../reducers/clientReducer";
import {
  getReports,
  getStaticContent,
  saveStaticContent,
  deleteStaticContent
} from "../../reducers/reportReducer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//import axios from "axios";
import "./style.css";
import { Editor } from "@tinymce/tinymce-react";

import { toast } from "react-toastify";

class StaticContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      unsavedChanges: false,
      selectedWorkspace: null,
      content: " ",
      selectedReport: "",
      unsavedContent: " ",
      reportName: "",
      showDeleteConfirmModal: false
    };
    this.cancelHandler = this.cancelHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReportChange = this.handleReportChange.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.uploadCallback = this.uploadCallback.bind(this);
  }

  componentDidMount() {
    this.props.getAllWorkspaces(false);
  }

  componentDidUnMount() {
    this.props.selectedWorkspace(null);
    this.props.setWorkspace(null);
  }
  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }
  handleChange(selectedOption) {
    this.props.setWorkspace(selectedOption.label);

    this.setState({
      selectedWorkspace: selectedOption,
      selectedReport: { label: "new page", html: "", name: "new page", id: 44 },
      reportName: "new page"
    });
    this.props.getStaticContent(selectedOption.id);
  }
  handleReportChange(selectedOption) {
    //this.props.setWorkspace(selectedOption.label);

    this.setState({
      selectedReport: selectedOption,
      content: selectedOption.html,
      reportName: selectedOption.name,
      unsavedContent: " ",
      unsavedChanges: false
    });

    //this.props.getStaticContent(selectedOption.id);
  }
  uploadCallback(blobInfo, progress) {
    return new Promise((resolve, reject) => {
      let file = blobInfo.blob;

      //const xhr = new XMLHttpRequest();

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/uploadImage`
      );
      xhr.setRequestHeader("ClientToken", localStorage.getItem("clientToken"));
      var data = new FormData();
      data.append("image", blobInfo.blob());
      data.append("workspace", this.state.selectedWorkspace.name);
      data.size = file.size;
      xhr.send(data);
      xhr.upload.onprogress = e => {
        progress((e.loaded / e.total) * 100);
      };
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response.data.link);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
        return;
      });
    });
  }
  handleEditorChange(e) {
    const { selectedReport } = this.state;
    if (
      e !== "" &&
      e.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, "") !==
        this.state.selectedReport.html
    ) {
      //Need to do this because this handler gets called when we mess with the SAS tokens
      let rmESAS = e.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, "");
      let rmECount = this.count(rmESAS, "img src=");
      var x = 1;

      while (x <= rmECount) {
        let img = rmESAS
          .split("img src=")
          // eslint-disable-next-line no-unexpected-multiline
          [x].split("'")[1]
          .split("?")[0];

        if (img.includes("tabsportal.blob.core")) {
          let remove = rmESAS
            .split("img src=")
            // eslint-disable-next-line no-unexpected-multiline
            [x].split("'")[1]
            .split("?")[1];

          rmESAS = rmESAS.replace("?" + remove, "");
        }

        x = x + 1;
      }
      let rmSAS = this.state.selectedReport.html;
      let rmCount = this.count(rmSAS, "img src=");
      x = 1;

      while (x <= rmCount) {
        let img = rmSAS
          .split("img src='")
          // eslint-disable-next-line no-unexpected-multiline
          [x].split("'")[0]
          .split("?")[0];
        if (img.includes("tabsportal.blob.core")) {
          let remove = rmSAS
            .split("img src='")
            // eslint-disable-next-line no-unexpected-multiline
            [x].split("'")[0]
            .split("?")[1];

          rmSAS = rmSAS.replace("?" + remove, "");
        }

        x = x + 1;
      }

      if (rmESAS !== rmSAS) {
        let sReport = {
          html: e.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, ""),
          label: selectedReport.label,
          name: selectedReport.name,
          id: selectedReport._id
        };

        this.setState({
          unsavedChanges: true,
          selectedReport: sReport,
          unsavedContent: e.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, ""),
          content: e.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, "")
        });
      }
    }
  }
  count(main_str, sub_str) {
    main_str += "";
    sub_str += "";

    if (sub_str.length <= 0) {
      return main_str.length + 1;
    }

    let subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    return (main_str.match(new RegExp(subStr, "gi")) || []).length;
  }
  saveHandler() {
    const { unsavedContent, reportName, selectedReport } = this.state;
    let compareReport = [];
    if (this.props.reports !== null) {
      compareReport = this.props.reports.filter(report => {
        return report.label === selectedReport.label;
      });
    }

    //This is the id generated for the new page option
    let report = {};
    let id = this.state.selectedReport.id;

    if (id === 44) {
      report = { label: reportName.trim(), name: reportName.trim(), id: null };
    } else if ((id === null || id === undefined) && compareReport.length > 0) {
      report = {
        label: reportName.trim(),
        name: reportName.trim(),
        id: compareReport[0]._id
      };
    } else {
      report = {
        label: reportName.trim(),
        name: reportName.trim(),
        id
      };
    }

    let rmSAS = unsavedContent;

    //Remove the SAS token from the image before saving it
    let rmCount = this.count(unsavedContent, "img src=");
    let x = 1;

    while (x <= rmCount) {
      let img = rmSAS
        .split("img src='")
        // eslint-disable-next-line no-unexpected-multiline
        [x].split("'")[0]
        .split("?")[0];
      if (img.includes("tabsportal.blob.core")) {
        let remove = rmSAS
          .split("img src='")
          // eslint-disable-next-line no-unexpected-multiline
          [x].split("'")[0]
          .split("?")[1];

        rmSAS = rmSAS.replace("?" + remove, "");
      }

      x = x + 1;
    }
    // let remove = rmSAS
    //   .split("src='")[1]
    //   .split("'")[0]
    //   .split("?")[1];
    // rmSAS = rmSAS.replace("?" + remove, "");
    this.props.saveStaticContent(this.state.selectedWorkspace, report, rmSAS);

    // this.setState({
    //   selectedReport: selectedOption,
    //   content: selectedOption.html,
    //   reportName: selectedOption.name
    // });

    this.setState({
      unsavedChanges: false,
      selectedReport: report
    });
  }
  cancelHandler(e) {
    this.setState({ content: "" });
  }
  inputChangeHandler() {
    this.setState({
      unsavedChanges: true,
      reportName: this.reportName.value
    });
  }
  deleteHandler() {
    this.props.deleteStaticContent(
      this.state.selectedWorkspace,
      this.state.selectedReport
    );
    toast.success("Deleted Static Content " + this.state.reportName);
    this.setState({
      content: "",
      selectedReport: { label: "new page", html: "", name: "new page", id: 44 },
      reportName: "new page"
    });

    this.handleClose();
  }
  handleClose() {
    this.setState({ showDeleteConfirmModal: false });
  }
  render() {
    // const customStyles = {
    //   option: (base, state) => ({
    //     ...base,
    //     backgroundColor: state.isSelected ? "green" : "white"
    //   })
    // };
    const { allWorkspaces, searchReports } = this.props;

    const {
      selectedWorkspace,
      selectedReport,
      reportName,
      showDeleteConfirmModal
    } = this.state;

    return (
      <div style={{ height: "85%", width: "77%" }}>
        <Modal isOpen={showDeleteConfirmModal}>
          <ModalHeader>Confirm Deletion</ModalHeader>

          <ModalBody>
            You are about to delete this static content, are you sure you want
            to do this?
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.deleteHandler}>
              Ok
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <div className="client" style={{ paddingBottom: "20px" }}>
          <div className="client-text">Page Location:</div>
          <Select
            className="dropdown"
            key={JSON.stringify(allWorkspaces)}
            value={selectedWorkspace}
            onChange={this.handleChange}
            options={allWorkspaces}
          />
          <div className="client-text">Page:</div>
          {searchReports !== null ? (
            <Select
              className="dropdown"
              value={selectedReport}
              key={JSON.stringify(selectedReport)}
              onChange={this.handleReportChange}
              options={searchReports}
            />
          ) : (
            <Select
              value={selectedReport}
              className="dropdown"
              isDisabled={true}
            />
          )}
          <div className="action-buttons">
            <div
              className={
                this.state.unsavedChanges &&
                this.state.reportName !== "" &&
                this.state.unsavedChanges &&
                this.state.reportName.trim() !== "new page"
                  ? "ta-button ta-button-primary"
                  : "ta-button ta-button-disabled"
              }
              onClick={
                this.state.unsavedChanges &&
                this.state.reportName !== "" &&
                this.state.unsavedChanges &&
                this.state.reportName.trim() !== "new page"
                  ? this.saveHandler
                  : null
              }
            >
              Save
            </div>
            <div
              className={
                this.state.unsavedChanges
                  ? "ta-button ta-button-secondary"
                  : "ta-button ta-button-disabled"
              }
              onClick={this.state.unsavedChanges ? this.cancelHandler : null}
            >
              Cancel
            </div>
            <div
              className={"ta-button ta-button-secondary"}
              onClick={() => {
                this.setState({ showDeleteConfirmModal: true });
              }}
            >
              Delete
            </div>
          </div>
        </div>
        <div className="user-group-title-div" />
        <div style={{ display: "flex" }}>
          <div>Page Name:</div>
          <input
            onChange={this.inputChangeHandler}
            disabled={selectedReport === ""}
            ref={input => (this.reportName = input)}
            value={reportName}
          />
        </div>
        <Editor
          disabled={selectedReport === ""}
          apiKey="pvb1gt41v3ao2iqre7v6pexbfzkpwahqb07bna8kku53ydbt"
          id={"tinyMCE"}
          onEditorChange={this.handleEditorChange}
          value={this.state.content}
          init={{
            images_upload_url: "postAcceptor.php",
            images_upload_handler: this.uploadCallback,
            plugins:
              "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
            menubar: false,
            toolbar:
              "insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image code table"
          }}
        />

        {/* <Editor
          toolbar={{
            image: {
              urlEnabled: false,
              uploadEnabled: true,
              alignmentEnabled: true,
              uploadCallback: this.uploadCallback,
              previewImage: false,
              inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
              alt: { present: true, mandatory: false },
              defaultSize: {
                height: "auto",
                width: "auto"
              }
            }
          }}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedClient: state.clientReducer.selectedClient,
  clients: state.clientReducer.clients,
  allWorkspaces: state.workspaceReducer.allWorkspaces,
  wsLoading: state.workspaceReducer.loading,
  reports: state.reportReducer.reports,
  searchReports: state.reportReducer.searchReports,
  error: state.reportReducer.error
});
const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  setClient(client) {
    dispatch(setClient(client));
  },
  getAllWorkspaces(justPowerBI) {
    dispatch(getAllWorkspaces(justPowerBI));
  },
  getStaticContent(workspace) {
    dispatch(getStaticContent(workspace));
  },
  setWorkspace(workspace) {
    dispatch(setWorkspace(workspace));
  },
  saveStaticContent(workspace, report, content) {
    dispatch(saveStaticContent(workspace, report, content));
  },
  deleteStaticContent(workspace, report) {
    dispatch(deleteStaticContent(workspace, report));
  },
  getReports(workspace) {
    dispatch(getReports(workspace));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(StaticContent);
