import axios from "axios";
import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: null,
  refreshes: [],
  searchRefreshes: []
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_REFRESHES_BEGIN = "FETCH_REFRESHES_BEGIN";
const FETCH_REFRESHES_SUCCESS = "FETCH_REFRESHES_SUCCESS";
const FETCH_REFRESHES_FAILURE = "FETCH_REFRESHES_FAILURE";
const SET_SEARCH_REFRESHES = "SET_SEARCH_REFRESHES";

/* -------------------- ACTION CREATORS -------------------- */

const fetchRefreshesBegin = () => ({
  type: FETCH_REFRESHES_BEGIN
});
const fetchRefreshesSuccess = files => {
  return { type: FETCH_REFRESHES_SUCCESS, payload: files };
};
const setSearchRefreshes = files => {
  return { type: SET_SEARCH_REFRESHES, payload: files };
};

const fetchRefreshesError = error => ({
  type: FETCH_REFRESHES_FAILURE,
  payload: { error }
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getRefreshes = () => {
  return dispatch => {
    dispatch(fetchRefreshesBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getAllExcelRefreshes`,
        {
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchRefreshesSuccess(response.data.refreshes));
      })
      .catch(error => {
        dispatch(fetchRefreshesError(error));
      });
  };
};

export const filterRefreshes = (filter, refreshes) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchRefreshes(refreshes));
    } else {
      let newRefreshes = cloneDeep(refreshes);
      let nRef = [];

      nRef = newRefreshes.filter((newRefresh, idx) => {
        return (
          newRefresh.clientName.toLowerCase().indexOf(filter.toLowerCase()) !==
          -1
        );
      });
      dispatch(setSearchRefreshes(nRef));
    }
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REFRESHES_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_REFRESHES_SUCCESS:
      return {
        ...state,
        refreshes: action.payload,
        searchRefreshes: action.payload,
        loading: false,
        loaded: true
      };
    case FETCH_REFRESHES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.error
      };
    case SET_SEARCH_REFRESHES:
      return {
        ...state,
        searchRefreshes: action.payload
      };
    default:
      return state;
  }
};
