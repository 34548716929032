import React, { Component } from "react";

import "../../../components/files/style.css";
import { CheckmarkIcon, DisabledIcon } from "../../../lib/icons";
import cx from "classnames";
import { connect } from "react-redux";
import { selectFiles } from "../../../reducers/filesReducer";
//import moment from "moment-timezone";
//import { EllipsisIcon } from "lib/icons";
//import axios from "axios";
//import azure from "azure-storage";
//import saveAs from "file-saver";
//import { parseToken } from "lib/helpers";

class File extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   selected: false
    // };
    this.clickHandler = this.clickHandler.bind(this);
  }
  clickHandler(file) {
    let selected = false;

    if (file.selected === undefined || file.selected === null) {
      selected = true;
    } else {
      selected = false;
    }
    // this.setState({ selected: !this.state.selected });
    this.props.selectFiles(file, selected);
  }
  renderAutoRefreshColumn(file) {
    if (file.refreshDisable === true) {
      return (
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "50px"
          }}
          onClick={() => this.clickHandler(file)}
        >
          <DisabledIcon
            height={"16"}
            // color={"rgba(88, 89, 91, 1)"}
            color={"red"}
            className="checkmark-icon"
          />
        </div>
      );
    } else if (file.refresh === true) {
      return (
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "50px"
          }}
          onClick={() => this.clickHandler(file)}
        >
          {file.refresh === true ? (
            <CheckmarkIcon
              height={"16"}
              // color={"rgba(88, 89, 91, 1)"}
              color={"rgba(62,197,23,1)"}
              className="checkmark-icon"
            />
          ) : (
            <div style={{ width: "22px" }} />
          )}
        </div>
      );
    } else {
      return (
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "50px"
          }}
          onClick={() => this.clickHandler(file)}
        >
          <div style={{ width: "22px" }} />
        </div>
      );
    }
  }
  render() {
    const { file, index } = this.props;
    let selected = false;
    if (file.selected !== undefined) {
      selected = file.selected;
    }

    let classes = cx("react-list-select-item", {
      "is-selected": selected
      //"is-focused": props.focused
    });
    if (this.props.type !== undefined && this.props.type === "report") {
      return (
        <div key={index} style={{ display: "flex" }}>
          <div key={index}>
            <span
            //className="ta-file-span"
            //onClick={() => this.clickHandler(file)}
            >
              {file.name}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => this.clickHandler(file)}
          className={classes}
          key={index}
          style={{ display: "flex" }}
        >
          <div key={index}>
            <span
              className="ta-file-span"
              //onClick={() => this.clickHandler(file)}
            >
              {file.name}
            </span>
          </div>

          <div
            style={{
              marginLeft: "auto",
              display: "flex"
            }}
          >
            <div>{file.creationTime}</div>
            <div style={{ paddingLeft: "50px", width: "280px" }}>
              {file.lastModified}
            </div>
            <div style={{ paddingLeft: "50px", width: "280px" }}>
              {file.lastSuccessDate}
            </div>
            <div style={{ paddingLeft: "50px", width: "100px" }}>
              {file.status}
            </div>
            {this.renderAutoRefreshColumn(file)}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  selectedFiles: state.filesReducer.selected
});
const mapDispatchToProps = dispatch => ({
  selectFiles(files, select) {
    dispatch(selectFiles(files, select));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(File);
