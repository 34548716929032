import React, { Component } from "react";
import { connect } from "react-redux";
import { setWorkspaces } from "../../reducers/workspaceReducer";
import {
  setGroup,
  addUserGroup,
  filterGroups,
  addUserGroupSuccess,
  setModelRoles
} from "../../reducers/groupReducer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./style/style.css";
import Group from "./components/group";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

class UserGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGroupsModal: false,
      selectedGroup: "",
      inputValue: "",
      groupName: "",
      newGroupName: ""
    };
    this.renderUserGroups = this.renderUserGroups.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  renderUserGroups() {
    const { loading, searchGroups } = this.props;
    if (searchGroups.length !== 0 && !loading) {
      return searchGroups.map((group, index) => {
        return (
          <div
            className={
              this.state.selectedGroup === group.name
                ? "group-item group-item-selected"
                : "group-item"
            }
            key={index}
            onClick={event => {
              this.clickHandler(event, group);
            }}
          >
            <Group
              group={group}
              unsavedChanges={group.unsavedChanges}
              key={index}
            />
          </div>
        );
      });
    }
  }

  handleClose() {
    this.setState({ showGroupsModal: false, inputValue: "" });
  }

  handleSave() {
    var value = this.state.inputValue;
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/addGroup`,
        {
          client: this.props.selectedClient.value,
          groupName: value
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // eslint-disable-next-line quotes
        toast.success('Group "' + value + '" added.');
        this.props.addUserGroupSuccess(response.data.userGroup);
      })
      .catch(error => {
        console.log(error);
        // eslint-disable-next-line quotes
        toast.error('Group "' + value + '" Already Exists');
      });

    this.setState({
      showGroupsModal: false,
      inputValue: "",
      selectedGroup: ""
    });
  }

  clickHandler(event, clicked) {
    this.props.setGroup(clicked.id);
    let newWorkspaces = [];
    if (this.props.workspaces.length > 0) {
      let checkWsArr = this.props.workspaces.map(item => {
        return item.id;
      });
      let checkAddArr = clicked.workspaces.map(item => {
        return item.id;
      });
      clicked.workspaces.forEach(item => {
        if (checkWsArr.includes(item.id)) {
          newWorkspaces.push(item);
        }
      });
      this.props.workspaces.forEach(item => {
        if (!checkAddArr.includes(item.id)) {
          newWorkspaces.push(item);
        }
      });
    } else {
      newWorkspaces = clicked.workspaces;
    }
    this.props.setWorkspaces(newWorkspaces);
    // console.log("CLICKED WORKSPACES");
    // console.log(clicked.workspaces);

    // console.log(this.props.workspaces);
    if (clicked.modelRoles !== undefined) {
      this.props.setModelRoles(clicked.modelRoles);
    }
    this.setState({ selectedGroup: clicked.name });
  }

  updateInputValue(evt) {
    this.setState({
      inputValue: evt.target.value
    });
  }
  handleInputChange() {
    this.props.filterGroups(this.groupSearch.value, this.props.userGroups);
  }
  render() {
    const { showGroupsModal } = this.state;
    return (
      <div
        className="user-group-div"
        style={{ maxWidth: "35%", minWidth: "35%", width: "35%" }}
      >
        <Modal isOpen={showGroupsModal}>
          <ModalHeader>Add User Group</ModalHeader>

          <ModalBody>
            <input
              value={this.state.inputValue}
              onChange={evt => this.updateInputValue(evt)}
            />
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.handleSave}>
              Save changes
            </Button>
            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>
        <div className="user-group-title-div">
          <div>User Groups</div>
          <div
            onClick={event => {
              if (this.props.selectedClient !== null) {
                this.setState({ showGroupsModal: true });
              }
            }}
            className="ta-add-button-right ta-button ta-button-primary"
          >
            +Add Group
          </div>
        </div>
        <input
          className="input-box"
          placeholder="search"
          ref={input => (this.groupSearch = input)}
          onChange={this.handleInputChange}
          style={{ width: "100%" }}
        />
        {this.renderUserGroups()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userGroups: state.groupReducer.userGroups,
  searchGroups: state.groupReducer.searchGroups,
  loading: state.groupReducer.loading,
  selectedClient: state.clientReducer.selectedClient,
  workspaces: state.workspaceReducer.workspaces
});

const mapDispatchToProps = dispatch => ({
  setWorkspaces(workspaces) {
    dispatch(setWorkspaces(workspaces));
  },
  filterGroups(filter, groups) {
    dispatch(filterGroups(filter, groups));
  },
  setGroup(group) {
    dispatch(setGroup(group));
  },
  addUserGroup(client, groupName) {
    dispatch(addUserGroup(client, groupName));
  },
  addUserGroupSuccess(group) {
    dispatch(addUserGroupSuccess(group));
  },
  setModelRoles(rRoles) {
    dispatch(setModelRoles(rRoles));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
