import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";

import Checkbox from "rc-checkbox";
import { cloneDeep } from "lodash";
import { FolderIcon } from "../../lib/icons";
import { getClients, setClient } from "../../reducers/clientReducer";
import {
  getFolderList,
  filterFolders,
  setFolderList,
  moveReport,
  createFolder
} from "../../reducers/reportFoldersReducer";

//import FileListFolder from "components/files/components/fileListFolder";
// import SortableTree from "@nosferatu500/react-sortable-tree";
// import "@nosferatu500/react-sortable-tree/style.css";
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import SortableTree, { getNodeAtPath } from "react-sortable-tree";
//import { Pen, DeleteIcon } from "lib/icons";
import File from "../../components/files/components/file";
import FileFolder from "../../components/files/components/fileFolder";
import "./style.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const maxDepth = 4;
class ReportFolders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      selectedPath: "",
      groups: [],
      showCreateModal: false,
      uploadFolders: [],
      searchUploadFolders: [],
      dupe: "",
      loadedUploadFiles: false
    };
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.editFolderName = this.editFolderName.bind(this);
    this.removeErrorDiv = this.removeErrorDiv.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.filterCreateFolderDialog = this.filterCreateFolderDialog.bind(this);
  }

  removeErrorDiv() {
    this.setState({ dupe: "" });
  }
  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }
  editFolderName(item, input, oldInput) {
    const { uploadFolders } = this.state;

    let findPath = item.path.split("/");
    let mFolders = [];
    let changeValue = input;
    let changed = false;
    let dupeCheck = [];
    let workspaceName = findPath[0];

    if (findPath.length === 2) {
      let oInput = oldInput;
      if (typeof oldInput !== "string" && Object.entries(oInput).length > 0) {
        oInput = oldInput.current.value;
      }
      if (input === oInput) {
        // this.props.createFolder(
        //   this.state.selectedClient,
        //   item.path,
        //   works;paceName
        // );
        mFolders = uploadFolders.map(folder => {
          if (folder.path === findPath[0] + "/") {
            folder.children = folder.children.map(child => {
              if (
                child.type === "folder" &&
                child.path === findPath[0] + "/" + findPath[1]
              ) {
                child.name = changeValue;
                child.editing = changed;
              }
              return child;
            });
          }
          return folder;
        });
      } else {
        let newPath = item.path.split("/");
        newPath[1] = input;
        newPath = newPath.join("/");

        this.props.createFolder(
          this.state.selectedClient,
          newPath,
          workspaceName
        );
      }
      // dupeCheck = uploadFolders.filter(folder => {
      //   if (folder.name.current === undefined) {
      //     return folder.name.toLowerCase() === input.toLowerCase();
      //   } else {
      //     return false;
      //   }
      // });
      // if (dupeCheck.length > 0) {
      //   changeValue = oldInput;
      //   changed = true;
      // }
      // mFolders = uploadFolders.map(folder => {
      //   if (folder.path === findPath[0] + "/") {
      //     folder.name = changeValue;
      //     folder.title = folder.name + "/";
      //     let path = folder.path.split("/");
      //     let rmItem = path[path.length - 2];
      //     path = folder.path.replace(rmItem + "/", "");
      //     folder.path = path + folder.title;

      //     folder.editing = changed;
      //   }

      //   return folder;
      // });
    } else if (findPath.length === 3) {
      if (input === oldInput) {
        this.props.createFolder(
          this.state.selectedClient,
          item.path,
          workspaceName
        );
      } else {
        let newPath = item.path.split("/");
        newPath[1] = input;
        newPath = newPath.join("/");
        console.log(newPath);
        this.props.createFolder(
          this.state.selectedClient,
          newPath,
          workspaceName
        );
      }
      mFolders = uploadFolders.filter(item => {
        return item.type === "folder";
      });
      mFolders = uploadFolders.map(folder => {
        if (folder.path === findPath[0] + "/") {
          dupeCheck = folder.children.filter(folder => {
            if (folder.name.current === undefined) {
              return folder.name.toLowerCase() === input.toLowerCase();
            } else {
              return false;
            }
          });
          if (dupeCheck.length > 0) {
            changeValue = oldInput;
            changed = true;
          }
          folder.children = folder.children.map(child => {
            if (
              child.type === "folder" &&
              child.path === findPath[0] + "/" + findPath[1] + "/"
            ) {
              child.name = changeValue;
              child.title = changeValue;
              let path = child.path.split("/");
              let rmItem = path[path.length - 2];
              path = child.path.replace(rmItem + "/", "");
              child.path = path + child.title;
              child.editing = changed;
            }
            return child;
          });
        }
        return folder;
      });
    } else {
      if (input === oldInput) {
        this.props.createFolder(
          this.state.selectedClient,
          item.path,
          workspaceName
        );
      } else {
        let newPath = item.path.split("/");
        newPath[2] = input;
        newPath = newPath.join("/");
        console.log(newPath);
        this.props.createFolder(
          this.state.selectedClient,
          newPath,
          workspaceName
        );
      }
      mFolders = uploadFolders.filter(item => {
        return item.type === "folder";
      });
      mFolders = uploadFolders.map(folder => {
        if (folder.path === findPath[0] + "/") {
          dupeCheck = folder.children.filter(folder => {
            if (folder.name.current === undefined) {
              return folder.name.toLowerCase() === input.toLowerCase();
            } else {
              return false;
            }
          });
          if (dupeCheck.length > 0) {
            changeValue = oldInput;
            changed = true;
          }
          folder.children = folder.children.map(child => {
            if (
              child.type === "folder" &&
              child.path === findPath[0] + "/" + findPath[1]
            ) {
              child.children = child.children.map(gChild => {
                if (
                  gChild.type === "folder" &&
                  gChild.path ===
                    findPath[0] + "/" + findPath[1] + "/" + findPath[2] + "/"
                ) {
                  gChild.name = changeValue;
                  gChild.title = folder.name + "/";
                  let path = gChild.path.split("/");
                  let rmItem = path[path.length - 2];
                  path = gChild.path.replace(rmItem + "/", "");
                  gChild.path = path + gChild.title;
                  gChild.editing = changed;
                }
                return gChild;
              });
            }
            return child;
          });
        }
        return folder;
      });
    }
    if (!changed) {
      this.setState({
        uploadFolders: mFolders,
        searchUploadFolders: mFolders,
        dupe: ""
      });
    } else {
      this.setState({
        uploadFolders: mFolders,
        searchUploadFolders: mFolders,
        dupe: "A folder with that name already exists, please enter a different name."
      });
    }

    //item.editing = false;
  }
  handleChange = selectedOption => {
    this.props.setClient(selectedOption);
    this.setState({ selectedClient: selectedOption });
    this.props.getFolderList(selectedOption.value);
  };
  componentDidMount() {
    if (this.props.selectedClient !== null) {
      this.setState({
        selectedClient: {
          label: this.props.selectedClient.label,
          value: this.props.selectedClient.value
        }
      });
      this.props.getFolderList(this.props.selectedClient.value);
    }
  }
  componentDidUpdate() {
    if (this.props.folders !== undefined) {
      if (
        this.props.folders.length !== this.state.uploadFolders.length &&
        !this.state.loadedUploadFiles
      ) {
        let mFolders = this.props.folders.filter(item => {
          return item.type === "folder";
        });
        this.setState({
          uploadFolders: mFolders,
          searchUploadFolders: mFolders,
          loadedUploadFiles: true
        });
      }
    }
  }
  checkboxChangeHandler(event, group) {
    console.log(group);
    let nGroups = this.state.groups;
    if (event.target.checked === true) {
      nGroups.push(group.name);
      this.setState({ groups: nGroups });
      event.target.checked = !event.target.checked;
    } else {
      nGroups = nGroups.filter(fGroup => {
        return fGroup !== group.name;
      });
      this.setState({ groups: nGroups });
      event.target.checked = !event.target.checked;
    }
  }

  renderFiles() {
    const { loading, searchGroups } = this.props;
    if (searchGroups.length !== 0 && !loading) {
      return searchGroups.map((group, index) => {
        return (
          <div key={index}>
            <Checkbox
              onChange={event => {
                this.checkboxChangeHandler(event, group);
              }}
            />
            {group.name}
          </div>
        );
      });
    }
  }
  filterCreateFolderDialog() {
    let filter = this.uploadSearch.value;
    if (this.uploadSearch.value === "") {
      this.setState({ searchUploadFolders: this.state.uploadFolders });
    } else {
      let newFolders = cloneDeep(this.state.uploadFolders);
      let nFolders = [];

      newFolders.forEach((newFolder, newFolderIdx) => {
        if (newFolder.children === undefined) {
          if (newFolder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
            return nFolders.push(newFolder);
        } else {
          newFolder.children = newFolder.children.filter((folder, index) => {
            if (folder.type !== "folder") {
              return false;
            }
            if (folder.children === undefined || folder.children.length === 0) {
              return (
                folder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
              );
            } else {
              return true;
            }
          });
          if (newFolder.children.length === 0) {
            if (
              newFolder.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            ) {
              nFolders.push(newFolder);
            }
          } else {
            newFolder.children.forEach((folder, index) => {
              if (folder.children !== undefined) {
                let tempFolders = cloneDeep(folder);
                folder.children = [];
                tempFolders.children.forEach((cFolder, cIndex) => {
                  if (cFolder.type !== "folder") {
                  } else if (
                    cFolder.name.toLowerCase().indexOf(filter.toLowerCase()) !==
                    -1
                  ) {
                    newFolder.children[index].children.push(cFolder);
                  }
                });
              }
            });
            newFolder.children = newFolder.children.filter(item => {
              if (item.children.length > 0) {
                return true;
              } else {
                return (
                  item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                );
              }
            });
            if (newFolder.children.length === 0) {
              if (
                newFolder.name.toLowerCase().indexOf(filter.toLowerCase()) !==
                -1
              ) {
                nFolders.push(newFolder);
              }
            } else {
              nFolders.push(newFolder);
            }
          }
        }
      });
      this.setState({ searchUploadFolders: nFolders });
    }
  }
  handleInputChange(type) {
    if (type === "upload") {
      this.filterCreateFolderDialog();
    } else {
      this.props.filterFolders(this.search.value, this.props.folders);
    }
  }
  handleClose() {
    this.setState({
      showCreateModal: false,

      path: null,
      fileName: null
    });

    // this.props.filterFolders("", this.props.folders);
    this.uploadSearch.value = "";
    this.filterCreateFolderDialog();
  }
  renderFoldersHelper(folders) {
    //const { uploadSearchFiles } = this.props;
    // debugger;
    if (folders !== undefined) {
      return folders.map((file, fIndex) => {
        if (file.children === undefined) {
          return <div key={"blankDiv" + fIndex} />;
        } else {
          return this.renderFolders(file, fIndex);
        }
      });
    }
  }
  folderClick(event, folders) {
    const { selectedPath } = this.state;
    let path = folders.path;

    // if (folders.name + "/" !== folders.path) {
    //   path = path + folders.name + "/";
    // }
    if (selectedPath === path) {
      this.setState({ selectedPath: "" });
    } else {
      this.setState({ selectedPath: path });
    }
  }
  renderFolders(folders, index) {
    return (
      <FileFolder
        clickHandler={event => {
          this.folderClick(event, folders);
        }}
        editName={this.editFolderName}
        key={index}
        reportFolder={true}
        index={index}
        item={folders}
        collapsible={true}
        selected={this.state.selectedPath}
      >
        <div>
          {folders.children.map((file, index) => {
            if (file.type === "file") {
              return <div key={index + "divs"} />;
            } else {
              return this.renderFolders(file, index);
            }
          })}
        </div>
      </FileFolder>
    );
  }
  renderFileFolder(node, index) {
    //debugger;
    if (node.type === "folder") {
      return (
        <div key={index} style={{ display: "flex", flexDirection: "row" }}>
          <FolderIcon
            height={"16"}
            // color={"rgba(88, 89, 91, 1)"}
            color={"rgba(88, 89, 91, 1)"}
            className="rf-folder-icon"
          />
          {node.name}
        </div>
      );
    } else {
      return <File key={index} index={index} file={node} type={"report"} />;
    }
  }
  createNewFolder() {
    const { uploadFolders, selectedPath } = this.state;
    let path = [];

    if (selectedPath === "") {
    } else {
      let newPath;
      if (!selectedPath.endsWith("/")) {
        newPath = selectedPath + "/";
      } else {
        newPath = selectedPath;
      }
      path = newPath.split("/");
      let mFolders = [];
      if (path.length === 2) {
        //For Parent Folders
        mFolders = uploadFolders.map(item => {
          if (item.path === path[0] + "/") {
            item.children.push({
              children: [],
              expanded: true,
              name: "New Folder",
              path: newPath + "New Folder/",
              title: "New Folder/",
              type: "folder",
              status: "new",
              editing: true
            });
          }
          return item;
        });
        this.setState({
          uploadFolders: mFolders,
          searchUploadFolders: mFolders,
          selectedPath: newPath + "New Folder/"
        });
      } else if (path.length === 3) {
        //for Subfolders
        mFolders = uploadFolders.filter(item => {
          return item.type === "folder";
        });
        mFolders = uploadFolders.map(item => {
          if (item.path === path[0] + "/") {
            item.children = item.children.map(child => {
              if (child.path === selectedPath) {
                child.children.push({
                  children: [],
                  expanded: true,
                  name: "New Folder",
                  path: newPath + "New Folder/",
                  title: "New Folder/",
                  type: "folder",
                  status: "new",
                  editing: true
                });
              }
              return child;
            });
          }
          return item;
        });
        this.setState({
          uploadFolders: mFolders,
          searchUploadFolders: mFolders,
          selectedPath: newPath + "New Folder/"
        });
      }
    }
  }
  render() {
    const { clients, searchFolders } = this.props;
    const {
      selectedClient,
      showCreateModal,
      searchUploadFolders,
      selectedPath
    } = this.state;

    return (
      <div style={{ height: "75%", width: "77%" }}>
        <Modal isOpen={showCreateModal}>
          <ModalHeader>Create Folder</ModalHeader>

          <ModalBody>
            {this.state.dupe !== "" && (
              <div
                style={{
                  position: "absolute",
                  background: "red",
                  color: "white",
                  display: "flex",
                  flexDirection: "row",
                  width: "93%",
                  padding: "5px"
                }}
              >
                {this.state.dupe}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={this.removeErrorDiv}
                >
                  x
                </div>
              </div>
            )}
            <div style={{ display: "flex" }}>
              <div>
                <div>
                  <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                    Select destination folder
                  </div>
                  <input
                    className="input-box"
                    placeholder="search"
                    ref={input => (this.uploadSearch = input)}
                    onChange={() => {
                      this.handleInputChange("upload");
                    }}
                    style={{
                      width: "350px",
                      position: "relative"
                    }}
                  />
                  <div className="upload-folders">
                    {this.renderFoldersHelper(searchUploadFolders)}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              className={
                "new-folder " +
                (selectedPath.split("/").length >= 4 || selectedPath === ""
                  ? "disabled"
                  : "")
              }
              color="secondary"
              disabled={
                selectedPath.split("/").length >= 4 || selectedPath === ""
              }
              onClick={() => {
                if (selectedPath.split("/").length < 4 && selectedPath !== "") {
                  this.createNewFolder();
                }
              }}
            >
              New Folder
            </Button>

            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>
        <div className="client" style={{ paddingBottom: "20px" }}>
          <div className="client-text">Client:</div>
          <Select
            key={JSON.stringify(clients)} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
            //filterOption={() => true}
            className="dropdown"
            value={
              selectedClient //isClearable
            }
            onChange={this.handleChange}
            onInputChange={this.onInputChange.bind(this)}
            options={clients}
          />
        </div>
        <div className="user-group-title-div">
          <div style={{ width: "25%" }}>
            <input
              className="input-box"
              placeholder="search"
              ref={input => (this.search = input)}
              onChange={this.handleInputChange}
              style={{ width: "100%" }}
            />
          </div>
          <div
            onClick={() => {
              this.setState({ showCreateModal: true });
            }}
            className="ta-button ta-button-primary"
            style={{
              height: "26px",
              width: "126px",
              textAlign: "center",
              cursor: "pointer"
            }}
          >
            Create Folder
          </div>
        </div>
        <SortableTree
          treeData={searchFolders}
          onChange={treeData => this.props.setFolderList({ treeData })}
          theme={FileExplorerTheme}
          generateNodeProps={node => {
            let nodePropsWButtons = {
              title: [
                <div key={node.treeIndex}>
                  {this.renderFileFolder(node.node, node.path.join(""))}
                </div>
              ],
              buttons: []
            };
            let nodePropsNoButtons = {
              title: [
                <div key={node.treeIndex}>
                  {this.renderFileFolder(node.node, node.path.join(""))}
                </div>
              ]
            };

            if (node.node.type === "folder") {
              return nodePropsNoButtons;
            } else {
              return nodePropsWButtons;
            }
          }}
          canDrag={({ node }) => {
            return node.type === "file";
          }}
          canDrop={({ node, nextParent }) => {
            if (nextParent === undefined || nextParent === null) {
              return false;
            } else {
              return (
                nextParent.type === "folder" &&
                nextParent.path.split("/")[0] === node.path.split("/")[0]
              );
            }
            //return !nextParent || !nextParent.noChildren;
          }}
          maxDepth={maxDepth}
          onMoveNode={({ node, treeIndex, path }) => {
            console.log(
              // eslint-disable-line no-console
              "node:",
              node,
              "treeIndex:",
              treeIndex,
              "path:",
              path
            );
            path.pop();
            let parentNode = getNodeAtPath({
              treeData: searchFolders,
              path: path,
              getNodeKey: ({ treeIndex }) => treeIndex,
              ignoreCollapsed: true
            });

            let pPath = parentNode.node.path.split("/");
            let finder = pPath[pPath.length - 1];
            if (finder === "") {
              pPath = pPath[0] + "/";
            } else {
              pPath = parentNode.node.path.split(finder)[0];
            }

            let nodeFinder = node.path.split("/");
            nodeFinder = nodeFinder[nodeFinder.length - 1];
            let nodePath = node.path.split(nodeFinder)[0];

            if (parentNode.node.path + "/" === nodePath) {
              //do nothing
            } else {
              //pPath = parentNode.node.path + "/";

              this.props.moveReport(node, parentNode.node.path);
              //this.props.renameFile(node.path, pPath + node.name);
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedClient: state.clientReducer.selectedClient,
  clients: state.clientReducer.clients,
  selectedFolders: state.reportFoldersReducer.selected,
  loading: state.reportFoldersReducer.loading,
  searchFolders: state.reportFoldersReducer.searchFolders,
  folders: state.reportFoldersReducer.folders

  // reports: state.reportReducer.reports,
  // error: state.reportReducer.error,
  // loading: state.reportReducer.loading,
  // allWorkspaces: state.workspaceReducer.allWorkspaces,
  // wsLoading: state.workspaceReducer.loading
});
const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  setClient(client) {
    dispatch(setClient(client));
  },
  getFolderList(client) {
    dispatch(getFolderList(client));
  },
  setFolderList(folders) {
    dispatch(setFolderList(folders));
  },
  filterFolders(filter, folders) {
    dispatch(filterFolders(filter, folders));
  },
  moveReport(report, target) {
    dispatch(moveReport(report, target));
  },
  createFolder(client, folder, ws) {
    dispatch(createFolder(client, folder, ws));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportFolders);
