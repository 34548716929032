import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
//import CreatableSelect from "react-select/lib/Creatable";
//import "./style.css";
import Checkbox from "rc-checkbox";
import { getClients, setClient } from "../../reducers/clientReducer";
import {
  getFileList,
  setRefresh,
  filterFiles,
  resetFiles
} from "../../reducers/filesReducer";
import { toast } from "react-toastify";
import axios from "axios";
import FileListFolder from "./components/fileListFolder";

class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      selectedReport: [],
      groups: []
    };
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.setRefresh = this.setRefresh.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }
  handleChange = selectedOption => {
    this.props.setClient(selectedOption);
    this.setState({ selectedClient: selectedOption });
    this.props.getFileList(selectedOption.value);
    this.props.resetFiles();
  };
  componentDidMount() {
    if (this.props.selectedClient !== null) {
      this.setState({
        selectedClient: {
          label: this.props.selectedClient.label,
          value: this.props.selectedClient.value
        }
      });
      this.props.getFileList(this.props.selectedClient.value);
    }
  }
  checkboxChangeHandler(event, group) {
    console.log(group);
    let nGroups = this.state.groups;
    if (event.target.checked === true) {
      nGroups.push(group.name);
      this.setState({ groups: nGroups });
      event.target.checked = !event.target.checked;
    } else {
      nGroups = nGroups.filter(fGroup => {
        return fGroup !== group.name;
      });
      this.setState({ groups: nGroups });
      event.target.checked = !event.target.checked;
    }
  }

  renderFiles() {
    const { loading, searchGroups } = this.props;
    if (searchGroups.length !== 0 && !loading) {
      return searchGroups.map((group, index) => {
        return (
          <div key={index}>
            <Checkbox
              onChange={event => {
                this.checkboxChangeHandler(event, group);
              }}
            />
            {group.name}
          </div>
        );
      });
    }
  }
  setRefresh(refresh) {
    if (this.props.selectedFiles.length !== 0) {
      this.props.setRefresh(
        this.state.selectedClient.value,
        this.props.selectedFiles,
        refresh
      );
      this.props.resetFiles();
      if (refresh) toast.success("Refresh enabled for selected files");
      else toast.success("Refresh disabled for selected files");
    }
  }
  handleInputChange() {
    this.props.filterFiles(this.search.value, this.props.files);
  }
  render() {
    const { clients, searchFiles } = this.props;
    const { selectedClient } = this.state;

    return (
      <div style={{ height: "75%", width: "77%" }}>
        <div className="client" style={{ paddingBottom: "20px" }}>
          <div className="client-text">Client:</div>
          <Select
            key={JSON.stringify(clients)} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
            //filterOption={() => true}
            className="dropdown"
            value={
              selectedClient //isClearable
            }
            onChange={this.handleChange}
            onInputChange={this.onInputChange.bind(this)}
            options={clients}
          />
        </div>
        <div className="user-group-title-div">
          <div style={{ width: "25%" }}>
            <input
              className="input-box"
              placeholder="search"
              ref={input => (this.search = input)}
              onChange={this.handleInputChange}
              style={{ width: "100%" }}
            />
          </div>

          <div className="action-buttons">
            <div
              className="ta-button ta-button-primary"
              style={{ height: "30px" }}
              onClick={() => {
                if (this.props.selectedFiles.length === 1) {
                  axios
                    .post(
                      `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/runRefreshForClientRpt`,
                      {
                        client: selectedClient.value,
                        report: this.props.selectedFiles[0]
                      },
                      {
                        headers: {
                          ClientToken: localStorage.getItem("clientToken")
                        }
                      }
                    )
                    .then(response => {
                      if (response.data.message !== undefined) {
                        alert(response.data.message);
                      } else {
                        toast.success("Refreshing selected file...");
                      }
                    })
                    .catch(err => {
                      alert("Error in the runRefreshForClientRpt Call");
                    });
                } else {
                  alert("This only works with 1 report at a time");
                }
                //this.props.getRefreshes();
              }}
            >
              Refresh Selected File
            </div>
            <div
              style={{
                padding: "5px",
                border: "1px solid rgba(160, 160, 160, 1)"
              }}
            >
              Set Auto Refresh on selected files
              <div style={{ display: "flex" }}>
                <div
                  className={
                    this.props.selectedFiles.length !== 0
                      ? "ta-button ta-button-primary"
                      : "ta-button ta-button-disabled"
                  }
                  style={{ marginLeft: "50px", marginRight: "50px" }}
                  onClick={() => {
                    this.setRefresh(true);
                  }}
                >
                  ON
                </div>
                <div
                  className={
                    this.props.selectedFiles.length !== 0
                      ? "ta-button ta-button-secondary"
                      : "ta-button ta-button-disabled"
                  }
                  onClick={() => {
                    this.setRefresh(false);
                  }}
                >
                  OFF
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.errorMsg === "" ? (
          <FileListFolder fileList={searchFiles} />
        ) : (
          <div>No Files</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedClient: state.clientReducer.selectedClient,
  clients: state.clientReducer.clients,
  selectedFiles: state.filesReducer.selected,
  loading: state.filesReducer.loading,
  errorMsg: state.filesReducer.errorMsg,
  searchFiles: state.filesReducer.searchFiles,
  files: state.filesReducer.files

  // reports: state.reportReducer.reports,
  // error: state.reportReducer.error,
  // loading: state.reportReducer.loading,
  // allWorkspaces: state.workspaceReducer.allWorkspaces,
  // wsLoading: state.workspaceReducer.loading
});
const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  setClient(client) {
    dispatch(setClient(client));
  },
  getFileList(client) {
    dispatch(getFileList(client));
  },
  setRefresh(client, selectedFiles, refresh) {
    dispatch(setRefresh(client, selectedFiles, refresh));
  },
  filterFiles(filter, files) {
    dispatch(filterFiles(filter, files));
  },
  resetFiles() {
    dispatch(resetFiles());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Files);
