import axios from "axios";
//import { cloneDeep } from "lodash";
/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loaded: false,
  idpLogin: false,
  isAuthed: false,
  loginErrors: {},
  userInfo: {},
  apps: [],
  appsLoading: true,
  appsLoaded: false,
  role: ""
};

/* -------------------- ACTION TYPES -------------------- */
const SET_LOADED = "SET_LOADED";
const SET_IDP_LOGIN = "SET_IDP_LOGIN";
const SET_LOGOUT = "SET_LOGOUT";
const GET_APPS_SUCCESS = "GET_APPS_SUCCESS";
const GET_APPS_BEGIN = "GET_APPS_BEGIN";
const GET_APPS_FAIL = "GET_APPS_FAIL";

/* -------------------- ACTION CREATORS -------------------- */
const setLoaded = value => {
  return { type: SET_LOADED, payload: value };
};
const setIdpLogin = value => {
  return { type: SET_IDP_LOGIN, payload: value };
};
const setLogout = () => {
  return { type: SET_LOGOUT };
};
const getAppsSuccess = (apps, groups) => ({
  type: GET_APPS_SUCCESS,
  payload: apps,
  groups
});
const getAppsBegin = () => ({
  type: GET_APPS_BEGIN
});
const getAppsError = error => ({
  type: GET_APPS_FAIL,
  payload: error
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const setAuthLoaded = value => {
  return dispatch => {
    dispatch(setLoaded(value));
  };
};
export const getApps = () => {
  return dispatch => {
    dispatch(getAppsBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/app/applicatonAuth`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token")
          }
        }
      )
      .then(response => {
        //console.log(response.data);
        localStorage.setItem("clientToken", response.data.clientToken);
        dispatch(
          getAppsSuccess(response.data.applications, response.data.groups)
        );
      })
      .catch(error => {
        dispatch(getAppsError(error.response.data.message));
      });
  };
};
export const logout = () => {
  return dispatch => {
    dispatch(setLogout());
  };
};
export const setIdpInitiatedLogin = value => {
  return dispatch => {
    dispatch(setIdpLogin(value));
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADED:
      return { ...state, loaded: action.payload, isAuthed: true };
    case SET_LOGOUT:
      return { ...state, isAuthed: false };
    case SET_IDP_LOGIN:
      return { ...state, idpLogin: action.payload };
    case GET_APPS_BEGIN:
      return { ...state, appsLoading: true, error: null, apps: [] };
    case GET_APPS_SUCCESS:
      let ovisAdmin = action.groups.filter(item => {
        return item === "ovis-admin";
      });
      let ovisContentDev = action.groups.filter(item => {
        return item === "ovis-content-dev";
      });
      let ovisFileShare = action.groups.filter(item => {
        return item === "ovis-file-share";
      });
      let role = "";
      console.log("ovis file share: ", ovisFileShare);
      if (ovisAdmin.length > 0) {
        role = "ovis-admin";
      } else if (ovisContentDev.length > 0) {
        role = "ovis-content-dev";
      } else if (ovisFileShare.length > 0) {
        role = "ovis-file-share";
      } else {
        role = "ovis-analyst";
      }

      return {
        ...state,
        apps: action.payload,
        role: role,
        appsLoading: false,
        appsLoaded: true
      };
    case GET_APPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apps: []
      };
    default:
      return state;
  }
};
