import React, { Component } from "react";
import loading from "../img/loading.svg";
import axios from "axios";

class Callback extends Component {
  componentDidMount() {
    //window.authContext.handleWindowCallback();

    let idToken = this.props.location.hash.split("token=")[1];
    console.log("ID TOKEN RENEWAL " + JSON.stringify(idToken));
    axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
  }
  render() {
    const style = {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "white"
    };

    return (
      <div style={style}>
        <img src={loading} alt="loading" />
      </div>
    );
  }
}

export default Callback;
