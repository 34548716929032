import axios from "axios";
import { cloneDeep } from "lodash";
/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  loading: true,
  error: null,
  reports: null,
  searchReports: null,
  feedItems: null,
  logo: "",
  bannerLeft: "",
  bannerRight: "",
  bannerMiddle: "",
  bannerLoading: true,
  showBanner: false,
  widths: {},
  numColumns: 3,
  workspaceAlias: "",
  hideWorkspace: false,
  disableReportCreation: false,
  enableWorkspaceBookmarks: false,
  selectedReport: null,
  selectedReportIdx: -1,
  selectedReportDescription: ""
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_REPORTS_BEGIN = "FETCH_REPORTS_BEGIN";
const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
const FETCH_REPORTS_FAILURE = "FETCH_REPORTS_FAILURE";
const SAVE_REPORT_DESCS_BEGIN = "SAVE_REPORT_DESCS_BEGIN";
const SAVE_REPORT_DESCS_SUCCESS = "SAVE_REPORT_DESCS_SUCCESS";
const SAVE_REPORT_DESCS_FAILURE = "SAVE_REPORT_DESCS_FAILURE";
const SET_SEARCH_REPORTS = "SET_SEARCH_REPORTS";
const FETCH_NEWS_FEED_SUCCESS = "FETCH_NEWS_FEED_SUCCESS";
const SET_BANNER_INFO = "SET_BANNER_INFO";
const FETCH_BANNER_INFO_BEGIN = "FETCH_BANNER_INFO_BEGIN";
const ADD_IMAGE = "ADD_IMAGE";
const UPDATE_REPORT = "UPDATE_REPORT";
const SET_SELECTED_REPORT = "SET_SELECTED_REPORT";
/* -------------------- ACTION CREATORS -------------------- */

const fetchReportsBegin = () => ({
  type: FETCH_REPORTS_BEGIN
});
const fetchBannerInfoBegin = () => ({
  type: FETCH_BANNER_INFO_BEGIN
});
const fetchReportsSuccess = (
  reports,
  alias = "",
  hidden = false,
  disableCreation = false,
  enableWorkspaceBookmarks = false,
  enablePersonalWorkspaceBookmarks = false
) => {
  return {
    type: FETCH_REPORTS_SUCCESS,
    payload: reports,
    alias,
    hidden,
    disableCreation,
    enableWorkspaceBookmarks,
    enablePersonalWorkspaceBookmarks
  };
};
const fetchReportsError = error => ({
  type: FETCH_REPORTS_FAILURE,
  payload: { error }
});
const saveReportDescsBegin = () => ({
  type: SAVE_REPORT_DESCS_BEGIN
});
const saveReportDescsSuccess = reports => {
  return { type: SAVE_REPORT_DESCS_SUCCESS, payload: reports };
};
const saveReportDescsError = error => ({
  type: SAVE_REPORT_DESCS_FAILURE,
  payload: { error }
});
const setSearchReports = reports => {
  return { type: SET_SEARCH_REPORTS, payload: reports };
};
const doUpdateReport = report => {
  return { type: UPDATE_REPORT, payload: report };
};
const fetchNewsFeedSuccess = feedItems => {
  return { type: FETCH_NEWS_FEED_SUCCESS, payload: feedItems };
};
const fetchBannerInfo = (
  bannerLeft,
  bannerRight,
  bannerMiddle,
  widths,
  numColumns,
  logo,
  showBanner
) => {
  return {
    type: SET_BANNER_INFO,
    bannerLeft,
    bannerRight,
    bannerMiddle,
    widths,
    numColumns,
    logo,
    showBanner
  };
};

const addImage = (rpt, img, token) => {
  return { type: ADD_IMAGE, rpt, img, token };
};
/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getReports = workspace => {
  return dispatch => {
    dispatch(fetchReportsBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/powerBI/getReports`,
        {
          workspace
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        let sasToken = response.data.sasToken;
        let rpt = [];
        rpt = response.data.reports.map(report => {
          if (report.img !== "") {
            report.img = report.img + "?" + sasToken;
          }
          return report;
        });

        dispatch(
          fetchReportsSuccess(
            rpt,
            response.data.alias,
            response.data.hidden,
            response.data.disableCreation,
            response.data.enableWorkspaceBookmarks,
            response.data.enablePersonalWorkspaceBookmarks
          )
        );
      })
      .catch(error => dispatch(fetchReportsError(error)));
  };
};
export const getStaticContent = workspace => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getStaticContent`,
        {
          workspace
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        function count(main_str, sub_str) {
          main_str += "";
          sub_str += "";

          if (sub_str.length <= 0) {
            return main_str.length + 1;
          }

          let subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          return (main_str.match(new RegExp(subStr, "gi")) || []).length;
        }
        let sc = [{ label: "new page", html: "", id: 44, name: "new page" }];
        let sasToken = response.data.sasToken;
        response.data.staticContent.forEach(report => {
          let rmCount = count(report.html, "img src=");
          let x = 1;

          while (x <= rmCount) {
            let img = report.html.split("img src='")[x].split("'")[0];

            if (img.includes("tabsportal.blob.core.windows")) {
              if (!img.includes("?st=")) {
                var re = new RegExp(img, "g");
                report.html = report.html.replace(re, img + "?" + sasToken);
              }
            }
            x = x + 1;
          }

          sc.push(report);
        });

        dispatch(fetchReportsSuccess(sc));
      })
      .catch(error => {});
  };
};

export const saveStaticContent = (workspace, report, content) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveStaticContent`,
        {
          workspace,
          content,
          report
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // let sc = [{ label: "new page", html: "", id: 44, name: "new page" }];
        // response.data.workspace.reports.forEach(report => {
        //   if (report.type === "staticContent") {
        //     let nReport = {
        //       label: report.name,
        //       html: report.html,
        //       id: report.id,
        //       name: report.name
        //     };
        //     sc.push(nReport);
        //   }
        // });

        dispatch(getStaticContent(workspace.id));
      })
      .catch(error => {});
  };
};

export const deleteStaticContent = (workspace, report) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/deleteStaticContent`,
        {
          workspace,
          report
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getStaticContent(workspace.id));
      })
      .catch(error => {});
  };
};

export const getSiteMessages = client => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getSiteMessages`,
        {
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        function count(main_str, sub_str) {
          main_str += "";
          sub_str += "";

          if (sub_str.length <= 0) {
            return main_str.length + 1;
          }

          let subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          return (main_str.match(new RegExp(subStr, "gi")) || []).length;
        }
        let sasToken = response.data.sasToken;
        let sc = [];

        response.data.result.forEach(nf => {
          let rmCount = count(
            nf.html,
            "src='https://tabsportal.blob.core.windows.net"
          );
          let x = 1;

          while (x <= rmCount) {
            let img = nf.html.split("src='")[x].split("'")[0];
            nf.html = nf.html.replace(img, img + "?" + sasToken);
            x = x + 1;
          }
          sc.push(nf);
        });
        dispatch(fetchNewsFeedSuccess(sc));
      })
      .catch(error => {});
  };
};

export const saveSiteMessage = (client, message, content) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveSiteMessage`,
        {
          client,
          content,
          message
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getSiteMessages(client));
      })
      .catch(error => {});
  };
};

export const deleteSiteMessage = (client, message) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/deleteSiteMessage`,
        {
          client,
          message
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getSiteMessages(client));
      })
      .catch(error => {});
  };
};

export const getBannerInfo = client => {
  return dispatch => {
    dispatch(fetchBannerInfoBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getBannerInfo`,
        {
          client
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        let bannerLeft = "";
        let bannerMiddle = "";
        let bannerRight = "";
        let bannerLeftWidth = "";
        let bannerMiddleWidth = "";
        let bannerRightWidth = "";
        let numColumns = 3;
        if (response.data.banner === undefined) {
        } else {
          function count(main_str, sub_str) {
            main_str += "";
            sub_str += "";

            if (sub_str.length <= 0) {
              return main_str.length + 1;
            }

            let subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
            return (main_str.match(new RegExp(subStr, "gi")) || []).length;
          }
          let sasToken = response.data.sasToken;

          let rmCount = count(
            response.data.banner,
            "src='https://tabsportal.blob.core.windows.net"
          );
          let newBanner = response.data.banner;

          let x = 1;

          while (x <= rmCount) {
            let img = newBanner
              .split("src='https://tabsportal.blob.core.windows.net")
              // eslint-disable-next-line no-unexpected-multiline
              [x].split("'")[0];
            newBanner = newBanner.replace(img, img + "?" + sasToken);
            x = x + 1;
          }
          // let getColumnBanner = newBanner.replace(/%20/g, "!@!");

          response.data.logo = response.data.logo + "?" + sasToken;
          console.log(response.data.banner);
          let bannerSplit = [];
          try {
            bannerSplit = response.data.banner.split("<div id=");
          } catch (err) {
            console.log("error in bannersplit " + err);
          }
          numColumns = bannerSplit.length - 1;
          if (numColumns === 3) {
            bannerLeft = newBanner
              .split("<div id=")[1]
              .split("%'>")[1]
              .split("</div>")[0];
            bannerRight = newBanner
              .split("<div id=")[3]
              .split("%'>")[1]
              .split("</div>")[0];
            bannerMiddle = newBanner
              .split("<div id=")[2]
              .split("%'>")[1]
              .split("</div>")[0];
            //Do width cals on non SAS version because the sas contains % which mess things up here
            bannerLeftWidth =
              response.data.banner.split("%")[0].split("width:")[1].trim() +
              "%";
            bannerMiddleWidth =
              response.data.banner.split("%'>")[1].split("width:")[1].trim() +
              "%";
            bannerRightWidth =
              response.data.banner.split("%'>")[2].split("width:")[1].trim() +
              "%";
          } else if (numColumns === 2) {
            bannerLeft = newBanner
              .split("<div id=")[1]
              .split("%'>")[1]
              .split("</div>")[0];
            bannerMiddle = newBanner
              .split("<div id=")[2]
              .split("%'>")[1]
              .split("</div>")[0];
            bannerLeftWidth =
              response.data.banner.split("%'>")[0].split("width:")[1].trim() +
              "%";
            bannerMiddleWidth =
              response.data.banner.split("%'>")[1].split("width:")[1].trim() +
              "%";
          } else {
            bannerLeft = newBanner
              .split("<div id=")[1]
              .split("%'>")[1]
              .split("</div>")[0];
            bannerLeftWidth =
              response.data.banner.split("%'>")[0].split("width:")[1].trim() +
              "%";
          }
        }

        dispatch(
          fetchBannerInfo(
            bannerLeft,
            bannerRight,
            bannerMiddle,
            { bannerLeftWidth, bannerMiddleWidth, bannerRightWidth },
            numColumns,
            response.data.logo,
            response.data.showBanner
          )
        );
      })
      .catch(error => {
        console.log(error);
      });
  };
};
export const saveBannerInfo = (client, content, showBanner) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveBannerInfo`,
        {
          client,
          content,
          showBanner
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {})
      .catch(error => {});
  };
};

export const saveLogo = (client, source) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveSiteLogo`,
        {
          client,
          source
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {})
      .catch(error => {});
  };
};

export const saveImg = (workspace, rpt, source, token) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveRptImg`,
        {
          workspace,
          rpt,
          source
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(addImage(rpt, source, token));
      })
      .catch(error => {});
  };
};

export const filterReports = (filter, reports) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchReports(reports));
    } else {
      let newReports = cloneDeep(reports);
      newReports = newReports.filter(report => {
        return report.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      });
      dispatch(setSearchReports(newReports));
    }
  };
};

export const saveReportDescs = (
  workspace,
  reports,
  alias = "",
  hideWorkspace = false,
  disableReportCreation = false,
  enableWorkspaceBookmarks = false,
  enablePersonalWorkspaceBookmarks = false
) => {
  return dispatch => {
    dispatch(saveReportDescsBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveReportDescriptions`,
        {
          workspace,
          reports,
          alias,
          hideWorkspace,
          disableReportCreation,
          enableWorkspaceBookmarks,
          enablePersonalWorkspaceBookmarks
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(saveReportDescsSuccess(response.data.reports));
      })
      .catch(error => dispatch(saveReportDescsError(error)));
  };
};
export const updateReport = report => {
  return dispatch => {
    dispatch(doUpdateReport(report));
  };
};
export const setSelectedReport = (report, idx, desc) => {
  return dispatch => {
    dispatch({ type: SET_SELECTED_REPORT, payload: report, idx, desc });
    //dispatch(setActiveWS(group));
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REPORTS_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_REPORTS_SUCCESS:
      let selectedReportDescription = action.payload[0].description;
      if (selectedReportDescription === null) {
        selectedReportDescription = "";
      }
      return {
        ...state,
        reports: action.payload,
        selectedReport: action.payload[0],
        selectedReportIdx: 0,
        selectedReportDescription: selectedReportDescription,
        searchReports: action.payload,
        workspaceAlias: action.alias,
        hideWorkspace: action.hidden,
        disableReportCreation: action.disableCreation,
        enableWorkspaceBookmarks: action.enableWorkspaceBookmarks,
        enablePersonalWorkspaceBookmarks:
          action.enablePersonalWorkspaceBookmarks,
        loading: false
      };
    case FETCH_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SAVE_REPORT_DESCS_BEGIN:
      return { ...state };
    case SAVE_REPORT_DESCS_SUCCESS:
      return state;
    case SAVE_REPORT_DESCS_FAILURE:
      return {
        ...state
      };

    case SET_SEARCH_REPORTS:
      return { ...state, searchReports: action.payload };
    case FETCH_NEWS_FEED_SUCCESS:
      return { ...state, feedItems: action.payload };
    case FETCH_BANNER_INFO_BEGIN:
      return { ...state, bannerLoading: true, error: null };
    case SET_BANNER_INFO:
      return {
        ...state,
        bannerLeft: action.bannerLeft,
        bannerRight: action.bannerRight,
        bannerMiddle: action.bannerMiddle,
        numColumns: action.numColumns,
        widths: action.widths,
        logo: action.logo,
        bannerLoading: false,
        showBanner: action.showBanner
      };
    case ADD_IMAGE:
      let rpts = state.reports;

      rpts = rpts.map(rpt => {
        if (rpt.id === action.rpt.id) {
          if (action.token === "") {
            rpt.img = action.img;
          } else {
            rpt.img = action.img + "?" + action.token;
          }
        }
        return rpt;
      });
      return { ...state, searchReports: rpts, reports: rpts };
    case UPDATE_REPORT:
      let uRpts = state.reports;
      let uSearchRpts = state.searchReports;

      uRpts = uRpts.map(rpt => {
        if (rpt.id === action.payload.id) {
          rpt = action.payload;
        }
        return rpt;
      });
      uSearchRpts = uSearchRpts.map(rpt => {
        if (rpt.id === action.payload.id) {
          rpt = action.payload;
        }
        return rpt;
      });
      return { ...state, searchReports: uSearchRpts, reports: uRpts };
    case SET_SELECTED_REPORT:
      return {
        ...state,
        selectedReport: action.payload,
        selectedReportIdx: action.idx,
        selectedReportDescription: action.desc
      };
    default:
      return state;
  }
};
