import React, { Component } from "react";
import { connect } from "react-redux";
import Checkbox from "rc-checkbox";

class WorkspaceCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.workspace.checked
    };
  }
  componentDidUpdate(e) {
    if (this.props.workspace.checked !== this.state.checked) {
      this.setState({ checked: this.props.workspace.checked });
    }
  }
  render() {
    return (
      <div key={this.props.index}>
        <Checkbox
          checked={this.state.checked}
          onChange={event => {
            console.log(this.props.workspace);
            this.setState({ checked: event.target.checked });
            this.props.workspace.checked = event.target.checked;
            this.props.checkboxChangeHandler(event, this.props.workspace);
          }}
        />
        {this.props.workspace.name}
      </div>
    );
  }
}
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceCheckbox);
