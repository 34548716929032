import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import Checkbox from "rc-checkbox";
import { DeleteIcon } from "../../lib/icons";
import "./style.css";

import {
  getReportSubscriptions,
  getReportSubscriptionsByClient,
  saveReportSubscription,
  deleteReportSubscription
} from "../../reducers/rptSubscriptionReducer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import axios from "axios";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Select from "react-select";
import LinkCellRenderer from "./LinkCellRenderer";
import DeleteCellRenderer from "./LinkCellRenderer";
class ReportSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "Dataset",
          field: "datasetName",
          editable: false
        },
        {
          headerName: "Client",
          field: "clientName",
          editable: false,
          cellRenderer: LinkCellRenderer,
          cellRendererParams: {
            onLinkClick: params => {
              this.setState({ selectedClient: params.value });

              this.props.getReportSubscriptionsByClient(params.value);
            }
          }
        },
        {
          headerName: "Subscriptions",
          field: "subscriptions"

          //comparator: groupComparator
        }
      ],
      selectedClientColDefs: [
        {
          headerName: "Dataset",
          field: "datasetName",
          editable: false
        },
        {
          headerName: "Report Subscription",
          field: "name",
          editable: false,
          cellRenderer: LinkCellRenderer,
          cellRendererParams: {
            onLinkClick: params => {
              let selectedRptSub = this.props.clientRptSubs[params.rowIndex];

              let selectedRadioOption = 1;
              if (selectedRptSub.reportType === "PDF") {
                selectedRadioOption = 2;
              }
              let status = "on";
              if (selectedRptSub.status !== "Active") {
                status = "off";
              }
              let selectedFrequency = {
                id: 1,
                label: "On Data Refresh",
                value: "On Data Refresh"
              };
              let sunChecked = false,
                monChecked = false,
                tueChecked = false,
                wedChecked = false,
                thuChecked = false,
                friChecked = false,
                satChecked = false,
                selectedMonthly;
              if (selectedRptSub.frequency === "weekly") {
                selectedFrequency = { id: 2, label: "Weekly", value: "Weekly" };

                selectedRptSub.weeklyFreq.forEach(item => {
                  switch (item) {
                    case "sun":
                      sunChecked = true;
                      break;
                    case "mon":
                      monChecked = true;
                      break;
                    case "tue":
                      tueChecked = true;
                      break;
                    case "wed":
                      wedChecked = true;
                      break;
                    case "thu":
                      thuChecked = true;
                      break;
                    case "fri":
                      friChecked = true;
                      break;
                    case "sat":
                      satChecked = true;
                      break;
                    default:
                      break;
                  }
                });
              } else if (selectedRptSub.frequency === "monthly") {
                selectedFrequency = {
                  id: 3,
                  label: "Monthly",
                  value: "Monthly"
                };
                selectedMonthly = {
                  label: selectedRptSub.monthlyFreq,
                  name: selectedRptSub.monthlyFreq
                };
              }
              this.setState({
                subscriptionId: selectedRptSub._id,
                showNewReportSubDialog: true,
                bookmarkName: selectedRptSub.bookmarkName,
                bookmark: selectedRptSub.bookmark,
                rptSubName: selectedRptSub.name,
                user: selectedRptSub.emailTo,
                selectedRadioOption,
                toggleSubActive: status,
                selectedFrequency,
                monChecked,
                tueChecked,
                wedChecked,
                thuChecked,
                friChecked,
                satChecked,
                sunChecked,
                selectedMonthly,
                deleteRptSubItem: selectedRptSub
              });
            }
          }
        },
        {
          headerName: "Workspace",
          field: "workspaceId"

          //comparator: groupComparator
        },
        {
          headerName: "Owner",
          field: "emailTo"

          //comparator: groupComparator
        },
        {
          headerName: "Delete",
          field: "delete",
          cellRenderer: DeleteCellRenderer,
          cellRendererParams: {
            onLinkClick: params => {
              let selectedRptSub = this.props.clientRptSubs[params.rowIndex];
              this.props.deleteReportSubscription(
                selectedRptSub._id,
                selectedRptSub.clientId.name
              );
            }
          }
        }
      ],
      selectedClient: "",
      selectedFrequency: {
        label: "On Data Refresh",
        value: "On Data Refresh",
        id: 1
      },
      frequencyOptions: [
        { label: "On Data Refresh", value: "On Data Refresh", id: 1 },
        { label: "Weekly", value: "Weekly", id: 2 },
        { label: "Monthly", value: "Monthly", id: 3 }
      ],
      user: "",
      bookmark: "",
      bookmarkName: "",
      selectedWeekly: [],
      selectedMonthly: { label: "1", value: "1" },
      monthlyOptions: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
        { label: "26", value: "26" },
        { label: "27", value: "27" },
        { label: "28", value: "28" },
        { label: "29", value: "29" },
        { label: "30", value: "30" },
        { label: "31", value: "31" }
      ],
      rptSubName: "",
      subscriptionId: "",
      subscriptionRptTitle: "",
      deleteRptSubItem: {},
      showDeleteReportSubscription: false,
      toggleSubActive: "on",
      selectedRadioOption: 1
    };
    this.props.getReportSubscriptions();
    this.textReportNameInput = React.createRef();
    this.toggleChangeReportSubscription =
      this.toggleChangeReportSubscription.bind(this);
  }
  onGridReady(event, populateChecks = false) {
    event.api.sizeColumnsToFit();
  }
  handleClose = () => {
    this.setState({
      showNewReportSubDialog: false,
      showDeleteReportSubscription: false
    });
  };
  renderDeleteButton() {
    const { subscriptionId } = this.state;
    if (subscriptionId === "") {
      return null;
    } else {
      return (
        <div
          style={{ marginTop: "-2px", marginLeft: "10px", cursor: "pointer" }}
          onClick={() => {
            this.setState({ showDeleteReportSubscription: true });
          }}
        >
          <DeleteIcon
            height={"24px"}
            width={"24px"}
            color={"rgba(88, 89, 91, 1)"}
          />
        </div>
      );
    }
  }
  toggleChangeReportSubscription() {
    if (this.state.toggleSubActive === "off") {
      this.setState({ toggleSubActive: "on" });
    } else {
      this.setState({ toggleSubActive: "off" });
    }
  }
  renderRptSubTextInput() {
    const { subscriptionRptTitle } = this.state;

    if (subscriptionRptTitle !== "") {
      return (
        <input
          style={{ width: "100%" }}
          defaultValue={subscriptionRptTitle}
          ref={this.textReportNameInput}
        />
      );
    } else {
      return (
        <input
          style={{ width: "100%" }}
          defaultValue={this.state.rptSubName}
          ref={this.textReportNameInput}
        />
      );
    }
  }
  renderFrequencyExtras() {
    const { selectedFrequency } = this.state;
    if (selectedFrequency.id === 1) {
      return null;
    } else if (selectedFrequency.id === 2) {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ sunChecked: !this.state.sunChecked });
              }}
              checked={this.state.sunChecked}
              className="daily-selection"
            />
            Sun
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ monChecked: !this.state.monChecked });
              }}
              checked={this.state.monChecked}
              className="daily-selection"
            />
            Mon
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ tueChecked: !this.state.tueChecked });
              }}
              checked={this.state.tueChecked}
              className="daily-selection"
            />
            Tue
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ wedChecked: !this.state.wedChecked });
              }}
              checked={this.state.wedChecked}
              className="daily-selection"
            />
            Wed
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ thuChecked: !this.state.thuChecked });
              }}
              checked={this.state.thuChecked}
              className="daily-selection"
            />
            Thu
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ friChecked: !this.state.friChecked });
              }}
              checked={this.state.friChecked}
              className="daily-selection"
            />
            Fri
          </div>
          <div className="daily-select-group">
            <Checkbox
              onChange={() => {
                this.setState({ satChecked: !this.state.satChecked });
              }}
              checked={this.state.satChecked}
              className="daily-selection"
            />
            Sat
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Select
            className="bookmarks-dropdown"
            classNamePrefix="react-select"
            placeholder={"Select Day"}
            value={
              this.state.selectedMonthly //isClearable
            }
            onChange={selectedOption => {
              this.setState({
                selectedMonthly: selectedOption
              });
            }}
            options={this.state.monthlyOptions}
          />
        </div>
      );
    }
  }
  renderBookmarkDialog() {
    if (
      this.state.bookmarkName !== "Select Bookmark" &&
      this.state.bookmarkName !== ""
    ) {
      return <div>{this.state.bookmarkName}</div>;
    } else {
      return <div>No Bookmarks Applied</div>;
    }
  }
  saveAndCloseRptSub() {
    let rptType = "PPTX";
    if (this.state.selectedRadioOption === 2) {
      rptType = "PDF";
    }
    let workspaceId = this.state.deleteRptSubItem.workspaceId;
    if (workspaceId === undefined || !workspaceId.includes("-")) {
      workspaceId = this.state.deleteRptSubItem.url
        .split("/report/")[1]
        .split("+")[0];
    }
    let status = "Active";
    if (this.state.toggleSubActive !== "on") {
      status = "Disabled";
    }

    let rptSub = {
      rptName: this.textReportNameInput.current.value,
      rptId: this.state.deleteRptSubItem.reportId,
      status: status,
      email: this.state.user,
      bookmark: this.state.bookmark,
      bookmarkName: this.state.bookmarkName,
      rptType,
      freq: this.state.selectedFrequency.label,
      datasetId: this.state.deleteRptSubItem.datasetId,
      workspaceId
    };
    if (this.state.selectedFrequency.label === "Weekly") {
      let selectedWeekly = [];
      if (this.state.sunChecked) {
        selectedWeekly.push("sun");
      }
      if (this.state.monChecked) {
        selectedWeekly.push("mon");
      }
      if (this.state.tueChecked) {
        selectedWeekly.push("tue");
      }
      if (this.state.wedChecked) {
        selectedWeekly.push("wed");
      }
      if (this.state.thuChecked) {
        selectedWeekly.push("thu");
      }
      if (this.state.friChecked) {
        selectedWeekly.push("fri");
      }
      if (this.state.satChecked) {
        selectedWeekly.push("sat");
      }
      rptSub.wFreq = selectedWeekly;
    } else if (this.state.selectedFrequency.label === "Monthly") {
      rptSub.mFreq = this.state.selectedMonthly.value;
    }
    rptSub._id = this.state.deleteRptSubItem._id;

    this.props.saveReportSubscription(
      rptSub,
      this.state.deleteRptSubItem.clientId.name
    );

    this.setState({ showNewReportSubDialog: false });
  }
  render() {
    const { showDeleteReportSubscription, showNewReportSubDialog } = this.state;
    if (this.state.selectedClient === "") {
      return (
        <div style={{ width: "100%" }}>
          <div>Report Subscriptions</div>
          <div
            className="ag-theme-balham"
            style={{
              height: "99%",
              width: "100%"
            }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={this.props.rptSubscriptions}
              enableSorting={true}
              enableFilter={false}
              onRowSelected={this.onRowSelected}
              onGridReady={event => this.onGridReady(event)}
              frameworkComponents={{}}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          <Modal isOpen={showNewReportSubDialog}>
            <ModalHeader>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>Edit Report Subscription</div>

                <ToggleButtonGroup
                  className="toggle-button-group"
                  name="viewEdit"
                  value={this.state.toggleSubActive}
                  onChange={this.toggleChangeReportSubscription}
                  defaultValue={"on"}
                  exclusive
                >
                  <ToggleButton
                    classes={{
                      selected: "button-override",
                      root: "button-override-root"
                    }}
                    className="toggle-button"
                    value="on"
                  >
                    <div>ON</div>
                  </ToggleButton>
                  <ToggleButton
                    classes={{ selected: "button-override" }}
                    className="toggle-button"
                    value="off"
                  >
                    <div>OFF</div>
                  </ToggleButton>
                </ToggleButtonGroup>
                {this.renderDeleteButton()}
              </div>
            </ModalHeader>
            <ModalBody>
              <div style={{ width: "100%" }}>
                {this.renderRptSubTextInput()}
              </div>

              <div style={{ paddingTop: "5px" }}>Emailing to</div>
              <div style={{ paddingTop: "5px" }}>
                <i>{this.state.user}</i>
              </div>
              <div style={{ paddingTop: "20px", paddingBottom: "5px" }}>
                Report Bookmarks
              </div>
              {this.renderBookmarkDialog()}

              {/* <Select
              className="bookmarks-dropdown"
              classNamePrefix="react-select"
              placeholder={"Bookmarks"}
              value={
                this.state.selectedBookmark //isClearable
              }
              onChange={selectedOption => {
                this.setState({
                  selectedBookmark: selectedOption
                });
              }}
              options={this.state.bookmarks}
            /> */}
              <div style={{ paddingTop: "20px", paddingBottom: "5px" }}>
                Report Type
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  checked={this.state.selectedRadioOption === 1}
                  onChange={event => {
                    this.setState({
                      selectedRadioOption: parseInt(event.target.value, 10)
                    });
                  }}
                  type="radio"
                  style={{ marginRight: "5px" }}
                  value={1}
                />
                <div style={{ marginTop: "0px", paddingBottom: "3px" }}>
                  PowerPoint Attachment
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  checked={this.state.selectedRadioOption === 2}
                  onChange={event => {
                    this.setState({
                      selectedRadioOption: parseInt(event.target.value, 10)
                    });
                  }}
                  type="radio"
                  style={{ marginRight: "5px" }}
                  value={2}
                />
                <div style={{ marginTop: "0px", paddingBottom: "3px" }}>
                  PDF Attachment
                </div>
              </div>
              <div style={{ marginTop: "20px", paddingBottom: "5px" }}>
                Frequency
              </div>
              <Select
                className="frequency-dropdown"
                classNamePrefix="react-select"
                placeholder={"Frequency"}
                value={
                  this.state.selectedFrequency //isClearable
                }
                onChange={selectedOption => {
                  this.setState({
                    selectedFrequency: selectedOption
                  });
                }}
                options={this.state.frequencyOptions}
              />
              {this.renderFrequencyExtras()}
            </ModalBody>

            <ModalFooter>
              <Button
                style={{ background: "#007ea7" }}
                bsstyle="primary"
                onClick={() => {
                  this.setState({ deleteRptSubItem: {} });
                  this.saveAndCloseRptSub();
                }}
              >
                Save & Close
              </Button>
              <Button
                onClick={() => {
                  this.setState({ deleteRptSubItem: {} });
                  this.handleClose();
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={showDeleteReportSubscription}>
            <ModalHeader>Delete Report Subscription</ModalHeader>
            <ModalBody>
              <div>
                Are you sure you want to delete this report subscription?
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                bsstyle="primary"
                onClick={() => {
                  this.props.deleteReportSubscription(
                    this.state.deleteRptSubItem._id
                  );
                  this.setState({ deleteRptSubItem: {} });
                  this.handleClose();
                }}
              >
                Delete
              </Button>
              <Button onClick={this.handleClose}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              Report Subscriptions - Viewing all Subscriptions for{" "}
              {this.state.selectedClient}
            </div>
            <div style={{ width: "30%" }}></div>
            <div
              className="ta-button ta-button-secondary"
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row"
              }}
              onClick={() => {
                this.setState({ selectedClient: "" });
              }}
            >
              <div class="arrow-left"></div>
              Back
            </div>
          </div>
          <div
            className="ag-theme-balham"
            style={{
              height: "99%",
              width: "100%"
            }}
          >
            <AgGridReact
              columnDefs={this.state.selectedClientColDefs}
              rowData={this.props.clientRptSubs}
              enableSorting={true}
              enableFilter={false}
              onRowSelected={this.onRowSelected}
              onGridReady={event => this.onGridReady(event)}
              frameworkComponents={{
                LinkCellRenderer: LinkCellRenderer,
                DeleteCellRenderer: DeleteCellRenderer
              }}
            />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  selectedClient: state.clientReducer.selectedClient,
  clients: state.clientReducer.clients,
  rptSubscriptions: state.rptSubReducer.reportSubscriptions,
  clientRptSubs: state.rptSubReducer.clientRptSubs,

  loading: state.reportFoldersReducer.loading
});
const mapDispatchToProps = dispatch => ({
  getReportSubscriptions() {
    dispatch(getReportSubscriptions());
  },
  getReportSubscriptionsByClient(client) {
    dispatch(getReportSubscriptionsByClient(client));
  },
  saveReportSubscription(rptSub, client) {
    dispatch(saveReportSubscription(rptSub, client));
  },
  deleteReportSubscription(rptSubId, client) {
    dispatch(deleteReportSubscription(rptSubId, client));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportSubscriptions);
