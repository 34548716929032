import React, { Component } from "react";

// import {
//   getProjects,
//   selectBatch
// } from "components/accubase/reducers/accubaseReducer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CheckmarkIcon, WarningTriangleIcon, XIcon } from "../../lib/icons";

class LastRefreshCellRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFailedRefreshModal: false
    };
  }
  componentDidMount() {}
  renderBody() {
    if (this.props.data.failedFiles !== undefined) {
      return this.props.data.failedFiles.map(file => {
        return <div>{file}</div>;
      });
    } else {
      return null;
    }
  }
  renderCell(text) {
    if (this.props.data.lastRefreshStatus === "Failed") {
      return (
        <div
          style={{ display: "flex", flexDirection: "row" }}
          onClick={() => {
            this.setState({ showFailedRefreshModal: true });
          }}
        >
          <WarningTriangleIcon
            height={"16"}
            // color={"rgba(88, 89, 91, 1)"}
            color={"red"}
            className="warning-icon"
            style={{ marginTop: "5px", paddingRight: "5px" }}
          />
          {text}
        </div>
      );
    } else if (this.props.data.lastRefreshStatus === "Success") {
      return (
        <div>
          <CheckmarkIcon
            height={"16"}
            // color={"rgba(88, 89, 91, 1)"}
            color={"rgba(62,197,23,1)"}
            className="checkmark-icon"
            style={{ marginTop: "5px", paddingRight: "5px" }}
          />
          {text}
        </div>
      );
    } else {
      return (
        <div>
          <XIcon
            height={"16"}
            // color={"rgba(88, 89, 91, 1)"}
            color={"black"}
            className="x-icon"
            style={{ marginTop: "5px", paddingRight: "5px" }}
          />
          Not Run
        </div>
      );
    }
  }
  render() {
    const { showFailedRefreshModal } = this.state;
    let text;
    if (this.props.data.lastRefreshStatus === "Failed") {
      text = "Failed (" + this.props.data.failCount + " files)";
    } else {
      text = "Success";
    }
    return (
      <div>
        <Modal isOpen={showFailedRefreshModal}>
          <ModalHeader>
            <div>Failed Files</div>
            <div>These files failed to refresh</div>
          </ModalHeader>

          <ModalBody>{this.renderBody()}</ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                this.setState({ showFailedRefreshModal: false });
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
        {this.renderCell(text)}
      </div>
    );
  }
}

export default LastRefreshCellRenderer;
