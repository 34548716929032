import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { getClients, setClient } from "../../reducers/clientReducer";

class DataManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = selectedOption => {
    this.props.setClient(selectedOption);
    this.setState({
      selectedClient: selectedOption
    });
  };
  componentDidMount() {
    if (this.props.selectedClient !== null) {
      this.setState({
        selectedClient: {
          label: this.props.selectedClient.label,
          value: this.props.selectedClient.value
        }
      });
    }
  }
  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }
  render() {
    const { clients } = this.props;
    const { selectedClient } = this.state;

    return (
      <div style={{ display: "flex", width: "77%" }}>
        <div style={{ width: "100%", height: "80%" }}>
          <div style={{ height: "100%" }}>
            <div className="client" style={{ marginTop: "10px" }}>
              <div className="client-text">Client:</div>
              <Select
                key={JSON.stringify(clients)} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
                //filterOption={() => true}
                className="dropdown"
                value={
                  selectedClient //isClearable
                }
                onChange={this.handleChange}
                onInputChange={this.onInputChange.bind(this)}
                options={clients}
                onCreateOption={
                  this.handleCreate //autoLoad={true}
                }
              />

              <div className="spacer" style={{ width: "10%" }} />
              <div className="action-buttons">
                <div
                  className={
                    this.state.unsavedChanges
                      ? "ta-button ta-button-primary"
                      : "ta-button ta-button-disabled"
                  }
                  onClick={() => {
                    if (this.state.unsavedChanges) {
                      this.saveHandler(false);
                    }
                  }}
                >
                  Save
                </div>

                <div
                  className={
                    this.state.unsavedChanges
                      ? "ta-button ta-button-secondary"
                      : "ta-button ta-button-disabled"
                  }
                  onClick={() =>
                    this.state.unsavedChanges
                      ? this.setState({ showIsDirtyModal: true })
                      : null
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.clientReducer.clients,
  selectedClient: state.clientReducer.selectedClient
});
const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  setClient(client) {
    dispatch(setClient(client));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DataManagement);
