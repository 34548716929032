import axios from "axios";
// import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  shareLinks: [],

  loading: true,
  error: null
};

/* -------------------- ACTION TYPES -------------------- */
const GET_SHARE_LINKS_SUCCESS = "GET_SHARE_LINKS_SUCCESS";
const GET_SHARE_LINKS_BEGIN = "GET_SHARE_LINKS_BEGIN";
const GET_SHARE_LINKS_FAILURE = "GET_SHARE_LINKS_FAILURE";

/* -------------------- ACTION CREATORS -------------------- */

const fetchShareLinksBegin = () => ({
  type: GET_SHARE_LINKS_BEGIN
});
const fetchShareLinksSuccess = inboxes => {
  return { type: GET_SHARE_LINKS_SUCCESS, payload: inboxes };
};
const fetchShareLinksFailure = () => ({
  type: GET_SHARE_LINKS_FAILURE
});

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getShareLinks = () => {
  return dispatch => {
    dispatch(fetchShareLinksBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getShareLinks`,
        {},
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchShareLinksSuccess(response.data.shareLinks));
      })
      .catch(err => {
        dispatch(fetchShareLinksFailure());
      });
  };
};

export const deleteShareLink = shareLinkId => {
  return dispatch => {
    // dispatch(fetchReportSubscriptionsBegin());

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/deleteShareLink`,
        {
          shareLinkId
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getShareLinks());
        // dispatch(fetchReportSubscriptionsSuccess(response.data.subscriptions));
      })
      .catch(error => dispatch(fetchShareLinksFailure(error)));
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SHARE_LINKS_BEGIN:
      return {
        ...state,
        loading: true
      };

    case GET_SHARE_LINKS_SUCCESS:
      return {
        ...state,
        shareLinks: action.payload,
        loading: false
      };
    case GET_SHARE_LINKS_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      };

    default:
      return state;
  }
};
