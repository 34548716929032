import { combineReducers } from "redux";
import clientReducer from "./clientReducer";
import groupReducer from "./groupReducer";
import workspaceReducer from "./workspaceReducer";
import reportReducer from "./reportReducer";
import userManagementReducer from "./userManagementReducer";
import authReducer from "./authReducer";
import filesReducer from "./filesReducer";
import reportFoldersReducer from "./reportFoldersReducer";
import customApplicationReducer from "./customApplicationReducer";
import excelRefreshReducer from "./excelRefreshReducer";
import reportSubscriptionReducer from "./rptSubscriptionReducer";
import fileUploadReducer from "./fileUploadReducer";
import shareLinkReducer from "./shareLinkReducer";

export default combineReducers({
  clientReducer: clientReducer,
  groupReducer: groupReducer,
  workspaceReducer: workspaceReducer,
  reportReducer: reportReducer,
  userManagementReducer: userManagementReducer,
  auth: authReducer,
  filesReducer: filesReducer,
  refreshReducer: excelRefreshReducer,
  reportFoldersReducer: reportFoldersReducer,
  customAppReducer: customApplicationReducer,
  rptSubReducer: reportSubscriptionReducer,
  fileUploadReducer: fileUploadReducer,
  shareLinkReducer: shareLinkReducer
});
