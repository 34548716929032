import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCustomApps,
  createCustomApp,
  getBaseAppObjects,
  saveBaseApp,
  setApp,
  filterAvailableObjects
} from "../../reducers/customApplicationReducer";
import Stepper from "../stepper";

import Select from "react-select";

import {
  AppChartAnalytics,
  AppChartUser,
  AppChartBar,
  AppChartLine,
  AppChartLineDown,
  AppPie,
  FolderIcon,
  LiftModelIcon,
  PromoPlanIcon,
  BaselineIcon,
  InputSourceIcon,
  ReportSvg
} from "../../lib/icons";
import axios from "axios";

//import { toast } from "react-toastify";
//import { cloneDeep, template } from "lodash";
//import axios from "axios";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./style.css";
import { cloneDeep } from "lodash";
// import { Left } from "react-bootstrap/lib/Media";
//import { orderBy } from "lodash";

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "4px",
  margin: `0 0 ${grid}px 0`,
  height: "80px",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
  border: "1px solid #dbddde",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#f7f8fa",
  padding: grid
  // width: 250,
});
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class EditBaseApplication extends Component {
  constructor(props) {
    super(props);
    let sOptions = [
      {
        id: 1,
        value: "AppChartAnalytics",
        label: (
          <div>
            <AppChartAnalytics
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 2,
        value: "AppChartUser",
        label: (
          <div>
            <AppChartUser
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 3,
        value: "AppChartBar",
        label: (
          <div>
            <AppChartBar
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 4,
        value: "AppChartLine",
        label: (
          <div>
            <AppChartLine
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 5,
        value: "AppChartLineDown",
        label: (
          <div>
            <AppChartLineDown
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 6,
        value: "AppPie",
        label: (
          <div>
            <AppPie
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 7,
        value: "files",
        label: (
          <div>
            <FolderIcon
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 8,
        value: "acculift",
        label: (
          <div>
            <LiftModelIcon
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 9,
        value: "promoOpt",
        label: (
          <div>
            <PromoPlanIcon
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 10,
        value: "accubase",
        label: (
          <div>
            <BaselineIcon
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 11,
        value: "mdm",
        label: (
          <div>
            <InputSourceIcon
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 12,
        value: "report",
        label: (
          <div>
            <ReportSvg
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      }
    ];
    let iOptions = [];
    for (let i = 1; i < 21; i++) {
      iOptions.push({ label: i, value: i });
    }
    this.state = {
      init: true,
      options: sOptions,
      curSelection: sOptions[0],
      thumbnails: [],
      removeImgArr: [],
      initThumbs: false,
      maxReportTiles: 6,
      incrementOptions: iOptions
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);

    this.textTitle = React.createRef();
    this.textDesc = React.createRef();
    this.renderThumbs = this.renderThumbs.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }
  componentDidMount() {
    const { selectedClient, newApp, appToEdit, workspaceType } = this.props;

    if (newApp) {
      this.props.getBaseAppObjects(selectedClient.value, "");
    } else {
      this.props.getBaseAppObjects(
        selectedClient.value,
        appToEdit,
        workspaceType
      );
    }
    //
  }

  componentDidUpdate() {
    const { initThumbs } = this.state;
    if (initThumbs) {
      this.setState({
        initThumbs: false
        //thumbnails: this.props.selectedCustomApp.thumbnails
      });
    } else if (this.props.selectedCustomApp.title !== undefined) {
      if (this.state.init && !this.props.newApp) {
        if (this.props.selectedCustomApp.description === "") {
          switch (this.props.title.toLowerCase()) {
            case "files":
              this.textDesc.current.value =
                "Download Excel & Other Shared Files";
              break;
            case "acculift":
              this.textDesc.current.value = "Create & Review Lift Models";
              break;
            case "promo optimization":
              this.textDesc.current.value = "Create & Review Promotion Plans";
              break;
            case "accubase":
              this.textDesc.current.value = "Create & Review Baselines";
              break;
            case "mdh":
              this.textDesc.current.value = "Master Data Harmonization";
              break;

            default:
          }
        } else {
          this.textDesc.current.value =
            this.props.selectedCustomApp.description;
        }
        if (
          this.props.selectedCustomApp.alias === undefined ||
          this.props.selectedCustomApp.alias === "" ||
          this.props.selectedCustomApp.alias === null
        ) {
          this.textTitle.current.value = this.props.selectedCustomApp.title;
        } else {
          this.textTitle.current.value = this.props.selectedCustomApp.alias;
        }

        let icon = this.props.selectedCustomApp.icon;
        if (icon === undefined) {
          if (this.props.workspaceType) {
            icon = 12;
          } else {
            switch (this.props.selectedCustomApp.type) {
              case "acculift":
                icon = 8;
                break;
              case "accubase":
                icon = 10;
                break;
              case "files":
                icon = 7;
                break;
              case "promoOpt":
                icon = 9;
                break;
              case "mdh":
                icon = 11;
                break;
              default:
                icon = 1;
                break;
            }
          }
        }
        let maxTiles = 6;
        if (this.props.selectedCustomApp.maxReportTiles !== undefined) {
          maxTiles = this.props.selectedCustomApp.maxReportTiles;
        }
        this.setState({
          curSelection: this.state.options[icon - 1],
          init: false,
          thumbnails: this.props.selectedCustomApp.thumbnails,
          maxReportTiles: maxTiles
        });
      }
    }
  }
  handleInputChange(type) {
    if (type === "groups") {
      this.props.filterGroups(this.groupSearch.value, this.props.userGroups);
    } else if (type === "groupName") {
      this.setState({ groupName: this.groupName.current.value });
    } else {
      if (this.objSearch.value !== "") {
        this.props.filterAvailableObjects(
          this.objSearch.value,
          this.props.searchAppObjects
        );
      } else {
        this.props.filterAvailableObjects(
          this.objSearch.value,
          this.props.availableCustomAppObjects
        );
      }
    }
  }

  async saveHandler() {
    const { selectedClient, workspaceType } = this.props;
    let title = this.textTitle.current.value;
    let desc = this.textDesc.current.value;

    let thumbnails = cloneDeep(this.state.thumbnails);
    thumbnails = thumbnails.map(item => {
      item.link = item.link.split("?")[0];
      return item;
    });
    let saveObj = {};
    if (title !== this.props.title) {
      saveObj = {
        title: this.props.title,
        alias: title,
        desc,
        id: this.props.appToEdit,
        thumbnails: thumbnails,
        icon: this.state.curSelection.id,
        type: this.props.selectedCustomApp.type,
        link: this.props.selectedCustomApp.link,
        collapsible: this.props.selectedCustomApp.collapsible,
        maxReportTiles: this.state.maxReportTiles
      };
    } else {
      saveObj = {
        title: this.props.title,
        alias: this.props.title,
        desc,
        id: this.props.appToEdit,
        thumbnails: thumbnails,
        icon: this.state.curSelection.id,
        type: this.props.selectedCustomApp.type,
        link: this.props.selectedCustomApp.link,
        collapsible: this.props.selectedCustomApp.collapsible,
        maxReportTiles: this.state.maxReportTiles
      };
    }

    if (this.state.removeImgArr.length > 0) {
      for (var item of this.state.removeImgArr) {
        if (item.containerName !== "public-folder") {
          await axios
            .post(
              `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/removeCustomAppImage`,
              item,
              {
                headers: {
                  ClientToken: localStorage.getItem("clientToken")
                }
              }
            )
            .then(res => {});
        }
      }

      this.setState({ removeImgArr: [] });
    }
    this.props.saveBaseApp(selectedClient.value, saveObj, workspaceType);

    this.props.setApp({});

    this.props.cancelHandler();
    this.props.saveHandler();
  }

  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  handleChange = selectedOption => {
    this.setState({
      curSelection: selectedOption
    });
  };
  removeImage(image) {
    const { removeImgArr } = this.state;
    let newImgArr = cloneDeep(removeImgArr);

    let newThumbs = this.state.thumbnails.filter(item => {
      return item.id !== image.id;
    });
    let containerName = image.link.split("/")[3];
    let fileName = image.link.split("/")[4].split("?")[0];
    newImgArr.push({
      containerName,
      fileName,
      id: image.id,
      customApplication: this.props.selectedCustomApp.id
    });
    this.setState({
      thumbnails: newThumbs,
      removeImgArr: newImgArr
    });
  }
  handleselectedFile = event => {
    const { thumbnails } = this.state;
    event.preventDefault();

    const data = new FormData();
    data.append("image", event.target.files[0]);
    data.append("name", "Upload");

    data.append("customApplication", this.props.selectedCustomApp.title);
    data.append("client", this.props.selectedClient.value);
    data.append("width", 325);
    data.append("height", 183);

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/uploadAndResizeApplicationImage`,
        data,
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        let newThumbs = cloneDeep(thumbnails);
        newThumbs.push(res.data.data);

        this.setState({ thumbnails: newThumbs });
        // this.setState({
        //   imgSrc: res.data.data.link,
        //   imgHeight: res.data.data.height,
        //   imgWidth: res.data.data.width
        // });
        // let sasRm = res.data.data.link.split("?")[0];
        // let sasToken = res.data.data.link.split("?")[1];
        // this.props.saveImg(
        //   this.state.selectedWorkspace,
        //   this.state.selectedReport,
        //   sasRm,
        //   sasToken
        // );
        //console.log(res.statusText);
      });
  };
  renderThumbs(thumb) {
    const { thumbnails } = this.state;

    let background;
    let title = "";
    if (this.props.title !== undefined) {
      title = this.props.title.toLowerCase();
    }

    switch (title) {
      case "files":
        background = "rgba(31,109,78,1)";

        break;
      case "acculift":
        background = "#008F7E";

        break;
      case "promo optimization":
        background = "#521C3A";

        break;
      case "accubase":
        background = "#138CFF";

        break;
      case "mdh":
        background = "#193259";

        break;

      default:
        background = "rgb(46, 92, 166)";
    }
    if (thumb !== undefined && thumb.link !== undefined) {
      if (thumb.link.includes("svg")) {
        return (
          <div
            key={thumb.id}
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              marginLeft: "27%",
              marginRight: "20%"
            }}
          >
            <div
              style={{
                textAlign: "center",
                width: "80%",
                backgroundImage: "url(" + thumb.link + ")",
                backgroundColor: background,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            ></div>
            <div
              style={{
                cursor: "pointer",
                marginTop: "24px",
                position: "relative",
                left: "80px"
              }}
              onClick={() => {
                this.removeImage(thumb);
              }}
            >
              x
            </div>
          </div>
        );
      }
      return (
        <div
          key={thumb.id}
          style={{ display: "flex", flexDirection: "row", height: "100%" }}
        >
          <div
            style={{
              textAlign: "center",
              width: "100%",
              backgroundImage: "url(" + thumb.link + ")",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
            }}
          ></div>
          <div
            style={{
              cursor: "pointer",
              marginTop: "24px",
              position: "relative",
              left: "-5px"
            }}
            onClick={() => {
              this.removeImage(thumb);
            }}
          >
            x
          </div>
        </div>
      );
    } else if (thumbnails.length > 0) {
      return thumbnails.map(thumb => {
        return (
          <div key={thumb.id} style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                backgroundImage: "url(" + thumb.link + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            ></div>
            <div
              style={{
                cursor: "pointer",
                marginTop: "24px",
                position: "relative",
                left: "-5px"
              }}
              onClick={() => {
                this.removeImage(thumb);
              }}
            >
              x
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  }
  resetToDefault() {
    const { options } = this.state;
    const { workspaceType } = this.props;
    this.textTitle.current.value = this.props.title;
    let thumbs = [];

    if (workspaceType === true) {
      this.textDesc.current.value = "";
      thumbs = [
        {
          link: "https://tabsportal.blob.core.windows.net/public-folder/reportIcon.svg",
          id: "e0d26475-802a-4f9b-8802-aa9fff360672"
        }
      ];
      this.setState({
        curSelection: options[11],
        thumbnails: thumbs,
        initThumbs: true
      });
    } else {
      switch (this.props.title.toLowerCase()) {
        case "files":
          this.textDesc.current.value = "Download Excel & Other Shared Files";
          thumbs = [
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/excelIcon.svg",
              id: "e0d26475-802a-4f9b-8802-aa9fff360672"
            }
          ];
          this.setState({
            curSelection: options[6],
            thumbnails: thumbs,
            initThumbs: true
          });
          break;
        case "acculift":
          this.textDesc.current.value = "Create & Review Lift Models";
          thumbs = [
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/liftModelIcon.svg",
              id: "61294ec2-88cc-4c2e-b1a7-bfd68154b239"
            },
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/AcculiftManage.png",
              id: "6beab9cc-2432-4261-a733-73c0ac2b184f"
            },
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/AcculiftChart.png",
              id: "d3231777-3d62-4a9f-b85c-c683b8a05bf5"
            }
          ];
          this.setState({
            curSelection: options[7],
            thumbnails: thumbs,
            initThumbs: true
          });
          break;
        case "promo optimization":
          this.textDesc.current.value = "Create & Review Promotion Plans";
          thumbs = [
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/promoOptIcon.svg",
              id: "3cde44d2-3ed7-4b22-bdb0-505b9a740afc"
            },
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/PromoManage.png",
              id: "7514e66d-0020-4e4e-9ff1-d6a717e07c28"
            },
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/PromoReview.png",
              id: "f17f8280-7666-4439-aa26-86570fb9a11f"
            }
          ];
          this.setState({
            curSelection: options[8],
            thumbnails: thumbs,
            initThumbs: true
          });
          break;
        case "accubase":
          this.textDesc.current.value = "Create & Review Baselines";
          thumbs = [
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/accubase.svg",
              id: "b65b2a1f-2017-4b39-8f6f-d73f0a59c4ab"
            },
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/AccubaseManage.png",
              id: "adf5d96d-7209-4209-99c9-326c7be9c71c"
            },
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/AccubaseChart.png",
              id: "d2048fcc-86ce-46ec-b091-f3feec69e81d"
            }
          ];
          this.setState({
            curSelection: options[9],
            thumbnails: thumbs,
            initThumbs: true
          });
          break;
        case "mdh":
          this.textDesc.current.value = "Master Data Harmonization";
          thumbs = [
            {
              link: "https://tabsportal.blob.core.windows.net/public-folder/mdhIcon.svg",
              id: "eb5af7c2-9e0e-46cc-ad75-5d6a4d50b4ac"
            }
          ];
          this.setState({
            curSelection: options[10],
            thumbnails: thumbs,
            initThumbs: true
          });
          break;

        default:
      }
    }
  }
  render() {
    let clientName = this.props.selectedClient.value.split("+")[0];
    let disabledSave = true;

    if (this.textTitle.current !== null) {
      if (this.textTitle.current.value !== "") {
        disabledSave = false;
      }
    }

    return (
      <div style={{ width: "90%" }}>
        <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
          Customize Base Applications
        </div>
        <div>
          Editing {this.props.title} Application for client: {clientName}
        </div>
        <hr />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="number-circle">1</div>
          <div>Set Application Alias,Icon, and Description</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginRight: "50px" }}>
            <div style={{ fontWeight: "bold" }}>Application Name</div>
            <div style={{ fontStyle: "italic", fontSize: "12px" }}>
              This name will be visible in the application menu and navigation
              bar
            </div>
            <input
              onChange={evt => {
                if (evt.target.value === "") {
                  disabledSave = true;
                } else {
                  disabledSave = false;
                }
              }}
              ref={this.textTitle}
            />
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>Application Icon</div>
            <div style={{ fontStyle: "italic", fontSize: "12px" }}>
              This icon will be visible in the application menu and navigation
              bar
            </div>
            <Select
              key={this.state.options} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
              //filterOption={() => true}
              className="select-icon-dd"
              value={
                this.state.curSelection //isClearable
              }
              onChange={item => {
                this.handleChange(item);
              }}
              options={this.state.options}
            />
          </div>
        </div>
        <div>
          <div>Application Description</div>
          <textarea style={{ width: "50%" }} ref={this.textDesc} />
        </div>
        <div>
          <div>Max Report Tiles on Homepage</div>
          <Stepper
            value={this.state.maxReportTiles}
            onChange={item => {
              this.setState({ maxReportTiles: item });
            }}
          />
        </div>
        {/* <div>
          <div>Max Report Tiles on Homepage</div>
          <Select
            key={"stuffs"} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
            //filterOption={() => true}
            className="select-increment-dd"
            value={
              this.state.maxReportTiles //isClearable
            }
            onChange={item => {
              this.setState({ maxReportTiles: item });
            }}
            options={this.state.incrementOptions}
          />
        </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "350px"
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "30px" }}
          >
            <div className="number-circle">2</div>
            <div>Add Application Thumbnails</div>
          </div>
          <div
            style={{
              fontStyle: "italic",
              fontSize: "11px",
              paddingLeft: "23px",
              marginTop: "-8px"
            }}
          >
            Minimum recommended image size 325 x 183 px. The first picture will
            become the main default landing image. Drag to reorder.
          </div>

          <div
            style={{
              height: "200px",
              border: "1px solid #ccc",
              marginTop: "10px",
              marginBottom: "10px",
              overflowY: "scroll"
            }}
          >
            <DragDropContext
              onDragEnd={result => {
                // dropped outside the list
                if (!result.destination) {
                  return;
                }

                const items = reorder(
                  this.state.thumbnails,
                  result.source.index,
                  result.destination.index
                );

                this.setState({
                  thumbnails: items
                });
              }}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.thumbnails.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {this.renderThumbs(item)}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <label
              className="ta-button ta-button-primary"
              style={{ width: "70px" }}
              htmlFor="upload"
            >
              Upload
            </label>
            <input
              hidden={true}
              id="upload"
              ref
              type="file"
              // disabled={
              //   this.props.searchAppObjects.length === 0 ? "disabled" : ""
              // }
              name="Upload"
              onChange={this.handleselectedFile}
              onClick={event => {
                event.target.value = null;
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "350px"
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "30px" }}
          >
            <div className="number-circle">3</div>
            <div>Save or discard your edits</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px"
            }}
          >
            <div
              className={
                "ta-button ta-button-primary " +
                (disabledSave ? "disabled" : "")
              }
              onClick={() => {
                if (!disabledSave) this.saveHandler();
              }}
            >
              Save
            </div>
            <div
              onClick={() => {
                this.props.setApp({});

                this.setState({ removeImgArr: [] });
                this.props.cancelHandler();
              }}
              className="ta-button"
              style={{ border: "1px solid #ccc" }}
            >
              Cancel
            </div>
            <div
              className={"ta-button "}
              style={{
                marginLeft: "70px",
                border: "1px solid #ccc"
              }}
              onClick={() => {
                this.resetToDefault();
              }}
            >
              Reset to Default
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customApps: state.customAppReducer.customApps,
  loadedAppObjects: state.customAppReducer.loadedAppObjects,
  clients: state.clientReducer.clients,
  selectedClient: state.clientReducer.selectedClient,
  selectedCustomApp: state.customAppReducer.selectedCustomApp
});
const mapDispatchToProps = dispatch => ({
  getCustomApps(client) {
    dispatch(getCustomApps(client));
  },
  createCustomApp(client, appName) {
    dispatch(createCustomApp(client, appName));
  },
  getBaseAppObjects(client, appId, workspaceType = false) {
    dispatch(getBaseAppObjects(client, appId, workspaceType));
  },

  saveBaseApp(client, app, workspaceType = false) {
    dispatch(saveBaseApp(client, app, workspaceType));
  },
  setApp(app) {
    dispatch(setApp(app));
  },
  filterAvailableObjects(filter, objs) {
    dispatch(filterAvailableObjects(filter, objs));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBaseApplication);
