import React, { Component } from "react";
import { connect } from "react-redux";
import "./style/style.css";
import { cloneDeep } from "lodash";

//import { cloneDeep } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";

class AppliedWorkspacesPanel extends Component {
  renderWorkspaces(group) {
    let newGroup = cloneDeep(group);
    if (newGroup.workspaces === undefined) {
      newGroup.workspaces = [];
    }
    newGroup.workspaces = newGroup.workspaces.filter(workspace => {
      return workspace.checked === true;
    });
    if (newGroup.workspaces === undefined || newGroup.workspaces.length === 0) {
      return <div className="ta-workspace-name">none</div>;
    } else {
      return newGroup.workspaces.map((workspace, index) => {
        return (
          <div key={index} className="ta-workspace-name">
            {workspace.name}
          </div>
        );
      });
    }
  }

  render() {
    const { ugLoading } = this.props;
    if (!ugLoading) {
      return this.props.userGroups.map((group, index) => {
        return (
          <div key={index}>
            <div className="ta-group-name">{group.name}</div>
            <div className="ta-workspaces" style={{ paddingLeft: "20px" }}>
              {this.renderWorkspaces(group)}
            </div>
          </div>
        );
      });
    } else {
      return <div className="ta-info-text">Select a client</div>;
    }
  }
}

const mapStateToProps = state => ({
  loading: state.workspaceReducer.loading,
  //workspaces: state.workspaceReducer.workspaces,
  allWorkspaces: state.workspaceReducer.allWorkspaces,
  allLoading: state.workspaceReducer.allLoading,
  userGroups: state.groupReducer.userGroups,
  ugLoading: state.groupReducer.loading
});

export default connect(mapStateToProps, "")(AppliedWorkspacesPanel);
