import React from "react";
import "./clientCR.css";

export default class LinkCellRenderer extends React.Component {
  handleClick = e => {
    e.preventDefault();
    this.props.onLinkClick(this.props);
  };

  render() {
    return (
      <button className="button-link" onClick={this.handleClick}>
        {this.props.value}
      </button>
    );
  }
}

// cellRenderer: params => {
//     if (params !== undefined) {
//       // var link = document.createElement("a");
//       var link = document.createElement("div");
//       link.href = "#";
//       // link.innerText = params.value;
//       link.addEventListener("click", e => {
//         e.preventDefault();
//         this.setState({ selectedClient: params.value });
//         this.props.getReportSubscriptionsByClient(params.value);
//       });
//       debugger;
//       return link;
//     }
//   }
